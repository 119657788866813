import Service1 from "../img/ORTHOPEDIC.jpg";
import Service2 from "../img/general.png";
import Service3 from "../img/arthroscopy.jpg";
import Service4 from "../img/joint.jpg";
import Service5 from "../img/spine.jpg";
import Service6 from "../img/Laparoscopic.jpg";
import Service7 from "../img/anorectal.jpg";
import Service8 from "../img/FootCare.jpg";
import Service9 from "../img/general-medicine.jpg";
import Service10 from "../img/icu.jpg";
import Service11 from "../img/dialysis.jpg";
import Service12 from "../img/Maternity.jpg";
import Service13 from "../img/neurosurgery.jpg";
import Service14 from "../img/ent.png";
import Service15 from "../img/cancer.jpg";
import Service16 from "../img/endoscopy.jpg";
import Service17 from "../img/chemist.png";
import Service18 from "../img/xray.jpg";
import Service19 from "../img/nicu.jpg";
import Service20 from "../img/PaediatricSurgery.jpg";
import Service21 from "../img/pediatric_med.jpeg";
import Service22 from "../img/obstetric-gynecology.jpg";
import Service23 from "../img/critical.jpg";
import Service24 from "../img/neonatology.jpg";
import Service25 from "../img/Oncology.jpg";
import Service26 from "../img/urology.jpg";
import Service27 from "../img/health checkup.jpg";
import Service28 from "../img/general surgery.jpg";

// ============ new Service =======
import SerImg1 from "../img/Services/Diabetologist.jpg";
import SerImg2 from "../img/Services/Gastroenterologist.jpg";
import SerImg3 from "../img/Services/Opthalmology.jpg";
import SerImg4 from "../img/Services/cardiology.jpg";
import SerImg5 from "../img/Services/dermatologist.jpg";
import SerImg6 from "../img/Services/general-surgeon.jpg";
import SerImg7 from "../img/Services/hematology.jpg";
import SerImg8 from "../img/Services/nephrologist.jpg";
import SerImg9 from "../img/Services/pulmonology.jpg";


// const ServiceData = [
//     {
//         id:1,
//         ServiceImage:Service1,
//         Header:"Orthopedic Surgery",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/orthopedic-surgery",
//         BannerDesc:"Orthopedics is a branch of medicine that deals with the diagnosis, treatment, and prevention of conditions and diseases affecting the musculoskeletal system, including bones, joints, ligaments, tendons, and muscles. Orthopedic doctors, also known as orthopedic surgeons, specialize in treating a wide range of injuries and disorders, such as osteoarthritis, spinal disorders, fractures, sports injuries, and congenital conditions like scoliosis.",
//         ShortDesc1:"Orthopedics is a branch of medicine that deals with the diagnosis, treatment, and prevention of conditions and diseases affecting the musculoskeletal system, including bones, joints, ligaments, tendons, and muscles. Orthopedic doctors, also known as orthopedic surgeons, specialize in treating a wide range of injuries and disorders, such as osteoarthritis, spinal disorders, fractures, sports injuries, and congenital conditions like scoliosis. They use a variety of treatments, including surgery, physical therapy, and medications, to help restore function, reduce pain, and prevent further damage to the affected area. The ultimate goal of orthopedic care is to help patients regain full mobility and an active lifestyle.",
//         shortTitle:"There are several sub-specialties within the field of orthopedics, including:",
//         descHead1:"Sports Medicine :",
//         descHead2:"Pediatric Orthopedics :",
//         descHead3:"Spine Surgery :",
//         descHead4:"Joint Replacement Surgery :",
//         descHead5:"Hand and Upper Extremity Surgery :",
//         descHead6:"Foot and Ankle Surgery :",
//         descHead7:"Trauma Surgery :",
//         descPoint1:"This specialization focuses on the prevention, diagnosis, and treatment of injuries related to physical activity, such as sprains, strains, and fractures.",
//         descPoint2:"This field deals with conditions and disorders of the musculoskeletal system in children, such as cerebral palsy, scoliosis, and congenital dislocations.",
//         descPoint3:"This sub-specialty is concerned with conditions affecting the spinal column, including herniated disks, spinal stenosis, and spinal fractures.",
//         descPoint4:"This specialization focuses on the replacement of damaged or diseased joints with artificial implants, such as knee or hip replacements.",
//         descPoint5:"This field deals with conditions affecting the hand, wrist, elbow, and shoulder, including carpal tunnel syndrome, rotator cuff tears, and osteoarthritis.",
//         descPoint6:"This sub-specialty deals with conditions affecting the foot and ankle, including plantar fasciitis, sprains, and fractures.",
//         descPoint7:"This field focuses on the treatment of traumatic injuries, such as fractures, dislocations, and ligament tears.",
//     },
//     {
//         id:2,
//         ServiceImage:Service2,
//         Header:"Trauma Care Centre",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/trauma-care-centre",
//         BannerDesc:"Tertiary trauma centre with a trauma team, including neurosurgery, Orthopedics, general surgery and Anesthesiology",
//         ShortDesc1:"Tertiary trauma centre with a trauma team, including neurosurgery, Orthopedics, general surgery and Anesthesiology",
//         descPoint1:"Specialized medical facility providing immediate and comprehensive care for life-threatening injuries. ",
//         descPoint2:"Categorized into levels based on the scope of resources and capabilities.",
//         descPoint3:"Equipped with advanced technology and a multidisciplinary team of healthcare professionals.",
//         descPoint4:"Operates 24/7 to ensure round-the-clock access to critical care.",
//         descPoint5:"Plays a pivotal role in regional trauma systems, collaborating with EMS and referral hospitals.",
//         descPoint6:"Focuses on rapid response, stabilization, and long-term rehabilitation for trauma patients.",
//     },
//     {
//         id:3,
//         ServiceImage:Service3,
//         Header:"Arthroscopic Surgery",
//         Branch:"Chembur",
//         category:"Ghatkopar",
//         Slugs:"/arthroscopic-surgery",
//         BannerDesc:"Arthroscopic Surgery is a minimally invasive surgical technique that uses a small camera and specialized instruments to diagnose and treat joint-related conditions, allowing for quicker recovery and reduced scarring compared to traditional open surgery.",
//         ShortDesc1:"Arthroscopic Surgery is a minimally invasive surgical technique that uses a small camera and specialized instruments to diagnose and treat joint-related conditions, allowing for quicker recovery and reduced scarring compared to traditional open surgery.",
//         descPoint1:"Minimally invasive surgical procedure using an arthroscope to diagnose and treat joint problems.",
//         descPoint2:"Small incisions reduce tissue damage, scarring, and promote faster recovery.",
//         descPoint3:"Arthroscope with a camera provides a clear view of joint structures during the procedure.",
//         descPoint4:"Commonly performed on knees, shoulders, hips, ankles, elbows, and wrists.",
//         descPoint5:"Treatments include ligament and tendon repair, cartilage removal, and joint surface smoothing.",
//         descPoint6:"Patients experience improved joint function and reduced pain after arthroscopic surgery.",
//     },
//     {
//         id:4,
//         ServiceImage:Service4,
//         Header:"Joint Replacement Surgery",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/joint-replacement-surgery",
//         BannerDesc:"Joint Replacement Surgery is a surgical procedure in which a damaged or arthritic joint is replaced with an artificial joint, relieving pain, improving mobility, and enhancing the overall quality of life for the patient.",
//         ShortDesc1:"Joint Replacement Surgery is a surgical procedure in which a damaged or arthritic joint is replaced with an artificial joint, relieving pain, improving mobility, and enhancing the overall quality of life for the patient.",
//         descPoint1:"Surgical procedure where damaged or arthritic joint surfaces are replaced with artificial implants.",
//         descPoint2:"Commonly performed on knees, hips, shoulders, and other joints.",
//         descPoint3:"Improves joint function, reduces pain, and enhances mobility in patients.",
//         descPoint4:"Used to treat conditions like osteoarthritis, rheumatoid arthritis, and joint degeneration.",
//         descPoint5:"Rehabilitation and physical therapy are essential for successful recovery.",
//         descPoint6:"Advances in materials and techniques have increased the longevity and effectiveness of joint replacements.",
//     },
//     {
//         id:5,
//         ServiceImage:Service5,
//         Header:"Spine Surgery",
//         Branch:"Chembur",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/spine-surgery",
//         BannerDesc:"Spine Surgery is a surgical specialty that focuses on the diagnosis and treatment of conditions affecting the spine, including deformities, injuries, herniated discs, and spinal instability, aiming to relieve pain, restore function, and improve the overall spinal health.",
//         ShortDesc:"Spine Surgery is a surgical specialty that focuses on the diagnosis and treatment of conditions affecting the spine, including deformities, injuries, herniated discs, and spinal instability, aiming to relieve pain, restore function, and improve the overall spinal health.",
//         descPoint1:"Surgical procedures focused on treating spinal conditions and disorders.",
//         descPoint2:"Aimed at relieving pain, stabilizing the spine, and restoring mobility.",
//         descPoint3:"Common spine surgeries include discectomy, spinal fusion, and laminectomy.",
//         descPoint4:"Addressing conditions like herniated discs, spinal stenosis, and degenerative disc disease.",
//         descPoint5:"Rehabilitation and post-surgery care are crucial for optimal outcomes.",
//         descPoint6:"Advancements in techniques, such as minimally invasive spine surgery, have improved patient recovery.",
//     },
//     {
//         id:6,
//         ServiceImage:Service6,
//         Header:"laparoscopic surgery",
//         Branch:"Chembur",
//         category:"Ghatkopar",
//         Slugs:"/laparoscopic-surgery",
//         BannerDesc:"Laparoscopic surgery, also known as minimally invasive surgery or keyhole surgery, is a surgical technique in which operations are performed through small incisions in the body, using a laparoscope (a long, thin tube with a light and camera at the end).",
//         ShortDesc1:"Laparoscopic surgery, also known as minimally invasive surgery or keyhole surgery, is a surgical technique in which operations are performed through small incisions in the body, using a laparoscope (a long, thin tube with a light and camera at the end).",
//         ShortDesc2:"In comparison to traditional open surgery, laparoscopic surgery often results in less pain, a shorter hospital stay, quicker recovery and less scarring. However, not all surgical procedures can be performed laparoscopically, and the specific benefits and risks will depend on the type of surgery being performed.",
//         ShortDesc3:"Laparoscopic procedures are commonly used in general surgery, gynecology, and urology, as well as some forms of weight loss surgery and some types of cancer surgery.",
//         ShortDesc4:"If you are considering laparoscopic surgery, it is important to discuss the specific details and benefits with your surgeon, as well as any potential risks and alternatives.",
//         shortTitle:"There are many different types of laparoscopic surgery, including:",
//         descPoint1:"Laparoscopic Cholecystectomy: a procedure to remove the gallbladder",
//         descPoint2:"Laparoscopic Appendectomy: a procedure to remove the appendix",
//         descPoint3:"Laparoscopic Gastric Bypass: a type of weight loss surgery",
//         descPoint4:"Laparoscopic Hernia Repair: a procedure to repair a hernia",
//         descPoint5:"Laparoscopic Ovarian Cystectomy: a procedure to remove ovarian cysts",
//         descPoint6:"Laparoscopic Hysterectomy: a procedure to remove the uterus",
//         descPoint7:"Laparoscopic Prostatectomy: a procedure to remove the prostate",
//         descPoint8:"Laparoscopic Gastrointestinal Surgery: a type of digestive surgery",
//         descPoint9:"Laparoscopic Colorectal Surgery: a type of surgery for colorectal conditions",
//         descPoint10:"Laparoscopic Splenectomy: a procedure to remove the spleen",
//         Desc1:"These are just a few examples of the types of laparoscopic surgery that are performed. It's important to discuss the specific details of the procedure with your doctor to understand the benefits, risks, and alternatives.",
//     },
//     {
//         id:7,
//         ServiceImage:Service7,
//         Header:"Dedicated Anorectal Surgery",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/dedicated-anorectal-surgery",
//         BannerDesc:"Dedicated Anorectal Surgery: Specialized surgical expertise dedicated to the diagnosis and treatment of anorectal disorders, improving the quality of life for patients suffering from conditions affecting the anal and rectal area.",
//         ShortDesc1:"Dedicated Anorectal Surgery: Specialized surgical expertise dedicated to the diagnosis and treatment of anorectal disorders, improving the quality of life for patients suffering from conditions affecting the anal and rectal area.",
//         descPoint1:"Specialized surgical field focusing on disorders and conditions of the anus and rectum.",
//         Point2:"Addresses issues like hemorrhoids, anal fistulas, fissures, and rectal prolapse.",
//         descPoint3:"Involves advanced diagnostic and treatment techniques tailored for anorectal problems.",
//         descPoint4:"Surgeons trained in colorectal and proctological procedures perform dedicated anorectal surgeries.",
//         descPoint5:"Emphasis on preserving bowel function and improving patients' quality of life.",
//         descPoint6:"May include minimally invasive approaches like stapled hemorrhoidopexy or transanal procedures.",
//     },
//     {
//         id:8,
//         ServiceImage:Service8,
//         Header:"Diabetic Foot Care Clinic",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/diabetic-foot-care-clinic",
//         BannerDesc:"Diabetic Foot Care Clinic User: A person seeking specialized care and treatment at a medical facility focused on addressing and managing foot complications related to diabetes, with the goal of preventing further complications and promoting foot health..",
//         ShortDesc1:"Diabetic Foot Care Clinic User: A person seeking specialized care and treatment at a medical facility focused on addressing and managing foot complications related to diabetes, with the goal of preventing further complications and promoting foot health..",
//         descPoint1:"It focuses on bones, joints, muscles, ligaments, tendons, and nerves.",
//         descPoint2:"It focuses on bones, joints, muscles, ligaments, tendons, and nerves.",
//         descPoint3:"Procedures can range from joint replacements to fracture repair and spine surgeries.",
//         descPoint4:"Orthopedic surgeons use advanced technology, such as arthroscopy, for minimally invasive procedures.",
//         descPoint5:"Rehabilitation and physical therapy are crucial components of post-surgery recovery.",
//         descPoint6:"Orthopedic surgery aims to improve mobility, reduce pain, and enhance overall musculoskeletal function.",
//     },
//     {
//         id:9,
//         ServiceImage:Service9,
//         Header:"General Medicine",
//         Branch:"Chembur",
//         category:"Ghatkopar",
//         Slugs:"/general-medicine",
//         BannerDesc:"Nakshatra ’s General Medicine and Critical Care unit provides immediate care to patients with life-threatening conditions.",
//         ShortDesc1:"Nakshatra ’s General Medicine and Critical Care unit provides immediate care to patients with life-threatening conditions.",
//         ShortDesc2:"General medicine, also known as internal medicine, is a medical specialty that focuses on the diagnosis, treatment, and management of adult illnesses and diseases. Doctors who specialize in general medicine are called internists. They provide comprehensive, long-term care for adults with a wide range of medical conditions, from common issues such as the flu or a broken bone to more complex conditions like diabetes, heart disease, and cancer.",
//         ShortDesc3:"Internists are trained to diagnose and treat a broad range of conditions, and they often coordinate the care provided by other specialists. They take a holistic approach to patient care, considering physical, mental, and social factors when making a diagnosis and creating a treatment plan. They also play a key role in preventive care, helping patients maintain their health and reduce their risk of developing chronic diseases.",
//         ShortDesc4:"General medicine covers a wide range of conditions, and internists may subspecialize in areas such as geriatrics (care of the elderly), endocrinology (the study of hormones and the endocrine system), infectious disease, and others. The goal of general medicine is to provide comprehensive, high-quality care to adult patients and help them maintain their health and well-being.",
//         shortTitle:"There are several branches of medicine that focus on diagnosing and treating various conditions and diseases. Some of the major types of medicine include:",
//         descHead1:"Internal medicine :",
//         descHead2:"Pediatrics :",
//         descHead3:"Obstetrics and gynecology :",
//         descHead4:"Surgery :",
//         descHead5:"Psychiatry :",
//         descHead6:"Neurology :",
//         descHead7:"Dermatology :",
//         descHead8:"Ophthalmology :",
//         descHead9:"Otolaryngology :",
//         descPoint1:"This branch of medicine focuses on the diagnosis and treatment of adult illnesses and chronic conditions, such as diabetes, heart disease, and respiratory problems.",
//         descPoint2:"This branch of medicine deals with the diagnosis and treatment of childhood illnesses and diseases, from infancy to adolescence.",
//         descPoint3:"This branch of medicine deals with the care of women during pregnancy, childbirth, and the postpartum period, as well as the diagnosis and treatment of conditions related to the female reproductive system.",
//         descPoint4:"This branch of medicine involves the diagnosis and treatment of conditions and diseases through surgical procedures.",
//         descPoint5:" This branch of medicine deals with the diagnosis and treatment of mental illness, emotional disorders, and behavioral problems.",
//         descPoint6:"This branch of medicine deals with the diagnosis and treatment of conditions and diseases related to the nervous system, such as Alzheimer's disease, epilepsy, and multiple sclerosis.",
//         descPoint7:"This branch of medicine deals with the diagnosis and treatment of conditions and diseases of the skin, hair, and nails.",
//         descPoint8:"This branch of medicine deals with the diagnosis and treatment of conditions and diseases related to the nervous system, such as Alzheimer's disease, epilepsy, and multiple sclerosis.",
//         descPoint9:"This branch of medicine deals with the diagnosis and treatment of conditions and diseases of the skin, hair, and nails.",
//     },
//     {
//         id:10,
//         ServiceImage:Service10,
//         Header:"I.C.C.U",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/i-c-c-u",
//         BannerDesc:"(Intensive Cardiac Care Unit) Specialized critical care unit dedicated to providing intensive monitoring and treatment for patients with cardiac conditions.",
//         ShortDesc1:"I.C.C.U (Intensive Cardiac Care Unit) Specialized critical care unit dedicated to providing intensive monitoring and treatment for patients with cardiac conditions.",
//         descPoint1:"Intensive Cardiac Care Unit (I.C.C.U) is a specialized medical unit providing intensive care for critically ill cardiac patients.",
//         descPoint2:"Equipped with advanced monitoring and life-supporting equipment for cardiac emergencies.",
//         descPoint3:"Dedicated team of cardiologists, critical care nurses, and specialists manage patients with acute cardiac conditions.",
//         descPoint4:"Focuses on immediate stabilization, continuous monitoring, and specialized interventions.",
//         descPoint5:"Commonly treats heart attacks, heart failure, arrhythmias, and post-cardiac surgeries.",
//         descPoint6:"Critical care provided in the I.C.C.U aims to improve patient outcomes and prevent complications.",
//     },
//     {
//         id:11,
//         ServiceImage:Service11,
//         Header:"Dialysis",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/dialysis",
//         BannerDesc:"Life-sustaining treatment that filters and purifies the blood for individuals with impaired kidney function or end-stage renal disease.",
//         ShortDesc1:"Life-sustaining treatment that filters and purifies the blood for individuals with impaired kidney function or end-stage renal disease.",
//         descPoin1:"Dialysis is a medical procedure that filters and purifies the blood when the kidneys are unable to do so adequately.",
//         descPoint2:"It is a life-sustaining treatment for individuals with kidney failure or end-stage renal disease (ESRD).",
//         descPoint3:"Hemodialysis uses a machine to filter blood outside the body, while peritoneal dialysis uses the abdominal lining to remove waste.",
//         descPoint4:"Dialysis helps maintain proper fluid and electrolyte balance in the body.",
//         descPoint5:"Regular dialysis sessions are necessary for patients awaiting kidney transplant or ineligible for transplantation.",
//         descPoint6:"Dialysis significantly improves the quality of life and prolongs survival for individuals with kidney failure.",
//     },
//     {
//         id:12,
//         ServiceImage:Service12,
//         Header:"Maternity Services",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/maternity-services",
//         BannerDesc:"Comprehensive care and support for expectant mothers throughout pregnancy, childbirth, and postnatal period.",
//         ShortDesc1:"Comprehensive care and support for expectant mothers throughout pregnancy, childbirth, and postnatal period.",
//         descPoint1:"Maternity services encompass healthcare and support provided to pregnant women before, during, and after childbirth.",
//         descPoint2:"Includes prenatal care, childbirth assistance, and postnatal care for mother and baby.",
//         descPoint3:"Offered by obstetricians, midwives, and other healthcare professionals.",
//         descPoint4:"Focuses on ensuring a healthy pregnancy, safe delivery, and maternal and infant well-being.",
//         descPoint5:"May include educational classes, breastfeeding support, and family planning counseling.",
//         descPoint6:"Aimed at promoting a positive and healthy pregnancy experience for expectant mothers.",
//     },
//     {
//         id:13,
//         ServiceImage:Service13,
//         Header:"Neuro Surgery",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/neuro-surgery",
//         BannerDesc:"Neuro Surgery is Surgical specialty focused on diagnosing and treating disorders of the nervous system, including the brain, spine, and peripheral nerves.",
//         ShortDesc1:"Neuro Surgery is Surgical specialty focused on diagnosing and treating disorders of the nervous system, including the brain, spine, and peripheral nerves.",
//         descPoint1:"Neurosurgery is a medical specialty focused on treating disorders and conditions of the nervous system.",
//         descPoint2:"Involves surgical interventions on the brain, spinal cord, and peripheral nerves.",
//         descPoint3:"Neurosurgeons are highly trained specialists who perform complex procedures to address neurological issues.",
//         descPoint4:"Common surgeries include tumor removal, spinal fusion, and treatment of brain aneurysms.",
//         descPoint5:"Utilizes advanced technology like neuroimaging and intraoperative monitoring for precise and safe surgeries.",
//         descPoint6:"Aims to alleviate neurological symptoms, restore function, and improve patients' quality of life.",
//     },
//     {
//         id:14,
//         ServiceImage:Service14,
//         Header:"ENT (Microscopic) Surgery",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/e-n-t-surgery",
//         BannerDesc:"E.N.T. stands for ear, nose, and throat.E.N.T. is a medical specialty that deals with conditions of the ear, nose, throat, head and neck.",
//         ShortDesc1:"E.N.T. stands for ear, nose, and throat.E.N.T. is a medical specialty that deals with conditions of the ear, nose, throat, head and neck.",
//         ShortDesc2:"An E.N.T. doctor, also known as an otolaryngologist, is a physician who is specially trained to diagnose and treat a wide range of conditions in these areas, including ear infections, hearing loss, balance disorders, sinusitis, nasal congestion, snoring and sleep apnea, voice and speech disorders, and head and neck cancer.",
//         ShortDesc3:"E.N.T. doctors use a variety of diagnostic tools, such as hearing tests, endoscopy, and imaging scans, to determine the cause of a patient's symptoms and to develop an appropriate treatment plan. Treatment options may include medications, lifestyle changes, or surgery.",
//         ShortDesc4:"E.N.T. is an important field of medicine that plays a critical role in maintaining the health and well-being of individuals with conditions affecting the ear, nose, throat, head, and neck. If you have symptoms in these areas, it may be helpful to see an E.N.T. specialist for a diagnosis and appropriate treatment.",
//         descHead1:"Ear conditions :",
//         descHead2:"Nose and sinus conditions :",
//         descHead3:"Throat conditions :",
//         descHead4:"Head and neck conditions :",
//         descPoint1:"Ear infections, hearing loss, tinnitus (ringing in the ears), balance disorders, and ear trauma are some of the common ear conditions that an E.N.T. specialist may treat.",
//         descPoint2:"Sinusitis, nasal congestion, allergies, and smell and taste disorders are some of the conditions that affect the nose and sinuses.",
//         descPoint3:"Snoring and sleep apnea, voice and speech disorders, and throat infections are some of the conditions that an E.N.T. specialist may diagnose and treat.",
//         descPoint4:"Head and neck cancer, facial plastic surgery, and head and neck trauma are some of the conditions that an E.N.T. specialist may diagnose and treat.",
//         Desc1:"It is important to note that E.N.T. conditions can often be interrelated, and an E.N.T. specialist will work closely with other healthcare providers to provide a comprehensive diagnosis and treatment plan for the patient.",
//     },
//     {
//         id:15,
//         ServiceImage:Service15,
//         Header:"Cancer Surgery",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/cancer-surgery",
//         BannerDesc:"Surgical procedures aimed at removing cancerous tumors or affected tissues as part of cancer treatment.",
//         ShortDesc1:"Surgical procedures aimed at removing cancerous tumors or affected tissues as part of cancer treatment.",
//         descPoint1:"Cancer surgery is a treatment method involving the surgical removal of cancerous tumors or tissues.",
//         descPoint2:"Aimed at eliminating localized cancer, preventing its spread, and providing a potential cure.",
//         descPoint3:"Often combined with other cancer treatments like chemotherapy and radiation therapy.",
//         descPoint4:"Performed by surgical oncologists specialized in cancer surgery techniques.",
//         descPoint5:"Precision and careful planning are crucial to preserve vital structures and functions.",
//         descPoint6:"The success of cancer surgery depends on early detection, tumor stage, and overall patient health.",
//     },
//     {
//         id:16,
//         ServiceImage:Service16,
//         Header:"Endoscopy",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/endoscopy",
//         BannerDesc:"Minimally invasive procedure using a flexible tube with a camera to visualize and diagnose conditions within the body's organs or cavities.",
//         ShortDesc1:"Minimally invasive procedure using a flexible tube with a camera to visualize and diagnose conditions within the body's organs or cavities.",
//         descPoint1:"Endoscopy is a medical procedure using a flexible tube with a camera to visualize and diagnose internal organs.", 
//         descPoint2:"Aimed at eliminating localized cancer, preventing its spread, and providing a potential cure.",
//         descPoint3:"Performed through natural openings (such as the mouth or anus) or small incisions.",
//         descPoint4:"Provides a minimally invasive way to examine the digestive tract, respiratory system, and other body areas.",
//         descPoint5:"Allows for real-time imaging and direct visualization during the procedure.",
//         descPoint6:"Used for diagnostic and therapeutic purposes across various medical specialties.",
//     },
//     {
//         id:17,
//         ServiceImage:Service17,
//         Header:"In house Chemist",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/in-house-chemist",
//         BannerDesc:"Convenient on-site pharmacy providing a wide range of medications and pharmaceutical services.",
//         ShortDesc1:"Convenient on-site pharmacy providing a wide range of medications and pharmaceutical services.",
//         descPoint1:"An in-house chemist is a pharmacist or pharmacy located within a healthcare facility or organization.",
//         descPoint2:"Provides convenient access to medications for patients within the same premises.",
//         descPoint3:"Dispenses prescription drugs and over-the-counter medications based on healthcare providers' orders.",
//         descPoint4:"Ensures proper medication management, patient counseling, and drug safety.",
//         descPoint5:"Supports healthcare professionals in optimizing medication therapy for patients.",
//         descPoint6:"Reduces the need for patients to visit external pharmacies, promoting seamless healthcare services.",
//     },
//     {
//         id:18,
//         ServiceImage:Service18,
//         Header:"In house 24 Hours Pathology and X-Ray",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/in-house-athology-and-x-Ray",
//         BannerDesc:"Round-the-clock availability of pathology laboratory and X-ray services for timely and accurate diagnostic testing.",
//         ShortDesc1:"Round-the-clock availability of pathology laboratory and X-ray services for timely and accurate diagnostic testing.",
//         descPoint1:"In-house 24-hour pathology and X-ray services provide immediate and continuous diagnostic support within a healthcare facility.",
//         descPoint2:"Enables rapid and accurate test results for timely patient care and treatment.",
//         descPoint3:"Convenient access to pathology tests and X-rays without the need for external facilities.",
//         descPoint4:"Orthopedic surgeons use advanced technology, such as arthroscopy, for minimally invasive procedures.",
//         descPoint5:"Rehabilitation and physical therapy are crucial components of post-surgery recovery.",
//         descPoint6:"Orthopedic surgery aims to improve mobility, reduce pain, and enhance overall musculoskeletal function.",
//     },
//     {
//         id:19,
//         ServiceImage:Service19,
//         Header:"N.I.C.U",
//         Branch:"Ghatkopar",
//         category:"Ghatkopar",
//         Slugs:"/n-i-c-u",
//         BannerDesc:"N.I.C.U (Neonatal Intensive Care Unit): Specialized unit providing intensive medical care for critically ill or premature newborn infants.",
//         ShortDesc1:"N.I.C.U (Neonatal Intensive Care Unit): Specialized unit providing intensive medical care for critically ill or premature newborn infants.",
//         descPoint2:"It focuses on bones, joints, muscles, ligaments, tendons, and nerves.",
//         descPoint3:"Procedures can range from joint replacements to fracture repair and spine surgeries.",
//         descPoint4:"Orthopedic surgeons use advanced technology, such as arthroscopy, for minimally invasive procedures.",
//         descPoint5:"Rehabilitation and physical therapy are crucial components of post-surgery recovery.",
//         descPoint6:"Orthopedic surgery aims to improve mobility, reduce pain, and enhance overall musculoskeletal function.",
//     },
//     {
//         id:20,
//         ServiceImage:Service20,
//         Header:"Peadiatric Surgery",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/peadiatric-surgery",
//         BannerDesc:"Surgical specialty dedicated to the diagnosis, treatment, and management of surgical conditions in infants, children, and adolescents.",
//         ShortDesc1:"Surgical specialty dedicated to the diagnosis, treatment, and management of surgical conditions in infants, children, and adolescents.",
//         descPoint2:"It focuses on bones, joints, muscles, ligaments, tendons, and nerves.",
//         descPoint3:"Procedures can range from joint replacements to fracture repair and spine surgeries.",
//         descPoint4:"Orthopedic surgeons use advanced technology, such as arthroscopy, for minimally invasive procedures.",
//         descPoint5:"Rehabilitation and physical therapy are crucial components of post-surgery recovery.",
//         descPoint6:"Orthopedic surgery aims to improve mobility, reduce pain, and enhance overall musculoskeletal function.",
//     },
//     {
//         id:21,
//         ServiceImage:Service21,
//         Header:"Peadiatric  Medicine",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/peadiatric-medicine",
//         BannerDesc:"Comprehensive medical care specializing in the health and well-being of infants, children, and adolescents.",
//         ShortDesc1:"Comprehensive medical care specializing in the health and well-being of infants, children, and adolescents.",
//         descPoint2:"It focuses on bones, joints, muscles, ligaments, tendons, and nerves.",
//         descPoint3:"Procedures can range from joint replacements to fracture repair and spine surgeries.",
//         descPoint4:"Orthopedic surgeons use advanced technology, such as arthroscopy, for minimally invasive procedures.",
//         descPoint5:"Rehabilitation and physical therapy are crucial components of post-surgery recovery.",
//         descPoint6:"Orthopedic surgery aims to improve mobility, reduce pain, and enhance overall musculoskeletal function.",
//     },
//     {
//         id:22,
//         ServiceImage:Service22,
//         Header:"Obstetrics",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/obstetrics",
//         BannerDesc:"Obstetrics is a medical specialty concerned with the care of women during pregnancy, childbirth, and the postpartum period. It involves managing the medical and surgical aspects of pregnancy and childbirth, as well as providing support and care to women and their families during this important time.",
//         ShortDesc1:"Obstetrics is a medical specialty concerned with the care of women during pregnancy, childbirth, and the postpartum period. It involves managing the medical and surgical aspects of pregnancy and childbirth, as well as providing support and care to women and their families during this important time.",
//         ShortDesc2:"The goal of obstetrics is to ensure the health and well-being of both the mother and her baby during pregnancy, labor, and delivery. This involves performing regular check-ups, monitoring the growth and development of the fetus, and managing any complications that may arise during pregnancy.",
//         ShortDesc3:"Obstetricians work closely with other healthcare professionals, such as midwives and pediatricians, to provide comprehensive care to pregnant women and their families. They also play an important role in educating women about their options for childbirth, including vaginal delivery, cesarean section, and other options for pain relief and comfort during labor.",
//         ShortDesc4:"Overall, obstetrics is a critical field that plays a crucial role in the health and well-being of mothers and babies during pregnancy and childbirth.",
//     },
//     {
//         id:23,
//         ServiceImage:Service23,
//         Header:"Critical Care",
//         Branch:"Chembur",
//         category:"Chembur",
//         Slugs:"/critical-care",
//         BannerDesc:"Critical Care is a specialized branch of medicine that involves the comprehensive management and treatment of critically ill patients in intensive care units (ICUs) using advanced monitoring and life-support techniques.",
//         ShortDesc1:"Critical Care is a specialized branch of medicine that involves the comprehensive management and treatment of critically ill patients in intensive care units (ICUs) using advanced monitoring and life-support techniques.",
//         descPoint1:"Critical care refers to specialized medical care for patients with life-threatening or severe conditions.",
//         descPoint2:"Provided in intensive care units (ICUs) by a team of skilled critical care specialists and nurses.",
//         descPoint3:"Focuses on constant monitoring, advanced life support, and specialized interventions.",
//         descPoint4:"Common critical care scenarios include sepsis, respiratory failure, and cardiac emergencies.",
//         descPoint5:"Aims to stabilize and improve the patient's condition, preventing further deterioration.",
//         descPoint6:"Critical care plays a crucial role in saving lives and supporting patients during their most vulnerable moments.",
//     },
//     {
//         id:24,
//         ServiceImage:Service24,
//         Header:"Neonatology",
//         Branch:"Chembur",
//         category:"Chembur",
//         Slugs:"/neonatology",
//         BannerDesc:"Neonatology is a medical specialty focused on the care of newborn infants, particularly those who are premature or have complex medical conditions.",
//         ShortDesc1:"Neonatology is a medical specialty focused on the care of newborn infants, particularly those who are premature or have complex medical conditions.",
//         descPoint1:"Neonatology is a specialized branch of pediatrics focused on the medical care of newborn infants.",
//         descPoint2:"Neonatologists are pediatricians with additional training in handling premature and critically ill babies.",
//         descPoint3:"Neonatology involves managing respiratory distress, infections, and other neonatal conditions.",
//         descPoint4:"Provides specialized care in neonatal intensive care units (NICUs) for high-risk infants.",
//         descPoint5:"Emphasizes nutrition, developmental support, and family-centered care for newborns.",
//         descPoint6:"Neonatologists work closely with obstetricians and pediatric subspecialists to ensure comprehensive care for newborns.",
//     },
//     {
//         id:25,
//         ServiceImage:Service25,
//         Header:"Surgical Oncology",
//         Branch:"Chembur",
//         category:"Chembur",
//         Slugs:"/surgical-oncology",
//         BannerDesc:"Surgical specialty dedicated to the treatment of cancer through surgical interventions, including tumor removal, lymph node dissection, and reconstructive procedures",
//         ShortDesc1:"Surgical specialty dedicated to the treatment of cancer through surgical interventions, including tumor removal, lymph node dissection, and reconstructive procedures.",
//         descPoint1:"Surgical Oncology is a specialized field of surgery focused on the treatment of cancer through surgical procedures.",
//         descPoint2:"Surgical oncologists are trained to remove tumors and cancerous tissues while preserving healthy organs and functions.",
//         descPoint3:"It plays a vital role in cancer treatment, either as the primary treatment or in combination with other therapies.",
//         descPoint4:"Surgical techniques vary based on tumor type, location, and stage, aiming for complete tumor removal.",
//         descPoint5:"Multidisciplinary collaboration with medical oncologists and radiation oncologists is common for comprehensive cancer care.",
//         descPoint6:"Continual advancements in surgical techniques and technology improve outcomes and patient quality of life.",
//     },
//     {
//         id:26,
//         ServiceImage:Service26,
//         Header:"Urology",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/urology",
//         BannerDesc:"Nakshatra’s expert team of Urologists and Andrologist doctors in Mumbai delivers top-class diagnostic and treatment services along with surgical procedures.",
//         ShortDesc1:"Nakshatra’s expert team of Urologists and Andrologist doctors in Mumbai delivers top-class diagnostic and treatment services along with surgical procedures.",
//         ShortDesc2:"Urology is a medical specialty that focuses on the diagnosis, treatment, and management of conditions affecting the urinary tract and the male reproductive system. Urologists are medical doctors who specialize in this area and are trained to manage a wide range of conditions, including urinary incontinence, urinary tract infections, prostate problems, male infertility, and sexually transmitted diseases. They use a variety of diagnostic techniques, such as urinary tract imaging tests, biopsies, and laboratory tests, to make an accurate diagnosis. Treatment options can include medication, minimally invasive procedures, and surgery, depending on the specific condition and its severity. The goal of urology is to help patients manage their symptoms, improve their quality of life, and maintain or restore their urinary and reproductive health.",
//         shortTitle:"There are several subspecialties within the field of urology, including:",
//         descHead1:"Pediatric urology :",
//         descHead2:"Female urology :",
//         descHead3:"Female urology :",
//         descHead4:"Neurourology :",
//         descHead5:"Renal transplantation :",
//         descHead6:"Oncology :",
//         descHead7:"Endourology :",
//         descPoint1:"This subspecialty focuses on the diagnosis and treatment of urinary and reproductive problems in infants, children, and adolescents.",
//         descPoint2:"This subspecialty focuses on the diagnosis and treatment of urinary and pelvic floor disorders in women, including urinary incontinence and pelvic organ prolapse.",
//         descPoint3:"This subspecialty focuses on the diagnosis and treatment of urinary and pelvic floor disorders in women, including urinary incontinence and pelvic organ prolapse.",
//         descPoint4:"This subspecialty focuses on the management of conditions that affect the urinary and reproductive systems as a result of nerve or spinal cord injuries, such as neurogenic bladder.",
//         descPoint5:"This subspecialty focuses on the surgical placement of a healthy kidney from a donor into a person with kidney failure.",
//         descPoint6:"This subspecialty focuses on the diagnosis and treatment of urologic cancers, such as prostate cancer, bladder cancer, and testicular cancer.",
//         descPoint7:"This subspecialty focuses on the use of minimally invasive techniques, such as lasers and endoscopes, to diagnose and treat urinary tract conditions.",
//     },
//     {
//         id:27,
//         ServiceImage:Service27,
//         Header:"Executive Health Checkup",
//         Branch:"Chembur",
//         category:"both",
//         Slugs:"/executive-health-checkup",
//         BannerDesc:"Comprehensive medical examination and assessment aimed at evaluating overall health and detecting potential health risks in individuals, typically tailored for executives or professionals.",
//         ShortDesc1:"Comprehensive medical examination and assessment aimed at evaluating overall health and detecting potential health risks in individuals, typically tailored for executives or professionals.",
//         descPoint1:"Executive health checkup is a comprehensive medical assessment tailored for busy professionals and executives.",
//         descPoint2:"It includes a range of tests and screenings to assess overall health and detect potential health issues early.",
//         descPoint3:"The checkup typically covers cardiac health, blood tests, imaging, and other relevant evaluations.",
//         descPoint4:"Aimed at providing a thorough health status review and personalized health recommendations.",
//         descPoint5:"Helps executives to proactively manage their health and make informed decisions for a healthy lifestyle.",
//         descPoint6:"May be conducted annually or periodically, based on individual health needs and preferences.",
//     },
//     {
//         id:28,
//         ServiceImage:Service28,
//         Header:"General surgery",
//         Branch:"Chembur",
//         category:"Both",
//         Slugs:"/general-surgery",
//         BannerDesc:"General surgery is a medical specialty that involves the surgical treatment of a wide range of conditions and diseases affecting many parts of the body, including the abdomen, skin, and endocrine system. General surgeons are trained to perform operations ranging from simple procedures, such as the removal of a small skin lesion, to complex procedures, such as the treatment of cancer or the repair of a hernia.",
//         ShortDesc1:"General surgery is a medical specialty that involves the surgical treatment of a wide range of conditions and diseases affecting many parts of the body, including the abdomen, skin, and endocrine system. General surgeons are trained to perform operations ranging from simple procedures, such as the removal of a small skin lesion, to complex procedures, such as the treatment of cancer or the repair of a hernia.",
//         ShortDesc2:"The goal of general surgery is to treat the underlying condition or disease, restore the normal function of the affected body part, and improve the patient's overall quality of life. General surgeons work closely with other medical specialists, such as gastroenterologists, oncologists, and radiologists, to provide comprehensive care for their patients.",
//         shortTitle:"Examples of common general surgical procedures include:",
//         descPoint1:"Appendectomy (removal of the appendix)",
//         descPoint2:"Cholecystectomy (removal of the gallbladder)",
//         descPoint3:"Hernia repair",
//         descPoint4:"Colectomy (removal of part of the colon)",
//         descPoint5:"Gastrectomy (removal of part of the stomach)",
//         descPoint6:"Thyroidectomy (removal of part or all of the thyroid gland)",
//         Desc1:"It is important to note that general surgery is a complex field, and each patient's treatment plan will depend on the specific condition they are facing and their individual medical history. If you need a general surgical procedure, it is important to find a qualified and experienced general surgeon to discuss your options and determine the best course of treatment for you.",
//     },
//     {
//         id:29,
//         ServiceImage:Service22,
//         Header:"Gynacology",
//         Branch:"Chembur",
//         category:"Chembur",
//         Slugs:"/gynacology",
//         BannerDesc:"Gynecology is a medical specialty that focuses on the health of the female reproductive system and the female reproductive organs, including the uterus, ovaries, fallopian tubes, and vagina. Gynecologists are medical doctors who specialize in diagnosing and treating a wide range of conditions related to women's reproductive health, including menstrual problems, infertility, menopause, pregnancy, childbirth, and various gynecological cancers. They also provide preventive care, such as pelvic exams, pap tests, and screenings for sexually transmitted infections. Gynecologists play a critical role in ensuring the overall health and well-being of women.",
//         ShortDesc1:"Gynecology is a medical specialty that focuses on the health of the female reproductive system and the female reproductive organs, including the uterus, ovaries, fallopian tubes, and vagina. Gynecologists are medical doctors who specialize in diagnosing and treating a wide range of conditions related to women's reproductive health, including menstrual problems, infertility, menopause, pregnancy, childbirth, and various gynecological cancers. They also provide preventive care, such as pelvic exams, pap tests, and screenings for sexually transmitted infections. Gynecologists play a critical role in ensuring the overall health and well-being of women.",
//         shortTitle:"There are many different types of gynecological conditions that a gynecologist may diagnose and treat. Some common gynecological conditions include:",
//         descHead1:"Menstrual disorders :",
//         descHead2:"Infertility :",
//         descHead3:"Polycystic Ovary Syndrome (PCOS) :",
//         descHead4:"Endometriosis :",
//         descHead5:"Uterine fibroids :",
//         descHead6:"Ovarian cysts :",
//         descHead7:"Sexually transmitted infections (STIs) :",
//         descPoint1:"Irregular, heavy, or painful periods, as well as premenstrual syndrome (PMS), can be treated by a gynecologist.",
//         descPoint2:"Gynecologists can help women who are having trouble getting pregnant by evaluating their reproductive health and offering treatments such as fertility drugs or in vitro fertilization (IVF).",
//         descPoint3:"PCOS is a hormonal disorder that can cause irregular periods, acne, and weight gain.",
//         descPoint4:"Endometriosis is a condition where the tissue that normally lines the inside of the uterus grows outside of it, causing pain and infertility.",
//         descPoint5:"Fibroids are noncancerous growths in the uterus that can cause heavy bleeding, pain, and infertility.",
//         descPoint6:" Ovarian cysts are fluid-filled sacs that develop in the ovaries and can cause pain or infertility.",
//         descPoint7:"Gynecologists can test for and treat STIs, including chlamydia, gonorrhea, and herpes.",
//     },

// ]

const ServiceData = [
    {
        id:1,
        ServiceImage:Service1,
        Header:"Orthopedic Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/orthopedic-surgery",
        ShortDesc:"Orthopedic surgery is a specialized branch of medicine that focuses on the diagnosis, treatment, and prevention of musculoskeletal conditions and injuries. Orthopedic surgeons are skilled professionals dedicated to restoring the function and mobility of bones, joints, muscles, ligaments, and tendons. With a commitment to both surgical and non-surgical approaches, orthopedic surgery aims to improve the quality of life for individuals of all ages by addressing a wide range of orthopedic issues.",
        BannerDesc:"Orthopedic surgery is a specialized branch of medicine that focuses on the diagnosis, treatment, and prevention of musculoskeletal conditions and injuries. Orthopedic surgeons are skilled professionals dedicated to restoring the function and mobility of bones, joints, muscles, ligaments, and tendons. With a commitment to both surgical and non-surgical approaches, orthopedic surgery aims to improve the quality of life for individuals of all ages by addressing a wide range of orthopedic issues.",
        ShortDesc1:"Orthopedic surgery is a crucial medical field that deals with the management of musculoskeletal disorders, including fractures, joint injuries, degenerative conditions, and more. Orthopedic surgeons employ advanced techniques to provide comprehensive care, whether it involves surgery, rehabilitation, or preventive measures. By focusing on restoring function and alleviating pain, orthopedic surgery contributes to enhancing patients' overall well-being and physical capabilities.",
        shortTitle:"Orthopedic Surgery: Advancing Mobility and Well-being Through Expert Musculoskeletal Care and Innovative Treatment Approaches",
        descHead1:"Comprehensive Musculoskeletal Care :",
        descHead2:"Surgical and Non-Surgical Approaches :",
        descHead3:"Joint Replacement Expertise :",
        descHead4:"Trauma Care :",
        descHead5:"Pediatric Orthopedics :",
        descHead6:"Pediatric Orthopedics :",
        descPoint1:"Orthopedic surgery encompasses a wide range of conditions, from sports injuries to degenerative diseases like arthritis.",
        descPoint2:"Orthopedic surgeons employ various techniques, including surgery, medication, physical therapy, and other interventions.",
        descPoint3:"Surgeons specialize in joint replacements like hip, knee, and shoulder, helping patients regain mobility and comfort.",
        descPoint4:"Orthopedic surgeons handle traumatic injuries, such as fractures and dislocations, providing prompt and effective treatment.",
        descPoint5:"Specialists address musculoskeletal issues in children, ensuring healthy growth and development.",
        descPoint6:" Orthopedic surgeons play a key role in sports medicine, diagnosing and treating athletic injuries to facilitate recovery.",
    },
    {
        id:2,
        ServiceImage:Service5,
        Header:"Spine Surgery",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/spine-surgery",
        ShortDesc:"Spine surgery is a specialized field within orthopedic and neurosurgery that focuses on the diagnosis, treatment, and correction of spinal disorders and conditions. It involves surgical interventions aimed at alleviating pain, restoring function, and improving the quality of life for individuals suffering from spine-related issues. With advancements in technology and surgical techniques, spine surgery offers effective solutions for a range of spinal conditions, from degenerative disorders to spinal deformities..",
        BannerDesc:"Spine surgery is a specialized field within orthopedic and neurosurgery that focuses on the diagnosis, treatment, and correction of spinal disorders and conditions. It involves surgical interventions aimed at alleviating pain, restoring function, and improving the quality of life for individuals suffering from spine-related issues. With advancements in technology and surgical techniques, spine surgery offers effective solutions for a range of spinal conditions, from degenerative disorders to spinal deformities.",
        ShortDesc1:"Spine surgery is a specialized medical discipline dedicated to addressing a wide spectrum of spinal disorders and conditions. Surgeons in this field employ cutting-edge techniques to treat issues such as herniated discs, spinal stenosis, scoliosis, and more. By utilizing minimally invasive procedures and advanced instrumentation, spine surgery aims to reduce pain, improve mobility, and enhance the overall well-being of patients with spinal problems.",
        shortTitle:"Spine Surgery: Pioneering Relief and Restoration for Spinal Disorders Through State-of-the-Art Techniques and Individualized Treatment",
        descHead1:"Diverse Spinal Conditions :",
        descHead2:"Minimally Invasive Techniques :",
        descHead3:"Precision Instrumentation :",
        descHead4:"Spinal Fusion :",
        descHead5:"Microdiscectomy :",
        descHead6:"Scoliosis Correction :",
        descPoint1:"Spine surgery encompasses various conditions, including herniated discs, spinal fractures, degenerative disc disease, and spinal deformities.",
        descPoint2:"Surgeons utilize minimally invasive approaches whenever possible, leading to smaller incisions, less pain, and quicker recovery times.",
        descPoint3:"Advanced tools and instrumentation enable surgeons to perform complex procedures with greater precision and minimal disruption to surrounding tissues.",
        descPoint4:"This procedure aims to stabilize and fuse two or more vertebrae to alleviate pain and address issues like spinal instability.",
        descPoint5:"A minimally invasive procedure that removes a portion of a herniated disc to relieve pressure on nerves and reduce pain.",
        descPoint6:"Spine surgeons specialize in correcting spinal deformities like scoliosis, enhancing posture and restoring a balanced spine.",
    },
    {
        id:3,
        ServiceImage:Service9,
        Header:"Medicine",
        Branch:"Both",
        category:"Both",
        Slugs:"/general-medicine",
        ShortDesc:"General medicine, often referred to as internal medicine, is a comprehensive medical field that focuses on the diagnosis, treatment, and prevention of a wide range of illnesses and health issues in adults. General medicine practitioners, known as internists or general physicians, are highly trained to provide holistic care and manage various medical conditions, serving as the primary point of contact for patients seeking medical assistance.",
        BannerDesc:"General medicine, often referred to as internal medicine, is a comprehensive medical field that focuses on the diagnosis, treatment, and prevention of a wide range of illnesses and health issues in adults. General medicine practitioners, known as internists or general physicians, are highly trained to provide holistic care and manage various medical conditions, serving as the primary point of contact for patients seeking medical assistance.",
        ShortDesc1:"General medicine, also known as internal medicine, encompasses a broad spectrum of medical care dedicated to the health and well-being of adults. It involves diagnosing, treating, and managing various acute and chronic illnesses, as well as promoting preventive measures to maintain optimal health. General physicians play a crucial role in providing comprehensive healthcare services, from routine check-ups to complex disease management.",
        shortTitle:"General Medicine: Nurturing Health and Wellness through Expert Diagnosis, Holistic Care, and Preventive Medicine",
        descHead1:"Comprehensive Care :",
        descHead2:"Diagnosis and Treatment :",
        descHead3:"Preventive Medicine :",
        descHead4:"Coordination of Care :",
        descHead5:"Health Promotion :",
        descHead6:"Holistic Approach :",
        descPoint1:"General medicine covers a wide array of health issues, addressing everything from common colds to chronic diseases like diabetes and hypertension.",
        descPoint2:"General physicians are skilled in diagnosing medical conditions through patient history, physical examinations, and diagnostic tests.",
        descPoint3:"Emphasis on preventive care helps patients maintain good health, manage risk factors, and avoid potential health complications.",
        descPoint4:"General physicians often serve as primary care providers who coordinate and manage patients' overall healthcare journey.",
        descPoint5:"They offer guidance on healthy lifestyle choices, nutrition, exercise, and disease prevention to enhance patients' well-being.",
        descPoint6:"General medicine takes into account patients' physical, emotional, and mental health, providing a well-rounded approach to healthcare.",
    },
    {
        id:4,
        ServiceImage:Service13,
        Header:"Neuro Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/neuro-surgery",
        ShortDesc:"Neuro surgery, a specialized branch of medical science, focuses on the surgical treatment of disorders affecting the nervous system. This intricate field involves surgical interventions on the brain, spinal cord, and peripheral nerves to address conditions like brain tumors, spinal cord injuries, neurovascular disorders, and more. Neuro surgeons are highly skilled professionals who employ advanced techniques and technologies to provide patients with effective surgical solutions for complex neurological issues.",
        BannerDesc:"Neuro surgery, a specialized branch of medical science, focuses on the surgical treatment of disorders affecting the nervous system. This intricate field involves surgical interventions on the brain, spinal cord, and peripheral nerves to address conditions like brain tumors, spinal cord injuries, neurovascular disorders, and more. Neuro surgeons are highly skilled professionals who employ advanced techniques and technologies to provide patients with effective surgical solutions for complex neurological issues.",
        ShortDesc1:"Neuro surgery is a specialized medical discipline dedicated to the surgical management of disorders related to the nervous system. It encompasses intricate procedures performed on the brain, spinal cord, and peripheral nerves to treat a variety of conditions, such as brain tumors, spinal deformities, aneurysms, and nerve injuries. Neuro surgeons are trained to navigate the delicate structures of the nervous system, ensuring optimal outcomes for patients.",
        shortTitle:"Neuro Surgery: Precision and Expertise in Surgical Interventions for Complex Neurological Conditions",
        descHead1:"Complex Procedures :",
        descHead2:"Brain Tumor Treatment :",
        descHead3:"Spinal Cord Interventions :",
        descHead4:"Neurovascular Disorders :",
        descHead5:"Minimally Invasive Techniques :",
        descHead6:"Collaborative Care :",
        descPoint1:"Neuro surgery involves intricate surgeries on the brain and spinal cord, requiring specialized skills and expertise.",
        descPoint2:"Neuro surgeons perform tumor resections to remove brain tumors, often collaborating with oncologists for comprehensive care.",
        descPoint3:"Surgical procedures address spinal cord injuries, herniated discs, and deformities to alleviate pain and improve mobility.",
        descPoint4:"Surgeons manage neurovascular conditions like aneurysms and arteriovenous malformations to prevent complications.",
        descPoint5:"Advanced technologies enable neuro surgeons to perform minimally invasive procedures, reducing risks and recovery times.",
        descPoint6:" Neuro surgeons work alongside neurologists, radiologists, and other specialists to provide holistic care for patients with neurological conditions.",
    },
    {
        id:5,
        ServiceImage:Service14,
        Header:"ENT (Microscopic) Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/e-n-t-surgery",
        ShortDesc:"ENT (Ear, Nose, and Throat) Microscopic Surgery is a specialized field of medicine that focuses on diagnosing and treating disorders of the ear, nose, throat, and related structures using advanced microscopic techniques. This surgical approach allows for highly precise interventions, resulting in improved outcomes and minimal discomfort for patients. ENT surgeons who specialize in microscopic surgery use specialized instruments and visualization tools to address a wide range of conditions affecting hearing, balance, breathing, and vocal function.",
        BannerDesc:"ENT (Ear, Nose, and Throat) Microscopic Surgery is a specialized field of medicine that focuses on diagnosing and treating disorders of the ear, nose, throat, and related structures using advanced microscopic techniques. This surgical approach allows for highly precise interventions, resulting in improved outcomes and minimal discomfort for patients. ENT surgeons who specialize in microscopic surgery use specialized instruments and visualization tools to address a wide range of conditions affecting hearing, balance, breathing, and vocal function.",
        ShortDesc1:"ENT (Microscopic) Surgery is a specialized branch of medicine that employs advanced microscopic techniques to diagnose and treat disorders of the ear, nose, throat, and adjacent areas. Surgeons use specially designed microscopes and instruments to perform precise interventions for conditions such as chronic sinusitis, ear infections, vocal cord issues, and nasal obstructions. This approach ensures accurate diagnosis, minimally invasive procedures, and enhanced patient recovery.",
        shortTitle:"ENT (Microscopic) Surgery: Precision Interventions for Ear, Nose, and Throat Disorders Using Advanced Microscopic Techniques",
        descHead1:"Enhanced Precision :",
        descHead2:"Ear Disorders :",
        descHead3:"Nasal and Sinus Issues :",
        descHead4:"Throat and Vocal Cord Concerns :",
        descHead5:"Minimized Trauma :",
        descHead6:"Balanced Approach :",
        descPoint1:"Microscopic surgery provides surgeons with a detailed view of the surgical area, enabling precise interventions.",
        descPoint2:"Microscopic techniques are used to treat conditions like chronic ear infections, hearing loss, and perforated eardrums.",
        descPoint3:"Surgeons address chronic sinusitis, nasal polyps, and deviated septums using minimally invasive methods.",
        descPoint4:"Microscopic surgery treats vocal cord nodules, polyps, and other throat-related conditions.",
        descPoint5:"Microscopic interventions result in smaller incisions, reduced tissue damage, and quicker recovery times.",
        descPoint6:"Surgeons balance the removal of diseased tissue with the preservation of healthy structures.",
    },
    {
        id:6,
        ServiceImage:Service20,
        Header:"Peadiatric Surgery",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/peadiatric-surgery",
        ShortDesc:"Pediatric Surgery is a specialized medical field dedicated to the surgical care of infants, children, and adolescents. This branch of surgery involves the diagnosis, treatment, and management of a wide range of congenital and acquired conditions that require surgical intervention in young patients. Pediatric surgeons are trained to address anatomical, physiological, and emotional differences unique to children, ensuring their well-being and healthy development.",
        BannerDesc:"Pediatric Surgery is a specialized medical field dedicated to the surgical care of infants, children, and adolescents. This branch of surgery involves the diagnosis, treatment, and management of a wide range of congenital and acquired conditions that require surgical intervention in young patients. Pediatric surgeons are trained to address anatomical, physiological, and emotional differences unique to children, ensuring their well-being and healthy development.",
        ShortDesc1:"Pediatric Surgery is a medical specialty focused on performing surgical procedures on infants, children, and adolescents. Pediatric surgeons are equipped to treat a diverse range of conditions, from congenital anomalies to injuries and tumors, all while considering the distinct medical and emotional needs of young patients. The field aims to provide compassionate care and optimal outcomes for pediatric patients.",
        shortTitle:"Pediatric Surgery: Nurturing Health and Well-being Through Specialized Surgical Care for Infants, Children, and Adolescents",
        descHead1:"Specialized Training :",
        descHead2:"Congenital Anomalies :",
        descHead3:"Pediatric Trauma :",
        descHead4:"Tumor Removal :",
        descHead5:"Minimally Invasive Techniques :",
        descHead6:"Psychosocial Aspects :",
        descPoint1:"Pediatric surgeons undergo extensive training to manage surgical issues specific to children, considering their unique anatomy and physiology.",
        descPoint2:"Pediatric surgeons address birth defects like cleft lip and palate, heart defects, and gastrointestinal malformations.",
        descPoint3:"These specialists handle injuries and accidents in children, ensuring appropriate care and timely interventions.",
        descPoint4:"Pediatric surgeons remove tumors and growths in various parts of the body, collaborating with oncologists for comprehensive care.",
        descPoint5:"Many pediatric surgeries utilize minimally invasive procedures, resulting in smaller incisions and faster recovery.",
        descPoint6:"Pediatric surgeons work closely with families, addressing emotional and psychological aspects of surgery on young patients.",
    },
    {
        id:7,
        ServiceImage:Service21,
        Header:"Peadiatric",
        Branch:"Both",
        category:"Both",
        Slugs:"/peadiatric",
        ShortDesc:"Pediatrics is a branch of medicine dedicated to the health and well-being of infants, children, and adolescents. It encompasses the comprehensive care, diagnosis, treatment, and prevention of various health conditions that affect young individuals. Pediatricians play a vital role in monitoring growth and development, providing vaccinations, managing illnesses, and guiding parents on proper child care practices.",
        BannerDesc:"Pediatrics is a branch of medicine dedicated to the health and well-being of infants, children, and adolescents. It encompasses the comprehensive care, diagnosis, treatment, and prevention of various health conditions that affect young individuals. Pediatricians play a vital role in monitoring growth and development, providing vaccinations, managing illnesses, and guiding parents on proper child care practices.",
        ShortDesc1:"Pediatrics is a medical specialty focused on the holistic care of children from birth through adolescence. Pediatricians are trained to address physical, emotional, and developmental needs, offering preventive care, medical treatments, and guidance to ensure children thrive and maintain optimal health.",
        shortTitle:"Pediatrics: Nurturing Childhood Health and Development Through Comprehensive Medical Care for Infants, Children, and Adolescents",
        descHead1:"Wellness Visits :",
        descHead2:"Childhood Vaccinations :",
        descHead3:"Illness Diagnosis and Treatment :",
        descHead4:"Developmental Milestones :",
        descHead5:"Parental Guidance :",
        descHead6:"Chronic Conditions :",
        descPoint1:"Pediatricians conduct routine check-ups to monitor growth, development, and overall health, providing guidance on nutrition and healthy habits.",
        descPoint2:"They administer vaccines to protect against infectious diseases, ensuring a strong foundation for a healthy future.",
        descPoint3:"Pediatricians diagnose and treat common childhood illnesses like colds, infections, and allergies, tailoring treatments to a child's specific needs.",
        descPoint4:"They track milestones such as speech, motor skills, and social interactions, intervening if delays are detected.",
        descPoint5:"Pediatricians offer guidance on parenting techniques, safety measures, and promoting a nurturing environment at home.",
        descPoint6:"Pediatricians manage chronic health conditions in children, collaborating with specialists when needed.",
    },
    {
        id:8,
        ServiceImage:Service22,
        Header:"OBS & GYNEAC",
        Branch:"Both",
        category:"Both",
        Slugs:"/obstetrics",
        ShortDesc:"OBS & GYNEAC is a specialized field of medicine dedicated to the care of pregnant women, childbirth, and postpartum care. Obstetricians, also known as OB-GYNs, play a crucial role in ensuring the health and well-being of both the expectant mother and her developing baby. Their expertise covers prenatal care, monitoring fetal growth, managing pregnancy complications, guiding labor and delivery, and offering postnatal support.",
        BannerDesc:"OBS & GYNEAC is a specialized field of medicine dedicated to the care of pregnant women, childbirth, and postpartum care. Obstetricians, also known as OB-GYNs, play a crucial role in ensuring the health and well-being of both the expectant mother and her developing baby. Their expertise covers prenatal care, monitoring fetal growth, managing pregnancy complications, guiding labor and delivery, and offering postnatal support.",
        ShortDesc1:"OBS & GYNEAC is the branch of medicine that focuses on the unique medical needs of pregnant women, encompassing prenatal care, childbirth, and postpartum recovery. Obstetricians provide comprehensive care throughout pregnancy, ensuring the safety and health of both mother and baby.",
        shortTitle:"OBS & GYNEAC: Nurturing Pregnancy and Ensuring Safe Delivery Through Expert Care for Expectant Mothers and Their Developing Babies",
        descHead1:"Prenatal Care :",
        descHead2:"Fetal Monitoring :",
        descHead3:"Labor and Delivery :",
        descHead4:"High-Risk Pregnancies :",
        descHead5:"Postpartum Care :",
        descHead6:"Family Planning :",
        descPoint1:"Obstetricians monitor the health of expectant mothers through regular check-ups, screenings, and ultrasounds to ensure a healthy pregnancy.",
        descPoint2:"They track fetal growth and development, addressing any concerns that may arise during pregnancy.",
        descPoint3:"OBS & GYNEAC guide women through labor and childbirth, offering pain management options and interventions if necessary.",
        descPoint4:"For pregnancies with complications or medical conditions, obstetricians provide specialized care to manage risks and ensure a safe outcome.",
        descPoint5:"After delivery, obstetricians support women in their postpartum recovery, addressing physical and emotional changes.",
        descPoint6:"Obstetricians offer guidance on family planning, contraception, and reproductive health to women throughout their lives.",
    },
    {
        id:9,
        ServiceImage:Service25,
        Header:"Oncology",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/surgical-oncology",
        ShortDesc:"Oncology is a critical medical field dedicated to the study, diagnosis, treatment, and management of cancer. Oncologists, specialized physicians in this domain, work tirelessly to provide comprehensive care for cancer patients, tailoring treatment plans that encompass a range of modalities. Their goal is to improve patient outcomes, enhance quality of life, and advance our understanding of cancer and its complexities.",
        BannerDesc:"Oncology is a critical medical field dedicated to the study, diagnosis, treatment, and management of cancer. Oncologists, specialized physicians in this domain, work tirelessly to provide comprehensive care for cancer patients, tailoring treatment plans that encompass a range of modalities. Their goal is to improve patient outcomes, enhance quality of life, and advance our understanding of cancer and its complexities.",
        ShortDesc1:"Oncology is a specialized branch of medicine focused on the diagnosis, treatment, and ongoing care of individuals with cancer. Oncologists utilize a multidisciplinary approach to address various cancer types and stages, striving to provide personalized and effective care.",
        shortTitle:"Oncology: Advancing Cancer Care Through Comprehensive Diagnosis, Tailored Treatment, and Ongoing Support for Patients Fighting Against Cancer",
        descHead1:"Diagnosis :",
        descHead2:"Treatment Planning :",
        descHead3:"Multidisciplinary Approach :",
        descHead4:"Palliative Care :",
        descHead5:"Research and Clinical Trials :",
        descHead6:"Patient Support :",
        descPoint1:"Oncologists use advanced imaging, biopsies, and molecular testing to accurately diagnose cancer, identifying its type and stage.",
        descPoint2:"Based on diagnosis, oncologists develop personalized treatment plans that may include surgery, chemotherapy, radiation therapy, immunotherapy, and targeted therapies.",
        descPoint3:"Oncology often involves collaboration with other medical specialists, such as surgeons, radiologists, and pathologists, to provide comprehensive care.",
        descPoint4:"Oncologists prioritize the management of cancer symptoms and side effects to improve patients' quality of life during and after treatment.",
        descPoint5:"Oncologists contribute to groundbreaking research and clinical trials, aiming to develop innovative treatments and improve cancer outcomes.",
        descPoint6:" Emotional and psychological support is integral; oncologists provide guidance, address patient concerns, and offer resources for patients and their families.",
    },
    {
        id:10,
        ServiceImage:Service26,
        Header:"Urology",
        Branch:"Both",
        category:"Both",
        Slugs:"/urology",
        ShortDesc:"Urology is a specialized medical field dedicated to the diagnosis, treatment, and management of conditions related to the urinary tract and male reproductive system. Urologists, skilled in both surgical and non-surgical approaches, address a wide range of urological issues to ensure patients' urinary health and overall well-being.",
        BannerDesc:"Urology is a specialized medical field dedicated to the diagnosis, treatment, and management of conditions related to the urinary tract and male reproductive system. Urologists, skilled in both surgical and non-surgical approaches, address a wide range of urological issues to ensure patients' urinary health and overall well-being.",
        ShortDesc1:"Urology is a vital medical discipline focused on the urinary tract and male reproductive system. Urologists diagnose and treat various conditions, from kidney stones to prostate cancer, utilizing advanced techniques to improve patients' urinary health.",
        shortTitle:"Urology: Advancing Urinary Health and Male Reproductive Care Through Comprehensive Diagnostics, Surgical Excellence, and Patient-Centered Solutions",
        descHead1:"Comprehensive Diagnostics :",
        descHead2:"Kidney Health :",
        descHead3:"Prostate Health :",
        descHead4:"Urinary Tract Disorders :",
        descHead5:"Male Reproductive Health :",
        descHead6:"Surgical Expertise :",
        descPoint1:"Urologists employ advanced imaging, tests, and procedures to diagnose conditions affecting the urinary tract and male reproductive organs.",
        descPoint2:"Urologists manage kidney-related issues, such as kidney stones, infections, and kidney diseases, ensuring proper kidney function.",
        descPoint3:"Monitoring and treating conditions like enlarged prostate (BPH) and prostate cancer is a key focus for urologists.",
        descPoint4:"Urologists address urinary tract infections, bladder dysfunction, and urinary incontinence through various medical and surgical interventions.",
        descPoint5:"Urologists manage male reproductive health concerns, including erectile dysfunction, infertility, and testicular disorders.",
        descPoint6:"Urologists perform surgical procedures such as minimally invasive surgeries, laser treatments, and robotic-assisted surgeries for optimal patient outcomes.",
    },
    // {
    //     id:11,
    //     ServiceImage:Service22,
    //     Header:"Gynacology",
    //     Branch:"Both",
    //     category:"Both",
    //     Slugs:"/gynacology",
    //     ShortDesc:"Explore the intricacies of gynecology in this comprehensive guide that delves into various aspects of women's health, reproductive system, and well-being. From puberty to menopause, fertility to pregnancy, and beyond, this guide offers valuable insights and information to empower women with knowledge about their bodies and health.",
    //     BannerDesc:"Explore the intricacies of gynecology in this comprehensive guide that delves into various aspects of women's health, reproductive system, and well-being. From puberty to menopause, fertility to pregnancy, and beyond, this guide offers valuable insights and information to empower women with knowledge about their bodies and health.",
    //     ShortDesc1:"Gynecology is a specialized field of medicine dedicated to the health and well-being of the female reproductive system. Covering a broad spectrum of concerns, gynecology addresses issues from adolescence through the various stages of life, including pregnancy and menopause. This comprehensive guide provides essential information about reproductive anatomy, menstrual health, contraception, fertility, common gynecological conditions, and the importance of regular check-ups. With this knowledge, women can make informed decisions about their health and take proactive steps to ensure a lifetime of well-being.",
    //     shortTitle:" Understanding Women's Health: A Comprehensive Guide to Gynecology",
    //     descHead1:"Reproductive Anatomy :",
    //     descHead2:"Menstrual Health :",
    //     descHead3:"Contraception Options :",
    //     descHead4:"Fertility and Pregnancy :",
    //     descHead5:"Common Gynecological Conditions :",
    //     descHead6:"Regular Check-ups and Screenings :",
    //     descPoint1:"Understand the intricate structures of the female reproductive system, including the uterus, ovaries, fallopian tubes, and cervix, to foster awareness and better self-care.",
    //     descPoint2:"Explore the menstrual cycle, its phases, and how to manage menstrual symptoms effectively. Learn about irregularities, potential causes, and when to consult a gynecologist.",
    //     descPoint3:"Discover various contraception methods available, from hormonal options like birth control pills to non-hormonal choices like barrier methods, aiding women in making informed decisions aligned with their preferences.",
    //     descPoint4:"Gain insights into fertility factors, conception, and preconception care. Explore the journey through pregnancy, from prenatal care to labor, delivery, and postpartum recovery.",
    //     descPoint5:"Learn about prevalent conditions such as polycystic ovary syndrome (PCOS), endometriosis, and urinary tract infections (UTIs), including their symptoms, causes, and available treatments.",
    //     descPoint6:"Recognize the significance of routine gynecological check-ups and screenings, including Pap smears and mammograms, in detecting potential issues early and maintaining optimal health.",
    // },
    {
        id:12,
        ServiceImage:SerImg6,
        Header:"General Surgery",
        category:"Both",
        Slugs:"/general-surgeon",
        ShortDesc:"Delve into the dynamic world of general surgery and the crucial role that general surgeons play in diagnosing, treating, and performing a wide range of surgical procedures. From emergency interventions to elective surgeries, this comprehensive content provides insights into the skills, responsibilities, and importance of general surgeons in the field of medicine.",
        BannerDesc:"Delve into the dynamic world of general surgery and the crucial role that general surgeons play in diagnosing, treating, and performing a wide range of surgical procedures. From emergency interventions to elective surgeries, this comprehensive content provides insights into the skills, responsibilities, and importance of general surgeons in the field of medicine.",
        ShortDesc1:"General surgeons are medical professionals who specialize in performing surgical procedures across various areas of the body. Their expertise spans from routine surgeries to complex interventions, encompassing emergency trauma care, elective procedures, and critical surgeries. This content offers a glimpse into the multifaceted role of a general surgeon, showcasing their dedication to patient care, precision in the operating room, and commitment to improving lives through surgical excellence.",
        shortTitle:"Mastering the Art of Surgery: Exploring the Role of a General Surgery",
        descHead1:"Versatility in Surgical Procedures :",
        descHead2:"Emergency Care:",
        descHead3:"Preoperative and Postoperative Care :",
        descHead4:"Collaboration with Specialists :",
        descHead5:"Precision and Skill :",
        descHead6:"Lifelong Learning :",
        descPoint1:"Explore the diverse scope of surgeries that general surgeons undertake, including appendectomies, hernia repairs, gallbladder removals, and more. Their ability to adapt to a wide range of surgical challenges is a hallmark of their profession.",
        descPoint2:"Discover how general surgeons are often at the forefront of emergency medical care, handling life-saving surgeries for trauma patients, accident victims, and those in critical conditions. Their quick thinking and surgical skills can make a significant difference in patient outcomes.",
        descPoint3:"Learn about the comprehensive care that general surgeons provide before and after surgery. From preoperative assessments to postoperative follow-ups, they ensure patient safety, comfort, and recovery.",
        descPoint4:"Understand the collaborative nature of general surgery, as surgeons work closely with other medical specialists to deliver comprehensive treatment plans. This interdisciplinary approach ensures a holistic approach to patient care.",
        descPoint5:"Gain insight into the meticulous nature of surgical procedures and the precision required by general surgeons. Their technical expertise, combined with years of training, enables them to achieve optimal results for their patients.",
        descPoint6:"Explore the commitment of general surgeons to ongoing learning and professional development. Advances in medical technology and techniques require them to stay updated and adapt to evolving practices.",
    },
    {
        id:13,
        ServiceImage:SerImg8,
        Header:"Nephrologist",
        Branch:"Both",
        category:"Both",
        Slugs:"/nephrologist",
        ShortDesc:"A nephrologist is a specialized medical professional focused on the diagnosis, treatment, and management of kidney-related conditions. Their expertise lies in maintaining optimal kidney function and preventing kidney-related disorders.",
        BannerDesc:"In essence, nephrologists are dedicated to safeguarding kidney health, offering a range of treatments and guidance to ensure optimal kidney function and overall quality of life.",
        ShortDesc1:"Gynecology is a medical specialty that focuses on the health of the female reproductive system and the female reproductive organs, including the uterus, ovaries, fallopian tubes, and vagina. Gynecologists are medical doctors who specialize in diagnosing and treating a wide range of conditions related to women's reproductive health, including menstrual problems, infertility, menopause, pregnancy, childbirth, and various gynecological cancers. They also provide preventive care, such as pelvic exams, pap tests, and screenings for sexually transmitted infections. Gynecologists play a critical role in ensuring the overall health and well-being of women.",
        shortTitle:"Nurturing Kidney Vitality for Lifelong Wellness",
        descHead1:"Kidney Expertise :",
        descHead2:"Disease Diagnosis :",
        descHead3:"Treatment Precision  :",
        descHead4:"Dialysis and Transplants :",
        descHead5:"Holistic Care :",
        descHead6:"Preventive Guidance :",
        descPoint1:"Nephrologists are dedicated to understanding the intricate workings of the kidneys and their impact on overall health.",
        descPoint2:"They diagnose and treat a range of kidney-related ailments, including kidney stones, chronic kidney disease, and hypertension.",
        descPoint3:" Nephrologists employ a range of therapies, medications, and interventions to address kidney conditions effectively.",
        descPoint4:"They specialize in managing dialysis treatments and evaluating candidates for kidney transplants.",
        descPoint5:" Nephrologists consider the broader health implications of kidney disorders, providing comprehensive care.",
        descPoint6:" They educate patients about kidney health and empower them to adopt lifestyles that support kidney well-being.",
    },
    {
        id:14,
        ServiceImage:SerImg5,
        Header:"Dermatologist",
        Branch:"Both",
        category:"Both",
        Slugs:"/dermatology",
        ShortDesc:"A dermatologist is a specialized medical professional who specializes in diagnosing, treating, and managing skin, hair, and nail conditions. They are experts in maintaining skin health and enhancing its appearance",
        BannerDesc:"A dermatologist is a specialized medical professional who specializes in diagnosing, treating, and managing skin, hair, and nail conditions. They are experts in maintaining skin health and enhancing its appearance",
        ShortDesc1:"In essence, dermatologists are allies in achieving healthy, vibrant skin. They provide a range of treatments, guidance, and solutions to address skin concerns and promote self-assured well-being.",
        shortTitle:"Radiant Skin, Confident You",
        descHead1:"Skin Expertise :",
        descHead2:"Diagnostic Precision :",
        descHead3:"Aesthetic Enhancement :",
        descHead4:"Hair and Nail Care :",
        descHead5:"Personalized Treatments :",
        descHead6:"Preventive Education :",
        descPoint1:"Dermatologists have in-depth knowledge of skin biology and its relation to overall health.",
        descPoint2:"They diagnose and treat various skin issues, including acne, eczema, and skin cancer, using advanced techniques.",
        descPoint3:" Dermatologists offer cosmetic procedures to enhance skin appearance, including Botox, fillers, and laser treatments.",
        descPoint4:"They address conditions related to hair loss and nail disorders, ensuring comprehensive care.",
        descPoint5:"Dermatologists tailor treatments to individual needs, considering factors like skin type and medical history.",
        descPoint6:"They educate patients on skin protection and care to prevent future issues and maintain skin health.",
    },
    {
        id:15,
        ServiceImage:SerImg9,
        Header:"Pulmonology",
        Branch:"Both",
        category:"Both",
        Slugs:"/chest-physican",
        ShortDesc:"Pulmonology, a specialized medical field, revolves around the study and management of respiratory disorders and lung health. Dedicated pulmonologists diagnose, treat, and provide essential care for a wide spectrum of respiratory conditions, aiming to improve breathing and overall well-being.",
        BannerDesc:"Pulmonology, a specialized medical field, revolves around the study and management of respiratory disorders and lung health. Dedicated pulmonologists diagnose, treat, and provide essential care for a wide spectrum of respiratory conditions, aiming to improve breathing and overall well-being.",
        ShortDesc1:"Pulmonology is a vital branch of medicine that focuses on respiratory health. Pulmonologists, equipped with extensive knowledge and expertise, diagnose and treat various lung-related issues. From common concerns like asthma and bronchitis to complex conditions such as lung infections and pulmonary fibrosis, pulmonology encompasses a range of disorders. Through innovative diagnostics and tailored treatments, pulmonologists enhance lung function, alleviate symptoms, and promote optimal respiratory wellness.",
        shortTitle:"Pulmonology: Nurturing Respiratory Wellness Through Specialized Expertise and Comprehensive Care",
        descHead1:"Respiratory Experts :",
        descHead2:"Holistic Care :",
        descHead3:"Diagnostic Precision :",
        descHead4:"Tailored Treatment :",
        descHead5:"Innovative Interventions :",
        descHead6:"Collaborative Approach :",
        descPoint1:"Pulmonologists are specialized physicians dedicated to understanding and managing lung and respiratory disorders.",
        descPoint2:"They provide comprehensive care for a diverse range of conditions, including chronic obstructive pulmonary disease (COPD), pneumonia, and lung cancer.",
        descPoint3:"Pulmonologists employ advanced diagnostic tools like spirometry and imaging to accurately identify and assess respiratory issues.",
        descPoint4:" Treatment plans are customized to each patient's condition, addressing symptoms, improving lung function, and enhancing quality of life.",
        descPoint5:"These specialists stay updated with the latest medical advancements, offering innovative treatments such as bronchoscopies and pulmonary rehabilitation.",
        descPoint6:"Pulmonologists collaborate with other medical experts to ensure well-rounded care, addressing both respiratory and overall health concerns.",
    },
    {
        id:16,
        ServiceImage:SerImg7,
        Header:"Hematology",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/hematology",
        ShortDesc:"Hematology, a specialized field within medicine, is dedicated to the study, diagnosis, and treatment of blood-related disorders. Hematologists explore the intricacies of blood composition, its components, and their impact on overall health. Their expertise contributes to improved blood conditions, enhanced well-being, and better quality of life for patients.",
        BannerDesc:"Hematology, a specialized field within medicine, is dedicated to the study, diagnosis, and treatment of blood-related disorders. Hematologists explore the intricacies of blood composition, its components, and their impact on overall health. Their expertise contributes to improved blood conditions, enhanced well-being, and better quality of life for patients.",
        ShortDesc1:"Hematology stands as a pivotal branch of medicine focused on blood disorders. Hematologists, armed with in-depth knowledge and advanced techniques, diagnose and treat an array of conditions such as anemia, bleeding disorders, and blood cancers. By utilizing cutting-edge diagnostics and tailored therapies, hematologists aim to restore blood health, manage symptoms, and foster positive outcomes for patients.",
        shortTitle:"There are many different types of gynecological conditions that a gynecologist may diagnose and treat. Some common gynecological conditions include:",
        descHead1:"Blood Disorder Specialists :",
        descHead2:"Diverse Scope of Care :",
        descHead3:"Diagnostic Expertise :",
        descHead4:"Personalized Treatment Plans :",
        descHead5:"Cutting-Edge Therapies :",
        descHead6:"Collaborative Care :",
        descPoint1:"Hematologists are medical professionals skilled in diagnosing and managing various blood-related conditions.",
        descPoint2:"They provide comprehensive treatment for a range of disorders, including leukemia, hemophilia, and thrombosis",
        descPoint3:"Hematologists employ advanced laboratory tests and genetic analyses to pinpoint underlying causes of blood disorders.",
        descPoint4:"Therapies are customized to each patient's condition, addressing specific symptoms and promoting optimal blood health.",
        descPoint5:"Hematology incorporates innovative treatments such as stem cell transplants and targeted therapies for blood cancers.",
        descPoint6:"Hematologists work closely with other medical professionals to ensure holistic management of blood-related conditions.",
    },
    {
        id:18,
        ServiceImage:SerImg4,
        Header:"Cardiology",
        Branch:"Both",
        category:"Both",
        Slugs:"/cardiologist",
        ShortDesc:"Cardiology, a specialized field of medicine, is dedicated to the study, diagnosis, and treatment of heart-related conditions. Cardiologists possess expertise in understanding the complexities of the cardiovascular system, aiming to safeguard heart health and ensure optimal functioning. Through advanced techniques and interventions, cardiology strives to enhance heart well-being and prevent cardiac ailments.",
        BannerDesc:"Cardiology, a specialized field of medicine, is dedicated to the study, diagnosis, and treatment of heart-related conditions. Cardiologists possess expertise in understanding the complexities of the cardiovascular system, aiming to safeguard heart health and ensure optimal functioning. Through advanced techniques and interventions, cardiology strives to enhance heart well-being and prevent cardiac ailments.",
        ShortDesc1:"Cardiology stands as a vital medical discipline focused on heart health. Expert cardiologists play a pivotal role in diagnosing and managing a wide spectrum of cardiovascular conditions, ranging from hypertension and coronary artery disease to heart failure and arrhythmias. Employing state-of-the-art diagnostics and innovative treatments, cardiology aims to promote heart vitality, manage symptoms, and improve patients' quality of life.",
        shortTitle:"Cardiology: Navigating the Intricacies of Heart Health, Pioneering Diagnosis, and Elevating Treatment for Cardiovascular Well-Being",
        descHead1:"Heart Health Specialists :",
        descHead2:"Holistic Care :",
        descHead3:"Advanced Diagnostics :",
        descHead4:"Tailored Interventions :",
        descHead5:"Innovative Procedures :",
        descHead6:"Collaborative Approach :",
        descPoint1:"Cardiologists are medical professionals with specialized training in diagnosing and treating various heart-related issues.",
        descPoint2:" They provide comprehensive care for a diverse range of heart conditions, from congenital heart defects to acquired disorders.",
        descPoint3:"Cardiologists employ cutting-edge technologies such as echocardiography and angiography to accurately assess heart function.",
        descPoint4:"Treatment plans are personalized to each patient's condition, focusing on symptom relief and long-term heart health.",
        descPoint5:"Cardiology encompasses innovative procedures like angioplasty, stent implantation, and cardiac ablation for arrhythmias.",
        descPoint6:"Cardiologists work in conjunction with other medical professionals to ensure comprehensive cardiovascular care.",
    },
    {
        id:19,
        ServiceImage:SerImg3,
        Header:"Opthalmology",
        Branch:"Chembur",
        Branch:"Chembur",
        category:"Ghatkopar",
        Slugs:"/opthalmology",
        ShortDesc:"Ophthalmology, a specialized field of medicine, revolves around the study, diagnosis, and treatment of eye-related conditions. Ophthalmologists are skilled professionals dedicated to preserving and enhancing vision. By utilizing cutting-edge techniques and innovative treatments, ophthalmology aims to address a wide range of ocular issues, ensuring optimal eye health and visual clarity.",
        BannerDesc:"Ophthalmology, a specialized field of medicine, revolves around the study, diagnosis, and treatment of eye-related conditions. Ophthalmologists are skilled professionals dedicated to preserving and enhancing vision. By utilizing cutting-edge techniques and innovative treatments, ophthalmology aims to address a wide range of ocular issues, ensuring optimal eye health and visual clarity.",
        ShortDesc1:"Ophthalmology is a crucial medical discipline centered on eye care. Ophthalmologists play a pivotal role in diagnosing and managing various eye conditions, ranging from refractive errors and glaucoma to retinal disorders. Leveraging state-of-the-art diagnostics and advanced surgical procedures, ophthalmology aims to safeguard eye health, improve vision, and enhance patients' quality of life.",
        shortTitle:"Ophthalmology: Illuminating the Path to Clear Vision, Pioneering Diagnosis, and Elevating Eye Health for Enhanced Visual Well-Being",
        descHead1:"Vision Care Specialists :",
        descHead2:"Comprehensive Eye Care :",
        descHead3:"Cutting-Edge Diagnostics :",
        descHead4:"Individualized Treatment Plans :",
        descHead5:"Surgical Excellence :",
        descHead6:"Collaboration and Care :",
        descPoint1:"Ophthalmologists possess specialized expertise in diagnosing and treating an array of eye-related ailments.",
        descPoint2:"They provide comprehensive care for conditions like cataracts, macular degeneration, and diabetic retinopathy.",
        descPoint3:"Ophthalmologists utilize advanced technologies such as optical coherence tomography and visual field testing for accurate diagnoses.",
        descPoint4:"Treatment approaches are tailored to each patient's unique needs, focusing on preserving or restoring visual function.",
        descPoint5:"Ophthalmology includes intricate procedures like cataract surgery, LASIK, and vitrectomy to address various eye issues.",
        descPoint6:"Ophthalmologists collaborate with other medical experts to ensure comprehensive eye health and overall well-being.",
    },
    {
        id:20,
        ServiceImage:SerImg2,
        Header:"Gastroenterologist",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/gastroenterologist",
        ShortDesc:"Gastroenterology, a specialized medical field, is dedicated to the study, diagnosis, and treatment of disorders affecting the digestive system. Gastroenterologists are highly skilled physicians who focus on the health and well-being of the gastrointestinal tract. Through advanced procedures and interventions, gastroenterology aims to alleviate digestive ailments, improve overall gastrointestinal function, and enhance patients' quality of life.",
        BannerDesc:"Gastroenterology, a specialized medical field, is dedicated to the study, diagnosis, and treatment of disorders affecting the digestive system. Gastroenterologists are highly skilled physicians who focus on the health and well-being of the gastrointestinal tract. Through advanced procedures and interventions, gastroenterology aims to alleviate digestive ailments, improve overall gastrointestinal function, and enhance patients' quality of life.",
        ShortDesc1:"Gastroenterology stands at the forefront of digestive health care. Gastroenterologists play a crucial role in diagnosing and managing various gastrointestinal conditions, ranging from irritable bowel syndrome and gastroesophageal reflux disease to inflammatory bowel disease. Leveraging state-of-the-art diagnostics and cutting-edge endoscopic procedures, gastroenterology strives to restore digestive harmony, manage symptoms, and promote wellness.",
        shortTitle:"Gastroenterology: Navigating the Pathways of Digestive Health, Pioneering Diagnosis, and Elevating Gastrointestinal Wellness for Enhanced Quality of Life",
        descHead1:"Digestive System Specialists :",
        descHead2:"Comprehensive Digestive Care :",
        descHead3:"Advanced Diagnostics :",
        descHead4:"Tailored Treatment Plans :",
        descHead5:"Innovative Procedures :",
        descHead6:"Collaborative Care :",
        descPoint1:"Gastroenterologists are medical experts specializing in the diagnosis and treatment of conditions affecting the digestive tract.",
        descPoint2:"They address a wide range of gastrointestinal disorders, including peptic ulcers, Crohn's disease, and liver disorders.",
        descPoint3:"Gastroenterologists utilize advanced imaging techniques and tests like endoscopy and colonoscopy to accurately diagnose conditions.",
        descPoint4:"Treatment approaches are tailored to each patient's specific condition, focusing on symptom relief and digestive health optimization.",
        descPoint5:"Gastroenterology encompasses innovative procedures like endoscopic retrograde cholangiopancreatography (ERCP) and capsule endoscopy.",
        descPoint6:" Gastroenterologists collaborate with other medical specialists to ensure comprehensive care for patients' gastrointestinal well-being.",
    },
    {
        id:21,
        ServiceImage:SerImg1,
        Header:"Diabetologist",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/gastroenterologist",
        ShortDesc:"Diabetology is a specialized medical field focused on the study, management, and treatment of diabetes. Diabetologists are skilled medical professionals dedicated to understanding the complexities of diabetes and providing comprehensive care to individuals with this chronic condition. Through a combination of medical interventions, lifestyle modifications, and patient education, diabetology aims to empower individuals to effectively manage their diabetes and lead healthier lives.",
        BannerDesc:"Diabetology is a specialized medical field focused on the study, management, and treatment of diabetes. Diabetologists are skilled medical professionals dedicated to understanding the complexities of diabetes and providing comprehensive care to individuals with this chronic condition. Through a combination of medical interventions, lifestyle modifications, and patient education, diabetology aims to empower individuals to effectively manage their diabetes and lead healthier lives.",
        ShortDesc1:"Diabetology is a vital discipline that addresses the challenges posed by diabetes, a chronic condition characterized by abnormal blood sugar levels. Diabetologists play a critical role in diagnosing and managing various types of diabetes, including type 1, type 2, and gestational diabetes. By focusing on personalized treatment plans, monitoring blood sugar levels, and promoting lifestyle changes, diabetology aims to enhance patients' well-being and minimize diabetes-related complications.",
        shortTitle:"Diabetology: Navigating the Complexities of Diabetes, Empowering Individuals to Manage Their Health and Well-being for a Fulfilling Life",
        descHead1:"Expert Diabetes Management :",
        descHead2:"Personalized Treatment Plans :",
        descHead3:"Blood Sugar Monitoring :",
        descHead4:"Lifestyle Modifications :",
        descHead5:"Preventing Complications :",
        descHead6:"Patient Education :",
        descPoint1:"Diabetologists are experts in managing all aspects of diabetes, from diagnosis to long-term care.",
        descPoint2:"Treatment plans are tailored to each patient's unique needs, considering factors like age, lifestyle, and diabetes type.",
        descPoint3:"Diabetologists emphasize regular blood sugar monitoring to ensure optimal diabetes management and prevent complications.",
        descPoint4:"Patients are educated about the importance of diet, exercise, and weight management in diabetes control.",
        descPoint5:"Diabetologists focus on preventing complications like diabetic neuropathy, retinopathy, and cardiovascular issues.",
        descPoint6:"Empowering patients with knowledge about their condition equips them to make informed decisions about their health.",
    },

]

export default ServiceData;