import Service1 from "../img/blog1.jpg";
import Blogs1 from "../img/blogs/blog1.jpg";
import Blogs2 from "../img/blogs/blog2.jpg";
import Blogs3 from "../img/blogs/blog6.jpg";
import Blogs4 from "../img/blogs/blog4.jpg";
import Blogs5 from "../img/blogs/blog5.jpg";
import Blogs6 from "../img/blogs/blog6.jpg";
import Blogs7 from "../img/blogs/blog7.jpg";
import Blogs8 from "../img/blogs/blog8.jpg";

const BlogData = [
    {
        id:1,
        BlogImg:Blogs1,
        BlogHead:"Understanding Typhoid: Causes and Transmission",
        BlogDesc:"Typhoid is primarily characterized by typhoid fever, which occurs due to the consumption of contaminated food containing salmonella bacteria. It is more common in unsanitary places or in areas where hygiene standards for food preparation are not maintained. The bacteria can be transmitted through the stool and urine of infected individuals and can spread if proper handwashing practices after using the bathroom are not followed. Additionally, the bacteria can spread through contact with contaminated surfaces that infected individuals may have previously touched.",
    },
    {
        id:2,
        BlogImg:Blogs2,
        BlogHead:"Consume Winter or Seasonable Vegetables",
        BlogDesc:"It is always recommended to eat seasonal vegetables. Winter brings with it a tremendous variety of vegetables that are great for your gut health. It consists of root vegetables like carrots, potatoes, turnips, sweet potatoes, etc.",
    },
    {
        id:3,
        BlogImg:Blogs3,
        BlogHead:"complications of Laparoscopic Surgery",
        BlogDesc:"Laparoscopy surgery is a ‘minimally invasive surgery’ which incurs small incisions, usually not more than ½ inch. It uses a long and slender device called a laparoscope which is inserted into the abdomen through a small incision. A camera attached to it allows the obstetrician–gynaecologist (ob-gyn) to view the abdominal and pelvic organs on an electronic screen. If a problem needs to be fixed, other instruments can also be used. These instruments are usually inserted through additional small incisions into the abdomen. It is essentially a painless, scarless surgery.",
    },
    {
        id:4,
        BlogImg:Blogs5,
        BlogHead:"Minimal Invasive Cardiac Bypass Surgery",
        BlogDesc:"Cardiac bypass surgery is one of the most invasive and major surgical procedures. Following an open cardiac bypass surgery, the time taken for wound healing and patient getting back to work ranges between 6-8 weeks. A minimal invasive approach to this major surgery has opened the doors for faster recovery and smaller wound without the need to cut open a bone.",
    },
    {
        id:5,
        BlogImg:Blogs6,
        BlogHead:"Covid -19 Vaccines",
        BlogDesc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sit fuga harum autem expedita ipsum, facere beatae obcaecati quisquam possimus?When candidate vaccines make it to human clinical trials, they first go through phase 1 trials primarily to test the vaccine’s safety, determine dosages and identify any potential side effects in a small number of people. Phase 2 trials further explore safety and start to investigate efficacy on larger groups. Phase 3 trials, which few vaccines ever make it to, are much larger, involving thousands or tens of thousands of people, to confirm and assess the effectiveness of the vaccine and test whether there are any rare side effects that only show up in large groups.",
    },
    {
        id:6,
        BlogImg:Blogs7,
        BlogHead:"Eye Flu Treatment",
        BlogDesc:"Do not self-medicate or take over counter eye drops. Consult an eye care professional for proper diagnosis and treatment. She will examine your eyes to see what type of infection it is, which part of your eyes are involved, and prescribe medication accordingly. The eye drops are anti-inflammatory and lubricating eye drops to soothe n your eyes and for faster recovery.",
    },
    {
        id:7,
        BlogImg:Blogs8,
        BlogHead:"Monsoon Illnesses",
        BlogDesc:"The monsoon season brings relief from scorching heat and rejuvenates the environment with the arrival of rainfall. However, this transformation is accompanied by a gradual spike in diseases, posing significant challenges for healthcare systems.The positive aspects of the monsoon, such as replenished water sources, enhanced agricultural productivity, and a refreshing change in the climate, are overshadowed by the increased incidence of monsoon-related illnesses. ",
    },
    {
        id:8,
        BlogImg:Blogs4,
        BlogHead:"Wellness in the Monsoon",
        BlogDesc:"Rain may increase indoor allergens like dust mites, moulds, and cockroaches. They can populate more in humidity, which may lead to allergies and increase the risk of an asthma attack. Rain and lightning can hit pollen and break it into smaller-than-normal pieces. Such outdoor allergens of higher circulating pollens during rain can induce respiratory issues and increase and trigger an asthmatic attack.",
    },
]

export default BlogData;