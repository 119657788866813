import Doctor1 from "../img/doctors/prashant-melmane.jpeg";
import Doctor2 from "../img/doctors/c-m-ashtekar.jpeg";
import Doctor3 from "../img/doctors/swaroop-hegde.jpeg";
import Doctor4 from "../img/doctors/amol-pawar.jpeg";
import Doctor5 from "../img/doctors/suchitra-nadkarni.jpeg";
import Doctor6 from "../img/doctors/man.webp";
import Doctor7 from "../img/doctors/shilpa-shah.jpeg";
import Doctor8 from "../img/doctors/jyoti-kulkarni.jpeg";
import Doctor9 from "../img/doctors/bharthi-rajesh.jpeg";
import Doctor10 from "../img/doctors/rajesh-subramaniam.jpeg";
import Doctor11 from "../img/doctors/dhiren-kalawadia.jpeg";
import Doctor12 from "../img/doctors/jayant-nadkarni.jpeg";
import Doctor13 from "../img/doctors/dhvanit-tolia.jpeg";
import Doctor14 from "../img/doctors/man.webp";
import Doctor15 from "../img/doctors/man.webp";
import Doctor16 from "../img/doctors/man.webp";
import Doctor17 from "../img/doctors/man.webp";
import Doctor18 from "../img/doctors/man.webp";
import Doctor19 from "../img/doctors/man.webp";
import Doctor20 from "../img/doctors/man.webp";
import Doctor21 from "../img/doctors/man.webp";
import Doctor22 from "../img/doctors/man.webp";
import Doctor23 from "../img/doctors/man.webp";
import Doctor24 from "../img/doctors/man.webp";
import Doctor25 from "../img/doctors/man.webp";
import Doctor26 from "../img/doctors/man.webp";
import Doctor27 from "../img/doctors/man.webp";
import Doctor28 from "../img/doctors/man.webp";
import Doctor29 from "../img/doctors/nikhil-shetty.jpg";
import Doctor30 from "../img/doctors/man.webp";
import Doctor31 from "../img/doctors/man.webp";
import Doctor32 from "../img/doctors/women.webp";
import Doctor33 from "../img/doctors/man.webp";
import Doctor34 from "../img/doctors/man.webp";
import Doctor35 from "../img/doctors/man.webp";
import Doctor36 from "../img/doctors/man.webp";
import Doctor37 from "../img/doctors/women.webp";
import Doctor38 from "../img/doctors/man.webp";
import Doctor39 from "../img/doctors/man.webp";
import Doctor40 from "../img/doctors/women.webp";
import Doctor41 from "../img/doctors/man.webp";
import Doctor42 from "../img/doctors/man.webp";
import Doctor43 from "../img/doctors/amol-manekar.jpg";
import Doctor44 from "../img/doctors/pinkesh-chandra.jpeg";
import Doctor45 from "../img/doctors/rajesh-patel.jpeg";
import Doctor46 from "../img/doctors/ram-prajapati.jpg";
import Doctor47 from "../img/doctors/yessukrishna-shetty.jpeg";
import Doctor48 from "../img/doctors/man.webp";
import Doctor49 from "../img/doctors/tanvi-bhat.jpg";
import Doctor50 from "../img/doctors/man.webp";
import Doctor51 from "../img/doctors/man.webp";
import Doctor52 from "../img/doctors/man.webp";
import Doctor53 from "../img/doctors/women.webp";
import Doctor54 from "../img/doctors/neha-jain.jpg";
import Doctor55 from "../img/doctors/anuya-manerkar.jpeg";
import Doctor56 from "../img/doctors/salman-sayeed.jpg";
import Doctor57 from "../img/doctors/dhanish-mehendiratta.jpeg";
import Doctor58 from "../img/doctors/priyanka-tate.jpeg";
import Doctor59 from "../img/doctors/ritu-agarwal.jpeg";
import Doctor60 from "../img/doctors/reeta-mehta.jpeg";
import NikhilShetty from "../img/doctors/Nikhil Shetty.webp";
import SiddharthChaudhary from "../img/doctors/Siddharth chaudhary.jpg";
import Doctor61 from "../img/doctors/man.webp";
import Doctor62 from "../img/doctors/women.webp";
import Doctor63 from "../img/doctors/man.webp";
import Doctor64 from "../img/doctors/man.webp";
import Doctor65 from "../img/doctors/man.webp";

const DoctorsData = [
  {
    id: 1,
    DoctorImage: Doctor1,
    DocName: "DR. Prashant Melmane",
    DocQualify: "MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Prashant P. Melmane, a leading general physician in Mumbai, specializes in various fields such as neurology, dermatology, gastroenterology, diabetology, homeopathy, endocrinology, and more, drawing patients citywide.",
    DocBranch: "Chembur",
    category: "Both",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 2,
    DoctorImage: Doctor2,
    DocName: "DR. C.M.Ashtekar",
    DocQualify: "MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Chandrashekhar Ashtekar is a Doctor in Chembur, Mumbai and has an experience of 22 years in this field. Dr. Chandrashekhar Ashtekar specialised in diabetes, cardiology, intensive care",
    DocBranch: "Chembur",
    category: "Both",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 3,
    DoctorImage: Doctor3,
    DocName: "DR. Swaroop Hegde",
    DocQualify: "MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Swaroop Hegde treating medical ailments like diabetes, hypertension and critical care.",
    DocBranch: "Chembur",
    category: "Both",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 4,
    DoctorImage: Doctor4,
    DocName: "DR. Amol Parwar",
    DocQualify: "MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Amol Ashok Pawar is a Consultant Cardio-Diabetes Physician based in Mumbai. He has a special interest in the areas of diabetology, cardiology, and metabolic disorders",
    DocBranch: "Chembur",
    category: "Both",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 5,
    DoctorImage: Doctor5,
    DocName: "DR. Suchitra Nadkarni",
    DocQualify: "OBS & GYNEAC",
    DocDepart: "OBS & GYNEAC",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr Suchitra Nadkarni is specialized in Gynecology who has an experience of 28 years in this field",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 6,
    DoctorImage: Doctor6,
    DocName: "Dr. Anil Venkatachilam",
    DocQualify: "MBBS MD (PULMONOLGY )",
    DocDepart: "MBBS, MD - Medicine, DM - Neurology",
    DocType: ["Neuro Surgery"],
    DocShort:
      "A specialist in Neurology with a rich experience of 25 year.He has handled numerous complex medical cases and is known for attention to detail, accurate diagnosis and treating patients with empathy.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 7,
    DoctorImage: Doctor7,
    DocName: "DR. Shilpa Uppal Shah",
    DocQualify: "OBS & GYNEAC",
    DocDepart: "OBS & GYNEAC",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. Shilpa Uppal (Shah) is a highly experienced Infertility Specialist, Obstetrician, and Gynecologist, practicing at Kolekar Hospital with expertise in high-risk pregnancy, infertility, gynecological, and laparoscopic surgeries.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 8,
    DoctorImage: Doctor8,
    DocName: "DR. Jyoti Kulkarni",
    DocQualify: "OBS & GYNEAC",
    DocDepart: "OBS & GYNEAC",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. Jyoti Kulkarni, a 20-year-experienced Gynecologist, holds an MS in Obstetrics & Gynaecology from the University of Pune (2003) and an MBBS from the same university (1998), specializing in high-risk pregnancy care.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 9,
    DoctorImage: Doctor9,
    DocName: "DR. Bharthi Rajesh",
    DocQualify: "OBS & GYNEAC",
    DocDepart: "OBS & GYNEAC",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. Bharti, a trusted Gynaecologist in Chembur East, Mumbai, offers comprehensive gynaecological treatments, periodical check-ups, stress management guidance, and specialized care for diseases in pregnancy.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 10,
    DoctorImage: Doctor10,
    DocName: "DR. Rajesh Subramanium",
    DocQualify: "MBBS MD",
    DocDepart: "PEADIATRIC",
    DocType: ["Peadiatric"],
    DocShort:
      "Dr. Rajesh Subramaniam, a renowned Pediatrician in Chembur, specializes in Adolescent Medicine, Child Specialist services, Childhood Infections Treatment, and comprehensive Paediatric Healthcare since 2001.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 11,
    DoctorImage: Doctor11,
    DocName: "DR. Dhiren Kalawadia",
    DocQualify: "MD (PEAD)",
    DocDepart: "PEADIATRIC",
    DocType: ["Peadiatric"],
    DocShort: "32 Yrs in General Pediatrics",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 12,
    DoctorImage: Doctor12,
    DocName: "DR. Jayant Nadkarni",
    DocQualify: "MD (PEAD)",
    DocDepart: "PEADIATRIC",
    DocType: ["Peadiatric"],
    DocShort:
      "Dr. Jayant R Nadkarni is a trusted Pediatrician in Mumbai has been practicing more than 35 years",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 13,
    DoctorImage: Doctor13,
    DocName: "DR. Dhvanit Tolia",
    DocQualify: "MS (SURGERY)",
    DocDepart: "SURGERY",
    DocType: ["General Surgery"],
    DocShort:
      "Dr. Dhvanit Haresh Tolia, a General Surgeon in Ghatkopar, Mumbai, brings 8 years of experience in Colorectal Surgery, Trauma Surgery, Minor Surgery, Piles Surgery, and Endoscopic Surgery, among other services.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 14,
    DoctorImage: Doctor14,
    DocName: "DR. Milind More",
    DocQualify: "MS (SURGERY)",
    DocDepart: "SURGERY",
    DocType: ["General Surgery"],
    DocShort:
      "Dr. Milind More is a Laparoscopic Surgeon in Chembur East, Mumbai and has an experience of 24 years in this field. Dr. Milind More specializes as Breast Surgeon, Gastrointestinal Surgeon, Colorectal Surgeon, Laparoscopic Surgeon",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 15,
    DoctorImage: Doctor15,
    DocName: "DR. G.M. Bhatia",
    DocQualify: "MS (ORTHO)",
    DocDepart: "ORTHO",
    DocType: ["Orthopedic Surgery"],
    DocShort:
      "Dr. G.M. Bhatia, a highly experienced Orthopedic surgeon with 44 years of practice, operates at Disha Nursing Home and Polyclinic in Chembur, Mumbai, having completed MBBS and MS in Orthopaedics from Delhi University in 1975 and 1978, respectively.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 16,
    DoctorImage: Doctor16,
    DocName: "DR. Santosh Jagtap",
    DocQualify: "MBBS MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Santosh Jagtap is a Doctor in Chembur East, Mumbai and has an experience of 22 years in this field. specialised in diabetes, infectious disease and hypertension",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 17,
    DoctorImage: Doctor17,
    DocName: "DR. Rahul Nikumbhe",
    DocQualify: "MBBS MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Rahul Nikumbhe, an MBBS, MD MEDICINE, is a consultant physician practicing in Chembur at present. He did his medical schooling from Seth G S Medical college and KEM Hospital Mumbai. He did his post graduation from Government Medical College and Hospital, Guwahati, Assam. ",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:18,
    DoctorImage: Doctor18,
    DocName: "DR. Shradha Shivgan",
    DocQualify: "MBBS MD ",
    DocDepart: "OBS & GYNE",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. Shradha Shivgan is a Obstetrician in Chembur East, Mumbai and has an experience of 14 years in this field.She completed DGO from King Edward Memorial Hospital and Seth Gordhandas Sunderdas Medical College in 2014,MBBS from Maharashtra Universtity of Health Sciences.",
      DocBranch: "Chembur",
      category: "Chembur",
      StartTime:"08:00",
      EndTime:"10:00",
  },
  {
    id:19,
    DoctorImage: Doctor19,
    DocName: "DR. Priyanka Kharat",
    DocQualify: "MBBS MD ",
    DocDepart: "OBS & GYNE",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. G.M. Bhatia, a highly experienced Orthopedic surgeon with 44 years oDr. Jayant R Nadkarni is a trusted Pediatrician in Mumbai has been practicing more than 35 years",
      DocBranch: "Chembur",
      category: "Chembur",
      StartTime:"08:00",
      EndTime:"10:00",
  },
  {
    id:20,
    DoctorImage: Doctor20,
    DocName: "DR. Uma Venkateswaran",
    DocQualify: "MBBS MD ",
    DocDepart: "PEADIATRIC",
    DocType: ["Peadiatric"],
    DocShort:
      "Dr. Uma Venkateswaran is a Pediatrician and Neonatologist in Chembur, Mumbai and has an experience of 32 years in these fields",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:21,
    DoctorImage: Doctor21,
    DocName: "DR. Manjusha Sailukar",
    DocQualify: "MBBS MS MCH",
    DocDepart: "PEADIATRIC",
    DocType: ["Peadiatric Surgery"],
    DocShort:
      "Dr Manjusha Sailukar is working as an Associate professor & HOD, Department of Paediatric Surgery.She has done her graduation from Nagpur University.Dr Manjusha has 14 yrs experience in the field of paediatric & Neonatal surgery",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 22,
    DoctorImage: Doctor22,
    DocName: "DR. Aashik Shetty",
    DocQualify: "MBBS MS ",
    DocDepart: "SURGERY",
    DocType: ["General Surgery"],
    DocShort:
      "MBBS, MS - General Surgery, FMAS, DMAS 13 Years of Experience. Second Opinion on Surgical Intervention Acute Abdomen  Acute Injuries.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:23,
    DoctorImage: Doctor23,
    DocName: "DR. Rajeev Thacker",
    DocQualify: "MBBS MS (SURGERY)",
    DocDepart: "SURGERY",
    DocType: ["General Surgery"],
    DocShort:
      "Dr. Rajeev Thacker is a General Surgery in Chembur, Mumbai and has an experience of 26 years in this field. Dr. Rajiv Thakker practices at Thacker Polyclinic in Chembur, Mumbai. He completed MBBS from LTMC, Mumbai in 1994 and MS - General Surgery from LTMC, Mumbai in 1997",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 24,
    DoctorImage: Doctor24,
    DocName: "DR. Parag Gawande",
    DocQualify: "MBBS MS MCH (UROLOGY )",
    DocDepart: "UROLOGY",
    DocType: ["Urology", "Surgical Oncology","Neonatology"],
    DocShort:
      "Some of the services provided by the doctor are: Emergency Fracture & Trauma Care, Knee & Hip Replacement Surgery, Arthroscopic Knee surgery, Back Pain Treatment, Platelet Rich Plasma Therapy, Paediatric Trauma etc",
    DocBranch: "Ghatkopar",
    category: "Both",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:25,
    DoctorImage: Doctor25,
    DocName: "DR. Ali Abbas Sayyed",
    DocQualify: "MBBS MS MCH (UROLOGY )",
    DocDepart: "UROLOGY",
    DocType: ["Urology"],
    DocShort:
      "ears of practice, operates at Disha Nursing Home and Polyclinic in Chembur, Mumbai, having completed MBBS and MS in Orthopaedics from Delhi University in 1975 and 1978, respectively.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 26,
    DoctorImage: Doctor26,
    DocName: "DR. Shyam Mohan",
    DocQualify: "MBBS MS MCH (UROLOGY )",
    DocDepart: "UROLOGY",
    DocType: ["Urology"],
    DocShort:
      "Dr. Shyam Mohan practices Urologist in Chembur, Mumbai and has 10 years of experience in this field. He has completed his M.Ch - Urology and MBBS. Dr. Shyam Mohan has gained the confidence of patients and is a popular Urologist expert in Mumbai who performs treatment and procedures for various health issues related to Urologis",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 27,
    DoctorImage: Doctor27,
    DocName: "DR. Aseem Thamba",
    DocQualify: "MBBS MD (NEPHROLOGY)",
    DocDepart: "NEPHROLOGY",
    DocType: ["Nephrologist"],
    DocShort:
      "Dr. Thamba Aseem is a Nephrologist/Renal Specialist in Peddar Road, Mumbai and has an experience of 27 years in this field",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 28,
    DoctorImage: Doctor28,
    DocName: "DR. Sunil Ghate",
    DocQualify: "MBBS MD (DERMAT)",
    DocDepart: "DERMAT",
    DocType: ["Dermatologist"],
    DocShort:
      "Dr Sunil Ghate is senior consultant dermatologist practising for over 17 years. The doctor has vast experience in clinical dermatology, leprosy, HIV.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:29,
    DoctorImage: Doctor29,
    DocName: "Dr. Nikhil S. Shetty",
    DocQualify: "MBBS MS",
    DocDepart: "ORTHO",
    DocType: ["Orthopedic Surgery"],
    DocShort:
      "Dr. Nikhil Shetty is a Consultant Orthopedist, Trauma, & Joint Replacement surgeon, in Ghatkopar West, Mumbai and has an experience of 14 years in these field.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:30,
    DoctorImage: Doctor30,
    DocName: "Dr. Siddharth Chaudhary",
    DocQualify: "MBBS MS",
    DocDepart: "ORTHO",
    DocType: ["Orthopedic Surgery"],
    DocShort:
      "Dr. Siddharth Chowdary is a Orthopedic surgeon,has an experience of 15 years in this field.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 31,
    DoctorImage: Doctor31,
    DocName: "DR. Arvind Kate",
    DocQualify: "MBBS MD PULMONOLGY ",
    DocDepart: "PULMONOLGY",
    DocType: ["Pulmonology"],
    DocShort:
      "16 Years Experience Overall MBBS, FCCP - Pulmonary Medicine, MD - TB & Chest",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 32,
    DoctorImage: Doctor32,
    DocName: "DR. Kavita Mody",
    DocQualify: "MBBS MD (PULMONOLGY )",
    DocDepart: "PULMONOLGY",
    DocType: ["Chest Physican"],
    DocShort:
      "Dr. Kavita Mody is a Pulmonologist in Vikhroli, Mumbai and has an experience of 17 years in this field. Dr. Kavita Mody practices at Godrej Memorial Hospital in Vikhroli, Mumbai and Chembur Polyclinic in Chembur East, Mumbai.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 33,
    DoctorImage: Doctor33,
    DocName: "DR. Rohit Chintamani",
    DocQualify: "MBBS MD (NEURO)",
    DocDepart: "NEUROLOGY",
    DocType: ["Neuro Surgery"],
    DocShort:
      "A specialist in Neurology with a rich experience of 25 year.He has handled numerous complex medical cases and is known for attention to detail, accurate diagnosis and treating patients with empathy.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 34,
    DoctorImage: Doctor34,
    DocName: "DR. B. K. Acharya",
    DocQualify: "MBBS MS",
    DocDepart: "NEUROLOGY",
    DocType: ["Neuro Surgery"],
    DocShort:
      "Dr. B. K. Acharya is a Neurosurgeon and Neurologist in Sanpada, Navi Mumbai and has an experience of 30 years in these fields. He completed MBBS from University of Lucknow in 1986,MS - General Surgery from University of Lucknow in 1990 and MCh - Neuro Surgery from University of Lucknow in 1993.",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 35,
    DoctorImage: Doctor35,
    DocName: "DR. Deepkumar Mahajan",
    DocQualify: "MBBS MD",
    DocDepart: "HEAMATOLOGY",
    DocType: ["Hematology"],
    DocShort:
      "Dr. Deepkumar Mahajan is one of the best Hematologists in chembur ",
    DocBranch: "Chembur",
    category: "Chembur",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 36,
    DoctorImage: Doctor36,
    DocName: "DR. Kaustubh Sarwade",
    DocQualify: "MBBS MS (HEAD AND NECK )",
    DocDepart: "ONCOLOGY",
    DocType: ["Oncology"],
    DocShort:
      "MS (Oto-Rhino-Laryngology), Fellow Head & Neck Cancer Surgery 9 years of Experience",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 37,
    DoctorImage: Doctor37,
    DocName: "DR. Manisha Banage",
    DocQualify: "BPTH",
    DocDepart: "ONCOLOGY",
    DocType: ["Oncology"],
    DocShort: "Physiotherapist available round the clock ",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 38,
    DoctorImage: Doctor38,
    DocName: "DR. Anil Potdar",
    DocQualify: "MBBS MD (CARDIOLOGY )",
    DocDepart: "CARDIOLOGY",
    DocType: ["Cardiology"],
    DocShort:
      "Dr. Anil Potdar is a Cardiologist in Vikhroli, Mumbai and has an experience of 37 years in this field. Dr. Anil Potdar practices at Godrej Memorial Hospital in Vikhroli, Mumbai and Dr. Anil Potdar in Chembur, Mumbai.",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 39,
    DoctorImage: Doctor39,
    DocName: "DR. Brijesh Agrwal",
    DocQualify: "MBBS DM (CARDIO)",
    DocDepart: "CARDIOLOGY ",
    DocType: ["Cardiology"],
    DocShort:
      "an interventional cardiologist with 13 years of experience in cardiology.",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:40,
    DoctorImage: Doctor40,
    DocName: "DR. Swetal Mahajan",
    DocQualify: "MBBS MS(ENT)",
    DocDepart: "ENT",
    DocType: ["ENT (Microscopic) Surgery"],
    DocShort: "ENT SPECIALIST ",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 41,
    DoctorImage: Doctor41,
    DocName: "DR. Shilendra Singh",
    DocQualify: "MBBS MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "DR. Shilendra Singh  specialised in diabetes, infectious disease and Rheumatology.",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id: 42,
    DoctorImage: Doctor42,
    DocName: "DR. Anil Khetani",
    DocQualify: "MBBS MD (MED)",
    DocDepart: "MEDICINE",
    DocType: ["Medicine"],
    DocShort:
      "Dr. Anil Khetani, MBBS, MD (Medicine), is a highly qualified medical professional specializing in the field of Medicine. With extensive qualifications and experience, he is a trusted healthcare provider dedicated to delivering exceptional medical care to his patients.",
    DocBranch: "Chembur",
    category: "Chembur",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:43,
    DoctorImage: Doctor43,
    DocName: "Dr. Amol Manerkar",
    DocQualify: "Qualifaction",
    DocDepart: "ORTHO",
    DocType: ["Medicine","Diabetologist","Cardiology"],
    DocShort:
      "Dr. Amol Manerkar is a highly skilled Orthopedic surgeon in Ghatkopar. With extensive expertise, he provides exceptional care, improving musculoskeletal health with compassion.",
    DocBranch: "Ghatkopar",
    category: "Both",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:44,
    DoctorImage: Doctor44,
    DocName: "Dr. Pinkesh Chandra",
    DocQualify: "Qualifaction",
    DocDepart: "DIABETOLOGIST",
    DocType: ["Diabetologist","Medicine"],
    DocShort:
      "Dr. Pinkesh Chandra is a dedicated Diabetologist based in Ghatkopar. With extensive qualifications, he offers specialized care and expertise in managing diabetes.",
    DocShort:
      "Dr. Pinkesh Chandra is a dedicated Diabetologist based in Ghatkopar. With extensive qualifications, he offers specialized care and expertise in managing diabetes. Patients receive compassionate treatment focused on enhancing their overall well-being.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:45,
    DoctorImage: Doctor45,
    DocName: "Dr. Rajesh Patel",
    DocQualify: "Qualifaction",
    DocDepart: "PEDIATRICIAN",
    DocType: ["Peadiatric"],
    DocShort:
      "Dr. Rajesh Patel is a proficient Pediatrician practicing in Ghatkopar. With specialized qualifications, he provides expert care and attention to children's health. Parents trust his compassionate approach in ensuring the well-being and healthy development.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:46,
    DoctorImage: Doctor46,
    DocName: "Dr. Ram Prajapati",
    DocQualify: "MBBS, MS - General Surgery",
    DocDepart: "GASTRO, COLORECTAL",
    DocType: ["Gastroenterologist","General Surgery"],
    DocShort:
      "Dr. Ram Prajapati, a General Surgeon at Disha Hospital in Chembur East & Ghatkopar West, Mumbai, specializes in MBBS, MS - General Surgery, and DNB, and is affiliated with prestigious surgical associations including ASI, HSI, IAGES, SGEI.",
    DocBranch: "Ghatkopar",
    category: "Both",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:47,
    DoctorImage: Doctor47,
    DocName: "Dr. Yessukrishna Shetty",
    DocQualify: "Qualifaction",
    DocDepart: "ENT Surgeon",
    DocType: ["ENT (Microscopic) Surgery"],
    DocShort:
      "Dr. Yessukrishna Shetty is a qualified ENT Surgeon in Ghatkopar, providing expert care for ear, nose, and throat conditions. Patients trust his skills and dedication for improved ENT health and well-being.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:48,
    DoctorImage: Doctor48,
    DocName: "Dr. Hardik Punamiya(Jain)",
    DocQualify: "MBBS, MS Ophthalmology",
    DocDepart: "OPTHALMOLOGY",
    DocType: ["Opthalmology"],
    DocShort:
      "Dr. Hardik Punamiya is a skilled Ophthalmologist. With MBBS and MS Ophthalmology qualifications, he ensures optimal eye health and vision with expert care and compassion.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:49,
    DoctorImage: Doctor49,
    DocName: "Dr. Tanvi Bhatt",
    DocQualify: "M.D. Chest Medicine T.B",
    DocDepart: "PULMONOLOGY",
    DocType: ["Pulmonology"],
    DocShort:
      "Dr. Tanvi Bhatt, a renowned Pulmonologist in Ghatkopar, specializes in respiratory care with an M.D. in Chest Medicine (T.B.). Patients trust her expertise and compassionate approach for improved lung health and overall well-being.",
    DocBranch: "Ghatkopar",
    category: "both",
   StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:50,
    DoctorImage: NikhilShetty,
    DocName: "Dr. Nikhil s. Shetty",
    DocQualify: "M.S Ortho",
    DocDepart: "ORTHOPAEDIC",
    DocType: ["Orthopadeic","Orthopedic Surgery"],
    DocShort:"Dr. Nikhil Shetty is a Consultant Orthopedist, Trauma, & Joint Replacement surgeon, in Ghatkopar West, Mumbai and has an experience of 5 years in these fields. Dr. Nikhil Shetty practices at Disha Hospital in Ghatkopar West, Mumbai. He completed MBBS from SDM Medical College in 2010 and MS - Orthopaedics from Yenepoya Medical College in 2013. He is additionally awarded with Diploma in Orthopaedics by the Société Internationale de Chirurgie Orthopédique et de Traumatologie, (SICOT), Belgium. He is an active member of SICOT, Indian Society of Hip & Knee Surgeons (ISHKS) and Bombay Orthopaedic Society.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"07:00 Am",
    EndTime:"09:00 Pm",
  },
  {
    id:51,
    DoctorImage: SiddharthChaudhary,
    DocName: "Dr. Siddharth Chaudhary",
    DocQualify: "M.S Ortho",
    DocDepart: "ORTHOPAEDIC",
    DocType: ["Orthopadeic","Orthopedic Surgery"],
    DocShort:
      "Dr. Siddhartha Chaudhari is one of the best Orthopedic and reconstructive surgeon in Mumbai, India. He has immense experience and is highly respected among his peers. He specialises in Joint Replacement, Spine Surgeon, Knee Replacement, Hip Replacement, Neuromuscular Disorders, Arthroscopy, Pain Specialist, Trauma, and Fracture Care and all ortho care. He has been performing joint replacement surgery since 2010 with more than 5000 joint replacements to his credit. He is associated with various prestigious hospitals and clinics.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
   StartTime:"09:00 Am",
    EndTime:"10:00 Pm",
  },
  // {
  //   id:52,
  //   DoctorImage: Doctor52,
  //   DocName: "Dr. Balaji Satpute",
  //   DocQualify: "Qualifaction",
  //   DocDepart: "ORTHO",
  //   DocType: ["Orthopedic Surgery"],
  //   DocShort:
  //     "Dr. Balaji Satpute, a proficient Orthopedic specialist in Ghatkopar, offers exceptional care and treatment. Patients trust his expertise and compassionate approach to improve musculoskeletal health and manage orthopedic conditions.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  // {
  //   id:53,
  //   DoctorImage: Doctor53,
  //   DocName: "Dr. Padma Khade",
  //   DocQualify: "D.F.P, M.D. DGO",
  //   DocDepart: "OBS & GYNEAC",
  //   DocType: ["OBS & GYNEAC"],
  //   DocShort:
  //     "Dr. Padma Khade, a highly qualified Obstetrician and Gynecologist in Ghatkopar, provides expert care for women's health. Patients trust her dedicated approach in managing obstetric and gynecological conditions, ensuring their well-being.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  // {
  //   id:54,
  //   DoctorImage: Doctor54,
  //   DocName: "Dr. Neha Jain",
  //   DocQualify: "M.B.B.S, M.D. DGO",
  //   DocDepart: "OBS & GYNEAC",
  //   DocType: ["OBS & GYNEAC"],
  //   DocShort:
  //     "Dr. Neha Jain, a skilled Obstetrician and Gynecologist in Ghatkopar, provides expert care for women's health. Patients trust her compassionate approach in managing obstetric and gynecological conditions, ensuring their well-being.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  {
    id:55,
    DoctorImage: Doctor55,
    DocName: "DR. Anuya Manerkar",
    DocQualify: "MBBS, DDV",
    DocDepart: "DERMATOLOGIST",
    DocType: ["Dermatologist"],
    DocShort:
      "Dr. Anuya Manerkar, MS in Dermatologist, practices at Ghatkopar. With specialized qualifications, he offers expert care in Dermatologist Surgery.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:56,
    DoctorImage: Doctor56,
    DocName: "Dr. Salman Ali Sayed",
    DocQualify: "MD Medicine, DM Nephrology",
    DocDepart: "OBS & GYNEAC",
    DocType: ["Nephrologist"],
    DocShort:
      "Dr. Salman Ali Sayed, an accomplished Nephrologist in Ghatkopar, specializes in kidney care with MD Medicine and DM Nephrology qualifications. Patients trust his expertise and compassionate approach.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:57,
    DoctorImage: Doctor57,
    DocName: "Dr. Dhanish Mehendiratta",
    DocQualify: "M.B.B.S., M.S. Ortho, F.A.S.S.I, F.A.O.S",
    DocDepart: "OBS & GYNEAC",
    DocType: ["Orthopedic Surgery"],
    DocShort:
      "Dr. Dhanish Mehendiratta, an eminent Orthopedic specialist in Ghatkopar, provides exceptional care for orthopedic conditions. Patients trust his expertise and compassionate approach, ensuring improved musculoskeletal health.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:58,
    DoctorImage: Doctor58,
    DocName: "Dr. Priyanka Tater Mehta",
    DocQualify: "Neuro Physician",
    DocDepart: "Neuro Physician",
    DocType: ["Neuro Surgery"],
    DocShort:
      "Dr. Priyanka Tater Mehta, a skilled Neuro Physician in Ghatkopar, provides expert care for neurological conditions. Patients trust her dedicated approach, ensuring their well-being in managing neurology-related issues.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:59,
    DoctorImage: Doctor59,
    DocName: "Dr. Ritu Agarwal",
    DocQualify: "Obstetrician & Gynecologists",
    DocDepart: "OBS & GYNEAC",
    DocType: ["OBS & GYNEAC"],
    DocShort:
      "Dr. Ritu Agarwal, a qualified Obstetrician & Gynecologist in Ghatkopar, offers specialized care in women's health. Patients trust her compassionate approach for comprehensive well-being and healthcare.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  {
    id:60,
    DoctorImage: Doctor60,
    DocName: "Dr. Reeta Mehta",
    DocQualify: "Qualifaction",
    DocDepart: "Pediatrician",
    DocType: ["Peadiatric"],
    DocShort:
      "Dr. Reeta Mehta, a skilled Pediatrician in Ghatkopar, provides expert care for children's health. Parents trust her compassionate approach for their little ones' well-being and healthy development.",
    DocBranch: "Ghatkopar",
    category: "Ghatkopar",
    StartTime:"08:00 Am",
    EndTime:"10:00 Pm",
  },
  // {
  //   id:61,
  //   DoctorImage: Doctor61,
  //   DocName: "DR. Amit Shobhawat",
  //   DocQualify: "MD (MED)",
  //   DocDepart: "MEDICINE",
  //   DocType: ["Medicine"],
  //   DocShort:
  //     "Dr. Amit Shobhawat, MD in Medicine, practices at Ghatkopar. With specialized qualifications, he offers expert care in internal medicine. Patients trust his knowledge and dedication in managing various medical conditions for their well-being.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  // {
  //   id:62,
  //   DoctorImage: Doctor62,
  //   DocName: "DR. Sumedha Gupta",
  //   DocQualify: "OBS & GYNEAC",
  //   DocDepart: "OBS & GYNEAC",
  //   DocType: ["OBS & GYNEAC"],
  //   DocShort:
  //     "Dr. Sumedha Gupta is a qualified Obstetrician & Gynecologist in Ghatkopar. With expertise in women's health, she offers specialized and compassionate care. Patients trust her for comprehensive well-being and healthcare.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  // {
  //   id:63,
  //   DoctorImage: Doctor63,
  //   DocName: "DR. Sanjay Gaikwad",
  //   DocQualify: "MS (ORTHO)",
  //   DocDepart: "ORTHO",
  //   DocType: ["Orthopedic Surgery"],
  //   DocShort:
  //     "Dr. Sanjay Gaikwad, MS in Orthopedics, practices at Ghatkopar. With specialized qualifications, he offers expert care in orthopedics. Patients trust his skills and dedication for improved musculoskeletal health.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
  // {
  //   id:64,
  //   DoctorImage: Doctor64,
  //   DocName: "DR. Danish Mehendiratta",
  //   DocQualify: "M.S ORTH",
  //   DocDepart: "SPINE SURGEON",
  //   DocType: ["Spine Surgery"],
  //   DocShort:
  //     "Dr. Danish Mehendiratta, MS in Orthopedics, practices at Ghatkopar. With specialized qualifications, he offers expert care in Spine Surgery.",
  //   DocBranch: "Ghatkopar",
  //   category: "Ghatkopar",
  //   StartTime:"08:00 Am",
  //   EndTime:"10:00 Pm",
  // },
];

export default DoctorsData;
