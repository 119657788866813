import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../CSS/style.css";
import Footer from "../Components/Footer";
import Csr1 from "../img/blogs/csr1.jpg";
import Csr2 from "../img/blogs/csr2.webp";
import Csr3 from "../img/blogs/csr3.jpg";
import Csr4 from "../img/blogs/csr4.jpg";
import Csr5 from "../img/blogs/csr5.webp";
import Csr6 from "../img/blogs/csr6.jpg";
import "../CSS/style.css";

const Csr = () => {
  return (
    <>
      {/* ============= Csr Section========== */}
      <section class="CsrSection">
        <div class="container">
          <div class="section-title">
            <h2>Our Social Services Pages</h2>
            <p>
              Empowering Lives, Strengthening Communities: Our Social Services
              Commitment
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="csrCard">
                <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr6} alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgB3ORA3-f6ZKX-Ndjrcs6eJHFEaGGYTAzZfUbqatRChnyWVvtfI1GFYE2QSybjPeaL3A&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHzdyQoEFt2RhD5vHAZExuD2v3jxruh9mY72yUTUQEMbnc743PkKtZ_8VzLtFrZFrm0EE&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Whole blood donation</h2>
                <hr />
                <p>
                  Donated red blood cells are typically given to people with
                  severe blood loss, such as after an injury or accident, and
                  people with anemia (low hemoglobin).
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="csrCard">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr1} alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr1} alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr1} alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Medical Social Worker</h2>
                <hr />
                <p>
                  Medical social workers work closely with patients and family
                  members who are experiencing mental, emotional, family and/or
                  financial stress due to their or their loved one’s medical
                  condition. Because of the types of challenges they encounter
                  and the fast pace of medical settings, medical social workers
                  may find this field to be stressful and demanding.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="csrCard">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDMn_LsoteKz3N-7XJKLFE1uCLJKhKm_xF8pL2-BCq2mrGY92HzAJph8gKLFGZ2u6zHgc&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr2} alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNvPMIXBXr42az2GvYJEUp03Fb-QZMVk02ibIyAbvbVei8-EEBjlYvd9rHZm0GRsjUvKY&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Health and social services</h2>
                <hr />
                <p>
                  International trade in health services has been growing
                  rapidly in recent years although it remains small compared
                  with trade in other types of services. New health services
                  providers, in particular from developing economies, are
                  seeking to attract foreign patients for various types of
                  specialized medical treatment.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="csrCard">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH4ksOQPxU7cQn_NYBcYjSaIOwkCS-VeroMA&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD0lq9uO36tQwy1R8aIPKAOPbB2HrTSyxq_w&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr3} alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Free Eye Check Up Camp, Mumbai</h2>
                <hr />
                <p>
                  The Free Eye Checkup Camp in Ghatkopar is a valuable community
                  initiative organized to offer complimentary eye screenings and
                  evaluations to the residents of Ghatkopar and neighboring
                  areas. This camp aims to address the eye health needs of
                  individuals who may not have easy access to regular eye
                  checkups or may face financial constraints. The event is
                  usually organized by eye care organizations, NGOs, or
                  government bodies, with the support of local volunteers and
                  medical professionals.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="csrCard">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTueYwTFLCmMUeHBrs_f4_BYktCAzEO26FVig&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNuxAf8zhFAKYahXhvggmIUwuI4fHOZ3Y_pw&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr4} alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Free Dental Checkup Awareness Camp</h2>
                <hr />
                <p>
                  The Free Eye Checkup Camp in Ghatkopar is a valuable community
                  initiative organized to offer complimentary eye screenings and
                  evaluations to the residents of Ghatkopar and neighboring
                  areas. This camp aims to address the eye health needs of
                  individuals who may not have easy access to regular eye
                  checkups or may face financial constraints. The event is
                  usually organized by eye care organizations, NGOs, or
                  government bodies, with the support of local volunteers and
                  medical professionals.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="csrCard">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="Csr"
                >
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU5UUdKhTPL5Vi60TqL49yVR3sJ_c234hNbg&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROawsANxN8yo81AdOlE_Vl6Z8UpNaObl8ZOQ&usqp=CAU" alt="" />
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                   <div className="csr-img">
                  <img src={Csr5} alt="" />
                </div>
                  </SwiperSlide>
                </Swiper>
                <h2>Free Health Checkup Camp</h2>
                <hr />
                <p>
                  The Medical Trust was very happy to receive them, more so
                  because they took a keen interest in knowing about the Centre,
                  its various departments, services given to the economically
                  needy persons etc. They spent some more time to meet the
                  doctors and also the staff members.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Csr;
