import React from "react";
import Footer from "../Components/Footer";

const Trainings = () => {
  return (
    <>
      {/* ============= Trainings Section========== */}
      <section class="TrainingSection">
        <div class="container">
          <div class="section-title">
            <h2>Our Trainings</h2>
            <p>
              Nurturing Medical Excellence To Empowering Doctors through
              Comprehensive Training for Tomorrow's Healthcare Challenges.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-2 order-1">
              <div className="Training_img">
                <img
                  src="https://cache.careers360.mobi/media/article_images/2022/8/23/bsc-nursing-course-degree-amu-featured-image.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 order-md-1 order-2">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>Diploma in Nursing Training</h2>
                  <p>
                    Our Diploma in Nursing Training program provides aspiring
                    nurses with essential skills for exemplary patient care.
                    Combining theory and clinical experience, participants
                    develop a strong foundation in healthcare, empathy, and
                    effective collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="Training_img">
                <img
                  src="https://cdn.pixabay.com/photo/2023/07/31/12/42/operating-theater-8160923_1280.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>BSS Diploma in Operation Theatere Technology</h2>
                  <p>
                    The BSS Diploma in Operation Theatre Technology prepares
                    students for careers in operation theatres. They gain skills
                    in anatomy, surgical techniques, and patient care,
                    qualifying them for roles as Operation Theatre Technicians
                    in hospitals and surgical centers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 order-md-1 order-2">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>Diploma in Dialysis Technology</h2>
                  <p>
                    The Diploma in Dialysis Technology prepares students to
                    become dialysis technicians, proficient in renal care and
                    dialysis procedures. Graduates support patients with kidney
                    diseases, operating equipment and providing vital care in
                    hospitals and renal care facilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <div className="Training_img">
                <img
                  src="https://theholyfaith.in/storage/2022/09/3-1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6">
              <div className="Training_img">
                <img
                  src="https://dolphinlifesciences.com/wp-content/uploads/2022/07/Top-B.Sc_.-Anesthesia-Technology-College-in-Meerut.jpg"
                  alt=""
                />
              </div>
            </div>
          <div className="col-md-6">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>BSS Diploma in Anesthesia Technology</h2>
                  <p>
                    The BSS Diploma in Anesthesia Technology prepares students
                    to assist anesthesiologists during surgical procedures,
                    ensuring patient safety. Graduates can pursue careers as
                    Anesthesia Technologists in healthcare settings.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 order-md-1 order-2">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>
                    BSS Diploma in General Health Care and Maternity Assistant
                  </h2>
                  <p>
                    The BSS Diploma in General Health Care and Maternity
                    Assistant offers comprehensive training in healthcare
                    support, including maternity care. Graduates can pursue
                    careers as Health Care or Maternity Assistants, contributing
                    to patient well-being in various healthcare settings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <div className="Training_img">
                <img
                  src="https://msparamedical.in/wp-content/uploads/2018/10/63319-1920.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6">
              <div className="Training_img">
                <img
                  src="https://www.uei.edu/wp-content/uploads/sites/5/2020/06/Top-5-Benefits-of-Working-as-a-Dental-Assistant-UEI-College.jpeg"
                  alt=""
                />
              </div>
            </div>
          <div className="col-md-6">
              <div className="Training_desc">
                <div className="training_detail">
                  <h2>Diploma in Dental Assistant</h2>
                  <p>
                    The Diploma in Dental Assistant prepares students for
                    careers as dental assistants, supporting dental
                    professionals in clinical and administrative tasks.
                    Graduates gain skills in dental procedures, patient care,
                    and office management, contributing to efficient dental care
                    delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Trainings;
