import React, { useState } from "react";
import ServiceData from "../Components/ServiceData";
import DoctorsData from "../Components/DoctorsData";
import Footer from "../Components/Footer";
import EnquiryModal from "../Components/EnquiryModal";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Ghatkopar = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {/* ============= Services Ghatkopar ========== */}
      <section class="chemburServ">
        <div class="container">
          <div class="section-title">
            <h2>Ghatkopar Services</h2>
            <p>
            Your Wellness, Our Passion to Ghatkopar's Hospital Services, Nurturing Health with Unparalleled Dedication
            </p>
          </div>
          <div class="row">
            {ServiceData.filter((item) => item.Branch === "Ghatkopar" || item.Branch === "Both").map(
              (item, index) => {
                const { id, ServiceImage, Header, ShortDesc,Slugs } = item;
                return (
                  <div className="col-md-4" key={id}>
                    <div className="ServCard">
                      <div className="ServCard-img">
                        <img src={ServiceImage} alt="Emergency and Trauma" />
                      </div>
                      <div className="ServCard-body">
                        <div className="ServCard-title">
                          <h2>{Header}</h2>
                        </div>
                        <div className="ServCard-text">
                          <p>{ShortDesc}</p>
                        </div>
                        <Link to={`${Slugs}`} className="btn">
                          Explore
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>

      {/* ============= Doctor Ghatkopar ========== */}
      <section class="chemburDoc">
        <div class="container">
          <div class="section-title">
            <h2>Ghatkopar Doctor</h2>
            <p>
            Expert Care, Compassionate Hearts to Ghatkopar's Trusted Doctors at Your Service
            </p>
          </div>
          <div class="row">
            {DoctorsData.filter(
              (item) =>
                item.DocBranch === "Ghatkopar" || item.DocBranch === "Both"
            ).map((item, index) => {
              const {
                id,
                DoctorImage,
                DocName,
                DocShort,
                DocQualify,
                DocDepart,
                DocBranch,
              } = item;
              return (
                <>
                  <div class="col-md-6 mb-5">
                    <div className="doctorPage">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="doctor_detail">
                            <div className="doctor-img">
                              <img src={DoctorImage} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 border-start">
                          <div className="doctor_desc">
                            <h2>{DocName}</h2>
                            <h5>
                              <span>Qualifaction :</span>
                              {DocQualify}
                            </h5>
                            <h6>
                              <span>Department :</span>
                              {DocDepart}
                            </h6>
                            <h6>
                              <span>Branch :</span> {DocBranch}
                            </h6>
                            <hr />
                          </div>
                        </div>
                      </div>
                      <p>
                        <span>Description :</span> {DocShort}
                      </p>
                      <div className="ServEnquiry">
                        <Button onClick={() => setModalShow(true)}>
                          Enquire Now
                        </Button>

                        <EnquiryModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                      {/* <div className="knowMore">
                        <a href="Doctor" class="btn">
                          Book Now
                        </a>
                      </div> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/* ============= Contact Ghatkopar ========== */}
      <section class="ContactSection">
        <div class="container">
          <div class="section-title">
            <h2>Ghatkopar Contact Details</h2>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="row">
                <div className="col-12">
                  <div className="conNum">
                    <h2>Contact Details</h2>
                    <a href="tel:7304028800">7304028800 </a><a href="tel:9004179994">/ 9004179994</a>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="conNum">
                    <h2>Mail</h2>
                    <a href="mailto:dishahospital.ac@gmail.com">dishahospital.ac@gmail.com</a>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="conNum">
                    <h2>Address</h2>
                    <a href="https://maps.app.goo.gl/8Kwtik4ZHQJVTNsb8" target="blank">
                    Om Sri Sai Dham CHS, Andheri - Ghatkopar Road Asalpha, Jambhulpada, Ghatkopar West, Mumbai, Maharashtra 400084.
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
            <div class="homeghatkopar-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30168.510276520152!2d72.899746!3d19.060933000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c61fc5573b0f%3A0xf422890340ae7ccf!2sDisha%20Polyclinic%20%26%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1687854896730!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Ghatkopar;
