import React, { useState } from "react";
import Footer from "../Components/Footer";
import $ from "jquery";

const Contact = () => {
  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");

  //////////
  const ChemburForm = (e) => {
    var body =
    '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">'+ custName +'</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">'+ custEmail +'</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">'+ custContact +'</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left;color:#07b7ff">'+ custSubject +'</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">'+ custMessage +'</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff"> Disha MultiHospital</span>.</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "dishamedicare@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };
  // email
  const [custName1, setCustName1] = useState("");
  const [custEmail1, setCustEmail1] = useState("");
  const [custContact1, setCustContact1] = useState("");
  const [custSubject1, setCustSubject1] = useState("");
  const [custMessage1, setCustMessage1] = useState("");

  //////////
  const GhatkoparForm = (e) => {
    var body =
    '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">'+ custName1 +'</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">'+ custEmail1 +'</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">'+ custContact1 +'</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left;color:#07b7ff">'+ custSubject1 +'</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">'+ custMessage1 +'</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff"> Disha MultiHospital</span>.</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "dishaghatkopar@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };
  return (
    <>
      {/* ============= Contact Chembur ========== */}
      <section class="ContactSection">
        <div class="container">
          <div class="section-title">
            <h2>Chembur Contact Details</h2>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Contact Details</h2>
                <a href="tel:022-252742602342">022–252742602342</a>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Mail</h2>
                <a href="mailto:dishamedicare@gmail.com">dishamedicare@gmail.com</a>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Address</h2>
                <a href="https://maps.app.goo.gl/KMXP8hstdtLT5mvd8">
                A-1, 2, 3-Amul Commercial Premises, Rd No. 1, opposite Bmc M Ward Office, Chembur, Mumbai, Maharashtra 400071.
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div class="col-md-6">
            <div class="contactForm">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="contact"
                        value={custContact}
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Select Branch</label>
                      <fieldset>
                      <select
                    class="form-control"
                    name="subject"
                    onChange={(e) => setCustSubject(e.target.value)}
                    Value={"custSubject"}
                  >
                     <option value="Chembur Branch" disabled selected>
                    Chembur Branch
                    </option>
                  </select>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea
                    class="form-control"
                    name="message custMessage"
                    id="custMessage1"
                    rows="6"
                    required
                    value={custMessage}
                    onChange={(e) => setCustMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-Enquiry mt-3">
                  <button onClick={ChemburForm}>Enquiry Now</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div class="chembur-map">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30168.510276520152!2d72.899746!3d19.060933000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c61fc5573b0f%3A0xf422890340ae7ccf!2sDisha%20Polyclinic%20%26%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1687854896730!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ============= Contact Ghatkopar ========== */}
      <section class="ContactSection2" style={{ background: "#eee" }}>
        <div class="container">
          <div class="section-title">
            <h2>Ghatkopar Contact Details</h2>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Contact Details</h2>
                <a href="tel:+91-7304028800 "> +91&nbsp;7304028800 /</a>
                <br/>
                <a href="tel:+91-9004179994">+91&nbsp;9004179994</a>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Mail</h2>
                <a href="mailto:dishahospital.ac@gmail.com">
                  {" "}
                  dishahospital.ac@gmail.com
                </a>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="contPage_card">
                <h2>Address</h2>
                <a href="https://maps.app.goo.gl/TGAFt4yxDcMr85jm9">
                Om Sri Sai Dham CHS, Andheri - Ghatkopar Road Asalpha, Jambhulpada, Ghatkopar West, Mumbai, Maharashtra 400084.
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div class="col-md-6">
              <div class="contactForm">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={custName1}
                        onChange={(e) => setCustName1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="contact"
                        value={custContact1}
                        onChange={(e) => setCustContact1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        value={custEmail1}
                        onChange={(e) => setCustEmail1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Select Branch</label>
                      <fieldset>
                      <select
                    class="form-control"
                    name="subject"
                    onChange={(e) => setCustSubject1(e.target.value)}
                    value={custSubject1 }
                  >
                    <option disabled selected value="Ghatkopar Branch">Ghatkopar Branch
                    </option>
                  </select>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea
                    class="form-control"
                    name="message custMessage"
                    id="custMessage1"
                    rows="6"
                    required
                    value={custMessage1}
                    onChange={(e) => setCustMessage1(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-Enquiry mt-3">
                  <button onClick={GhatkoparForm}>Enquiry Now</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div class="ghatkopar-map">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30162.504174155012!2d72.898305!3d19.093919!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c878a3f9a869%3A0x475e99f41dcf0ad3!2sDisha%20Hospital!5e0!3m2!1sen!2sin!4v1687854584148!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
