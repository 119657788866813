import { React, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DoctorsData from "./DoctorsData";
import ServiceData from "../Components/ServiceData";
import moment from "moment";
import $ from "jquery";

const EnquiryModal = (props) => {
  // ============ Mail function ==========
  const [selectedService, setSelectedService] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");

  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
    setSelectedBranch(""); // Reset selected branch when changing service
  };

  const availableBranches = ServiceData.find(
    (service) => service.Header === selectedService
  )?.category;

  // =================  Slot Generate Funtion ========
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDoctorData, setSelectedDoctorData] = useState(null);

  useEffect(() => {
    const doctorData = DoctorsData.find(
      (doctor) => doctor.DocName === selectedDoctor
    );

    if (doctorData) {
      setSelectedDoctorData(doctorData);

      const generatedSlots = createTimeSlots(
        doctorData.StartTime,
        doctorData.EndTime,
        30
      );
      setSlots(generatedSlots);
    }
  }, [selectedDoctor]);

  const createTimeSlots = (fromTime, toTime, intervalMinutes) => {
    let startTime = moment(fromTime, "hh:mm A");
    let endTime = moment(toTime, "hh:mm A");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    let arr = [];
    while (startTime <= endTime) {
      arr.push(startTime.format("hh:mm A"));
      startTime.add(intervalMinutes, "minutes");
    }
    return arr;
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    var Email = "";
    if (selectedBranch === "Chembur") {
      Email = "dishamedicare@gmail.com";
    } else {
      Email = "dishaghatkopar@gmail.com";
    }

    var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Services:</strong></td><td style="text-align:left;color:#07b7ff">' +
      selectedService +
      '</td></tr><tr><td style="text-align:left"><strong>Branch:</strong></td><td style="text-align:left;color:#07b7ff">' +
      selectedBranch +
      '</td></tr><tr><td style="text-align:left"><strong>Doctor:</strong></td><td style="text-align:left;color:#07b7ff">' +
      selectedDoctor +
      '</td></tr><tr><td style="text-align:left"><strong>Date:</strong></td><td style="text-align:left;color:#07b7ff">' +
      selectedSlot +
      '</td></tr><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custName +
      '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custEmail +
      '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custContact +
      '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custMessage +
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff">Disha MultiHospital</span>.</p></div></body></html>';
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: Email,
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Get A Enquiry</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <div className="row">
              <div class="col-lg-6 mb-2">
                <fieldset>
                <label for="name">Services</label>
                  <select
                    className="form-control"
                    name="service"
                    onChange={handleServiceChange}
                    value={selectedService}
                  >
                    <option value="">Select Services</option>
                    {ServiceData.filter(
                      (service) => service.Header !== "I.C.C.U"
                    )
                      .sort((a, b) => a.Header.localeCompare(b.Header))
                      .map((service) => (
                        <option key={service.id} value={service.Header}>
                          {service.Header}
                        </option>
                      ))}
                  </select>
                </fieldset>
              </div>
              <div class="col-lg-6 mb-2">
                <fieldset>
                <label for="name">Branch</label>
                  <select
                    className="form-control"
                    name="branch"
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    value={selectedBranch}
                  >
                    <option value="">Select Branch</option>
                    {availableBranches === "Both" ? (
                      <>
                        <option value="Chembur">Chembur</option>
                        <option value="Ghatkopar">Ghatkopar</option>
                      </>
                    ) : (
                      <>
                        {availableBranches === "Chembur" && (
                          <option value="Chembur">Chembur</option>
                        )}
                        {availableBranches === "Ghatkopar" && (
                          <option value="Ghatkopar">Ghatkopar</option>
                        )}
                      </>
                    )}
                  </select>
                </fieldset>
              </div>
              <div class="col-lg-6 mb-2">
                <fieldset>
                <label for="name">Doctor</label>
                  <select
                    className="form-control"
                    name="doctor"
                    onChange={(e) => setSelectedDoctor(e.target.value)}
                    value={selectedDoctor}
                  >
                    <option value="">Select Doctor</option>
                    {selectedService && selectedBranch && (
                      <>
                        {DoctorsData.filter(
                          (doctor) =>
                            doctor.DocType.includes(selectedService) &&
                            doctor.DocBranch === selectedBranch
                        ).map((doctor) => (
                          <option key={doctor.id} value={doctor.DocName}>
                            {doctor.DocName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </fieldset>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label for="name">Your Slot</label>
                  <select
                    className="form-control"
                    value={selectedSlot}
                    onChange={(e) =>handleSlotSelection(e.target.value)}
                  >
                    {slots.map((slot, index) => (
                      <option key={index} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="name">Your Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={custName}
                    onChange={(e) => setCustName(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="name">Your Email</label>
                  <input
                    class="form-control"
                    type="text"
                    name="email"
                    value={custEmail}
                    onChange={(e) => setCustEmail(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="name">Phone</label>
                  <input
                    className="form-control"
                    type="number"
                    name="contact"
                    value={custContact}
                    onChange={(e) => setCustContact(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="name">Message</label>
                  <textarea
                    class="form-control"
                    name="message custMessage"
                    id="custMessage1"
                    value={custMessage}
                    onChange={(e) => setCustMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn EnquirySubmit" onClick={ServForm}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnquiryModal;
