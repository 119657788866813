import React, { useState, useEffect, useMemo } from "react";
import DoctorsData from "../Components/DoctorsData";
import Footer from "../Components/Footer";
import Button from "react-bootstrap/Button";
import {FaSearch} from "react-icons/fa";
import DoctorModal from "../Components/EnquiryModal";

const Doctor = () => {
  const [modalShow, setModalShow] = useState(false);
  const [sportList, setSportList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSportList(DoctorsData);
  }, []);

  useEffect(() => {
    function getFilteredList() {
      if (!selectedCategory) {
        return sportList;
      }
      return sportList.filter((item) => item.category === selectedCategory);
    }

    const filteredList = getFilteredList();
    setFilteredList(filteredList);
  }, [selectedCategory, sportList]);

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.value);
  }

  return (
    <>
      <section className="doctorFilter">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="DocSearch">
                <h6>Search Doctor</h6>
                <div className="searchbut">
                <input 
                className="form-control"
                  type="text"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  placeholder="Search..."
                />
              <div className="search_icon">
              <i><FaSearch/></i>
              </div>
                </div>
                {/* <Button>Search</Button> */}
              </div>
            </div>
            <div className="col">
              <div className="DocFilter">
                <h6>Filter Branch</h6>
                <div className="DocFilter_Btn">
                    <select
                      name="category-list"
                      id="category-list"
                      onChange={handleCategoryChange}
                    >
                      <option value="">All</option>
                      <option value="Chembur">Chembur</option>
                      <option value="Ghatkopar">Ghatkopar</option>
                    </select>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="doctor" id="filteredDoctorsContainer">
        <div class="container">
          <div class="section-title">
            <h2>Our Doctors List</h2>
            <p>
            A list of doctors at a specific hospital or medical facility, I recommend visiting the hospital's official website or contacting them directly to inquire about their doctor directory.
            </p>
          </div>
          <div class="row">
            {filteredList
              .filter((item) =>
                item.DocName.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item, index) => {
                const {
                  id,
                  DoctorImage,
                  DocName,
                  DocShort,
                  DocQualify,
                  DocDepart,
                  DocBranch,
                } = item;
                return (
                  <div class="col-md-6 mb-5" key={id}>
                    <div className="doctorPage">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="doctor_detail">
                            <div className="doctor-img">
                              <img src={DoctorImage} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 border-start">
                          <div className="doctor_desc">
                            <h2>{DocName}</h2>
                            <h5>
                              <span>Qualifaction :</span> {DocQualify}
                            </h5>
                            <h6>
                              <span>Department :</span> {DocDepart}
                            </h6>
                            <h6>
                              <span>Branch :</span> {DocBranch}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <p>
                      {DocShort}
                      </p>
                      <div className="ServEnquiry">
                        <Button onClick={() => setModalShow(true)}>
                          Enquire Now
                        </Button>

                        <DoctorModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Doctor;
