import React, { useState } from "react";
import Footer from "../Components/Footer";
import ServiceData from "../Components/ServiceData";
import "../CSS/NavBar.css";
import $ from "jquery";
import { StickyContainer, Sticky } from "react-sticky";
import { Link, useLocation } from "react-router-dom";
import EnquiryModal from "../Components/EnquiryModal";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DoctorsData from "../Components/DoctorsData";

const Service = () => {
  // const [activeTab, setActiveTab] = useState("Orthopedic Surgery");
  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  // };

  // ======= Fetch Services with Slugs in same page =======
  const search = useLocation();
  const Path = search.pathname;
  const EventFdata = ServiceData.find((e) => e.Slugs == Path);
  // Define the doctor type you want to filter by
  const targetDoctorType = EventFdata.Header;
  console.log(targetDoctorType);
  const filteredDoctors = DoctorsData.filter((doctor) =>
    doctor.DocType.includes(targetDoctorType)
  );
  console.log(filteredDoctors);

  // ========= Enquiry Modal ===========
  const [modalShow, setModalShow] = React.useState(false);

  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");

  //////////
  const ChemburForm = (e) => {
    var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custName +
      '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custEmail +
      '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custContact +
      '</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custSubject +
      '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' +
      custMessage +
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff"> Disha MultiHospital</span>.</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "dishamedicare@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };

  return (
    <>
      <div class="header_title_page">
        <div class="container">
          <div class="head_title_inn">
            <h1>{EventFdata.Header}</h1>
            <p>{EventFdata.BannerDesc}</p>
          </div>
        </div>
      </div>
      {/* ========= Services Section */}
      <StickyContainer>
        <section className="newService">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 d-md-block d-none">
                <h4>Other Services</h4>
                <Sticky>
                  {({ style }) => (
                    <div
                      className="sidebar_serv"
                      style={style}
                      id="servicescroll"
                    >
                      <ul className="nav nav-pills brand-pills" role="tablist">
                        {ServiceData.sort((a, b) =>
                          a.Header.localeCompare(b.Header)
                        ).map((service) => (
                          <li key={service.id}>
                            <Link to={`${service.Slugs}`}>
                              <button className="nav-link">
                                {service.Header}{" "}
                                <i className="fa fa-angle-right" />
                              </button>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Sticky>
              </div>
              <div className="col-md-6">
                <div className="service_description">
                  <div key={EventFdata.id}>
                    <div className="Service-img">
                      <img src={EventFdata.ServiceImage} alt="" />
                    </div>
                    <div className="service_Detail">
                      <h2>{EventFdata.Header}</h2>
                      <p>{EventFdata.ShortDesc1}</p>
                      <p>{EventFdata.ShortDesc2}</p>
                      <p>{EventFdata.ShortDesc3}</p>
                      <p>{EventFdata.ShortDesc4}</p>
                      <h4>{EventFdata.shortTitle}</h4>
                      <h6>
                        <span>{EventFdata.descHead1}</span>
                        {EventFdata.descPoint1}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead2}</span>
                        {EventFdata.descPoint2}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead3}</span>
                        {EventFdata.descPoint3}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead4}</span>
                        {EventFdata.descPoint4}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead5}</span>{" "}
                        {EventFdata.descPoint5}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead6}</span>
                        {EventFdata.descPoint6}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead7}</span>
                        {EventFdata.descPoint7}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead8}</span>{" "}
                        {EventFdata.descPoint8}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead9}</span>{" "}
                        {EventFdata.descPoint9}
                      </h6>
                      <h6>
                        <span>{EventFdata.descHead10}</span>{" "}
                        {EventFdata.descPoint10}
                      </h6>
                      <p>{EventFdata.Desc1}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-md-block d-none">
                <Sticky>
                  {({ style }) => (
                    <div class="serviceForm " style={style}>
                      <div class="form-group mt-3">
                        <label for="name">Your Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={custName}
                          onChange={(e) => setCustName(e.target.value)}
                        />
                      </div>
                      <div class="form-group mt-3">
                        <label for="name">Phone</label>
                        <input
                          className="form-control"
                          type="number"
                          name="contact"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                      </div>
                      <div class="form-group mt-3">
                        <label for="name">Your Email</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          value={custEmail}
                          onChange={(e) => setCustEmail(e.target.value)}
                        />
                      </div>
                      <div class="form-group mt-3">
                        <label for="name">Services</label>
                        <fieldset>
                          <select
                            className="form-control"
                            name="subject"
                            onChange={(e) => setCustSubject(e.target.value)}
                            value={custSubject}
                          >
                            <option value="" disabled>
                              Select Services
                            </option>
                            {ServiceData.map((service) => (
                              <option key={service.id} value={service.Slugs}>
                                {service.Header}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                      <div class="form-group mt-3">
                        <label for="name">Message</label>
                        <textarea
                          class="form-control"
                          name="message custMessage"
                          id="custMessage1"
                          rows="6"
                          required
                          value={custMessage}
                          onChange={(e) => setCustMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-Enquiry mt-3">
                        <button onClick={ChemburForm}>Enquiry Now</button>
                      </div>
                    </div>
                  )}
                </Sticky>
              </div>
              <div className="col-12 mt-5 d-md-none d-block">
                <div class="serviceForm ">
                  <div class="form-group mt-3">
                    <label for="name">Your Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={custName}
                      onChange={(e) => setCustName(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name">Phone</label>
                    <input
                      className="form-control"
                      type="number"
                      name="contact"
                      value={custContact}
                      onChange={(e) => setCustContact(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name">Your Email</label>
                    <input
                      class="form-control"
                      type="text"
                      name="email"
                      value={custEmail}
                      onChange={(e) => setCustEmail(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name">Services</label>
                    <fieldset>
                      <select
                        className="form-control"
                        name="subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                        value={custSubject}
                      >
                        <option value="" disabled>
                          Select Hospital
                        </option>
                        {ServiceData.map((service) => (
                          <option key={service.id} value={service.Slugs}>
                            {service.Header}
                          </option>
                        ))}
                      </select>
                    </fieldset>
                  </div>
                  <div class="form-group mt-3">
                    <label for="name">Message</label>
                    <textarea
                      class="form-control"
                      name="message custMessage"
                      id="custMessage1"
                      rows="6"
                      required
                      value={custMessage}
                      onChange={(e) => setCustMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-Enquiry mt-3">
                    <button onClick={ChemburForm}>Enquire Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </StickyContainer>

      {/* ============= Services Doctor ========== */}
      <section class="Services_Doc">
        <div class="container">
          <div class="section-title mb-5">
            <h2>{EventFdata.Header} Doctor</h2>
            <p>
              Expert Care, Compassionate Hearts Trusted Doctors at Your Service
            </p>
          </div>

          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay]}
            className="ServiceDoctor"
          >
            {filteredDoctors.map((ele) => {
              const {
                DoctorImage,
                DocName,
                DocQualify,
                DocDepart,
                DocShort,
                DocBranch,
              } = ele;
              return (
                <>
                  <SwiperSlide>
                    <div className="ServdoctorPage">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="Servdoctor_detail">
                            <div className="Servdoctor-img">
                              <img src={DoctorImage} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 border-start">
                          <div className="Servdoctor_desc">
                            <h2>{DocName}</h2>
                            <h5>
                              <span>Qualifaction :</span>
                              {DocQualify}
                            </h5>
                            <h6>
                              <span>Department :</span>
                              {DocDepart}
                            </h6>
                            <h6>
                              <span>Branch :</span> {DocBranch}
                            </h6>
                            <hr />
                          </div>
                        </div>
                      </div>
                      <p>
                        <span>Description :</span> {DocShort}
                      </p>
                      <div className="ServEnquiry">
                        <Button onClick={() => setModalShow(true)}>
                        Enquire Now
                        </Button>

                        <EnquiryModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Service;
