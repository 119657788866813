import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Chembur from './Pages/Chembur';
import AboutUs from './Pages/AboutUs';
import Tpa from './Pages/Tpa';
import Ghatkopar from './Pages/Ghatkopar';
import Doctor from './Pages/Doctor';
import ServiceData from './Components/ServiceData';
import Training from './Pages/Trainings';
import Contact from './Pages/Contact';
import Blogs from './Pages/Blogs';
import Csr from './Pages/Csr';
import Service from './Pages/Service';
import DoctorAppointment from './Components/DoctorAppointment';

function App() {
  const sortedServiceData = ServiceData.slice().sort((a, b) =>
  a.Header.localeCompare(b.Header)
);

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/AboutUs' element={<AboutUs/>} />
          <Route path='/Tpa' element={<Tpa/>} />
          <Route path='/Chembur' element={<Chembur/>} />
          <Route path='/Ghatkopar' element={<Ghatkopar/>} />
          <Route path='/Doctor' element={<Doctor/>} />
          <Route path='/Contact' element={<Contact/>} />
          <Route path='/Training' element={<Training/>}/>
          <Route path='/Blogs' element={<Blogs/>}/>
          <Route path='/Csr' element={<Csr/>}/>
          <Route
        path="/DoctorAppointment/:selectedService/:selectedBranch/:selectedDoctor/:selectedDate"
        element={<DoctorAppointment />}
      />
          {ServiceData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Service/>}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;