import React from "react";
import Logo from "../img/VSV.png";
import { GrLocation } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <div class="footer-logo">
                <Link to="/" class="footer-logo">
                  <img src={Logo} alt="logo" />
                </Link>
                <p>
                Disha Multispeciality Hospital is a leading healthcare facility dedicated to providing expert medical care and compassionate service.
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="footer-quicklink">
                <h5 class="title">Useful Links</h5>
                <ul class="list-unstyled">
                  <li>
                    <Link href="/AboutUs">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Chembur">Chembur Branch</Link>
                  </li>
                  <li>
                    <Link to="/Ghatkopar">Ghatkopar Branch</Link>
                  </li>
                  <li>
                    <Link to="/Doctor">Doctor Profile</Link>
                  </li>
                  {/* <li>
                    <Link to="/Blogs">Blogs</Link>
                  </li> */}
                  <li>
                    <Link to="/Tpa">Corporates / TPA</Link>
                  </li>
                  <li>
                    <Link to="/Trainings">Trainings</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 px-0">
              <div class="footer-address1">
                <h5 class="title">Chembur Details</h5>
                <ul class="list-unstyled">
                  <li>
                    <i className="contact_icon">
                      <GrLocation />
                    </i>
                    <span>
                    A-1, 2, 3-Amul Commercial Premises, Rd No. 1, opposite Bmc M Ward Office, Chembur, Mumbai, Maharashtra 400071.
                    </span>
                  </li>
                  <li>
                    <i className="contact_icon">
                      <GoMail />
                    </i>
                    <span>
                      <a href="mailto:dishamedicare@gmail.com">
                        dishamedicare@gmail.com
                      </a>
                    </span>
                  </li>
                  <li>
                    <i className="contact_icon">
                      <FiPhoneCall />
                    </i>
                    <span>
                      <a href="tel:022–252742602342">022–252742602342</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 px-0">
              <div class="footer-address2">
                <h5 class="title">Ghatkopar Details</h5>
                <ul class="list-unstyled">
                  <li>
                    <i className="contact_icon">
                      <GrLocation />
                    </i>
                    <span>
                    Om Sri Sai Dham CHS, Andheri - Ghatkopar Road Asalpha, Jambhulpada, Ghatkopar West, Mumbai, Maharashtra 400084.
                    </span>
                  </li>
                  <li>
                    <i className="contact_icon">
                      <GoMail />
                    </i>
                    <span>
                      <a href="mailto:dishahospital.ac@gmail.com">
                        dishahospital.ac@gmail.com
                      </a>
                    </span>
                  </li>
                  <li>
                    <i className="contact_icon">
                      <FiPhoneCall />
                    </i>
                    <span>
                      <a href="tel:7304028800">7304028800</a> /{" "}
                      <a href="tel:9004179994">9004179994</a>
                    </span>
                  </li>
                </ul>
                <div class="footer-socialLink">
                  <a
                    href="https://www.facebook.com/profile.php?id=100084123703149"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f m-r-10"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/dishamultispecialityhospitals/"
                    target="_blank"
                  >
                    <i class="fab fa-instagram m-r-10"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div className="copyRight">
                  <small>
                    <a href="https://dishamultispeciality.in/">Copyright © 2023 Disha Hospital.</a>
                  </small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div className="develop">
                  <small>
                    Develop by : <a href="https://skdm.in/">Shree Krishana Digital Marketing</a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
