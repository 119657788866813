import React from "react";
import Brands1 from "../img/brands/united-healthcare.png";
import Brands2 from "../img/brands/medi-assist.png";
import Brands3 from "../img/brands/md-india.png";
import Brands4 from "../img/brands/paramount-health.png";
import Brands5 from "../img/brands/heritage-health-insurance.png";
import Brands6 from "../img/brands/medicare-insurance.png";
import Brands7 from "../img/brands/FHPL.png";
import Brands8 from "../img/brands/raksha-health-insurance.png";
import Brands9 from "../img/brands/vidal-health-insurance.jpg";
import Brands10 from "../img/brands/medicare-insurance.png";
import Brands11 from "../img/brands/gennis-india-insurance.png";
import Brands12 from "../img/brands/alankit-insurance.png";
import Brands13 from "../img/brands/health-india-insurance.jpg";
import Brands14 from "../img/brands/Vipul-Medcorp-tpa.jpg";
import Brands15 from "../img/brands/Park-Mediclaim-TPA.png";
import Brands16 from "../img/brands/dedicated-healthcare-services.jpg";
import Brands17 from "../img/brands/rothshield-insurance.jpg";
import Brands18 from "../img/brands/ericson-insurance.png";
import Brands19 from "../img/brands/apollo-munich.png";
import Brands20 from "../img/brands/start-health.jpg";
import Brands21 from "../img/brands/cholamandalam.png";
import Brands22 from "../img/brands/iffco-tokio.jpg";
import Brands23 from "../img/brands/Universal-Sompo-General-Insurance.jpg";
import Brands24 from "../img/brands/hdfc-ergo.png";
import Brands25 from "../img/brands/aditya-birla.png";
import Brands26 from "../img/brands/reliance-health-insurance.jpg";
import Brands27 from "../img/brands/liberty-general-insurance.jpg";
import Brands28 from "../img/brands/easrt-west-general-insurance.jpg";
import Brands29 from "../img/brands/navi-general-insurance.png";
import Brands30 from "../img/brands/go-digit-insurance.png";
import Brands31 from "../img/brands/tata-aig-insurance.png";
import Footer from "../Components/Footer";

const Tpa = () => {
  return (
    <>
      {/* ============= Our Tpa ========== */}
      <section class="ourTpaSection">
        <div class="container">
          <div class="section-title">
            <h2>LIST OF DOCUMENTS REQUIRED FOR CASHLESS</h2>
            <p>
              Avail cashless hospitalization through your insurance provider.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul class="bullet-text">
                <li>Policy copy</li>
                <li>
                  Patients photo Id (Aadhar card, Pan card, Driving License etc)
                </li>
                <li>
                  Previous Consulting Note in which admission is advised.
                  Following details must be mentioned – patient Name, Date,
                  Presenting complaints, complaints duration, Diagnosis,
                  Proposed line of treatment received & advised or name of
                  surgery to be done. Also it should be mentioned that patient
                  is advised for admission.
                </li>
                <li>
                  Previous investigation reports supporting the diagnosis like
                  MRI, Sonography, and X-RAY..
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-12">
              <ul class="bullet-text">
                <li>TPA card (like Mediassist, MDIndia, Paramount, Raksha)</li>
                <li>Employee card (In Corporate case)</li>
                <li>First consulting paper till latest.</li>
                <li>
                  Security deposit is mandatory for all cashless as per hospital
                  protocol.
                </li>
                <li>Ericson TPA Healthcare Pvt. Ltd</li>
                <li>Mail id of TPA dept - dishamedicare@gmail.com </li>
                <li>Contact No -022-25274260/ 8850050647</li>
              </ul>
            </div>
          </div>
          <div class="row mt-5">
            <div class="section-title">
              <h2>CASHLESS CONSENT FORM -THIRD PARTY ADMINISTRATION (TPA)</h2>
              <p>
                Avail cashless hospitalization through your insurance provider.
              </p>
            </div>
            <div class="col-md-6">
              <ul class="bullet-text">
                <li>
                  IT HAS BEEN EXPLAINED TO ME IN THE LANGUAGE I UNDERSTAND ABOUT
                  THE DETAILS OF CASHLESS FACILITY AT DISHA NURSING HOME,
                  CHEMBUR.
                </li>
                <li>
                  SECURITY DEPOSIT WILL BE COLLECTED AS PER BED SCHEDULE AT THE
                  TIME OF ADMISSION AND IT WILL BE REFUNDED ONCE THE FULL &
                  FINAL PAYMENT RECEIVED FROM THE TPA. ICCU/SPECIAL WARD/
                  SUITES/SINGLE/DELUXE 20000 GENERAL WARD 15000 OPERATIVE CASE
                  20000
                </li>
                <li>
                  I , THE UNDERSIGNED, HEREBY DECLARE THAT, IN CASE OF DENIAL OF
                  CASHLESS HOSPITALIZATION BENEFIT OR NON REPLY FROM TPA, IT IS
                  MY SOLE RESPONSIBILITY TO MAKE THE FULL AND FINAL PAYMENT BY
                  CASH BEFORE THE DISCHRAGE.
                </li>
                <li>
                  I UNDERSTAND NOT TO HOLD THE HOSPITAL RESPONSIBLE FOR ANY
                  DELAY IN GETTING APPROVAL OR EXTENSION FROM MY TPA OR
                  INSURANCE AGENCY.I UNDERSTAND THAT SUCH APOPROVALS ARE MY
                  RESPONSIBILITY AND NOT THE RESPONSIBILITY OF THE HOSPITAL. THE
                  HOSPITAL RENDERS THIS SERVICE AS A VALUE ADDITION ONLY.
                </li>
                <li>
                  ROOM CHARGES/BED CHARGES WOULD BE TAKEN AS PER ALLOWED IN MY
                  POLICY TERMS.
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="bullet-text">
                <li>
                  I UNDERSTAND TO PAY ANY ADVANCE /DEPOSIT AS AND WHEN REQUIRED
                  BY THE HOSPITAL AND AGREE THAT I WILL SETTLE THE AMOUNT BEFORE
                  THE DISCHARGE OF THE PATIENT.
                </li>
                <li>
                  I ALSO PROMISE TO PAY ALL BILLS RAISED FOR ANY NON-MEDICAL
                  CHARGES INCURRED IN THE TREATMENT OF THE PATIENT. I UNDERTAKE
                  TO PAY THE DIFFERENTIAL CHARGES IF I HAVE AVAILED, ROOM
                  FACILITY ABOVE MY POLICY ALLOCATION PER DAY.
                </li>
                <li>
                  I UNDERSTAND THAT MY ADMISSION TO THIS HOSPITAL IS PRIOR TO
                  APPROVAL OF CASHLESS POLICY. HOWEVER, IF THE INSURANCE COMPANY
                  APPROVE A LESSER CATEGORY AS FAR AS ROOM TARIFF IS CONCERNED,
                  I AGREE TO PAY THE ENTIRE BILL DIFFERENCE AMOUNT (EXCEPT DRUGS
                  & CONSUMABLES ) OVER AND ABOVE THE APPROVED AMOUNT.
                </li>
                <li>
                  I UNDERSTAND THAT THE ENTIRE DEPOSIT WILL BE KEPT WITH THE
                  HOSPITAL TILL THE TIME THE FINAL SETTLMENT IS RECEIVED FROM
                  THE TPA
                </li>
                <li>
                  I STATE THAT THE HOSPITAL HAS EXPLAINED THE ABOVE TERMS TO ME
                  IN THE LANGUAGE I UNDERSTAND.
                </li>

              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Tpa Brands ========== */}
      <section class="Tpa_brands" style={{ background: "#eee" }}>
        <div class="container">
          <div class="section-title">
            <h2>List Of Insurance Companies & TPAs</h2>
            <p>
              The list below is indicative of the TPAs tie-ups at Disha
              Multispeciality Hospital, Ghatkopar and Chembur.
            </p>
          </div>
          <div class="tpasList mb-5">
            <h6>TPA ON PANEL Under GIPSA </h6>
          </div>
          <div class="row">
            <div class="col-md-3">
              <a href="https://www.uhc.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands1} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>UNITED HEALTH CARE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://mediassist.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands2} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>MEDIA ASSIST</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.mdindiaonline.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands3} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>MD INDIA HEALTHCARE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.paramounttpa.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands4} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>PARAMOUNT HEALTH</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                href="http://223.31.103.204/HeritageHealthTPA/HOME/Home.aspx"
                target="blank"
              >
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands5} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>HERITAGE HEALTH INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.uhc.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands6} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>MEDICARE INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.fhpl.net/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands7} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>FAMILY HEALTH PLAN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.rakshatpa.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands8} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>RAKSHA HEALTH INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.vidalhealthtpa.com/home.html" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands9} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>VIDAL HEALTH INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.uhc.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands10} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>MEDSAVE HEALTH INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.geninsindia.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands11} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>GENINS INDIA INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.alankitinsurance.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands12} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>ALANKIT INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.healthindiatpa.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands13} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>HEALTH INDIA INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.vipulmedcorp.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands14} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>VIPUL MEDCROP INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.parkmediclaim.co.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands15} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>PARK MEDICLAIM INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                href="https://www.justdial.com/jdmart/Mumbai/Dedicated-Healthcare-Services-INDIA-Pvt-Ltd-Churchgate/022P1224825457Z4N2S9_BZDET/catalogue"
                target="blank"
              >
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands16} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>DEDICATED HEALTHCARE SERVICES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="http://rothshield.co.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands17} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>ROTHSHIELD INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Tpa Brands ========== */}
      <section class="Tpa_brands">
        <div class="container">
          <div class="section-title">
            <h2>List Of Insurance Companies & TPAs</h2>
            <p>
              The list below is indicative of the TPAs tie-ups at Disha
              Multispeciality Hospital, Ghatkopar and Chembur.
            </p>
          </div>
          <div class="tpasList mb-5">
            <h6>TPA ON PANEL Under PVT INS</h6>
          </div>
          <div class="row">
            <div class="col-md-3">
              <a href="https://www.ericsontpa.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands18} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>ERICSON INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://mediassist.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands19} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>POLLO MUNICH(HDFC ERGO)</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.starhealth.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands20} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>STAR HEALTH</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.cholamandalam.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands21} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>CHOLAMANDALAM</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.iffcotokio.co.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands22} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>IFFCO-TOKIO</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.universalsompo.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands23} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>UNIVERSAL SOMPO GEN. INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.hdfcergo.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands24} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>HDFC ARGO</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.adityabirla.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands25} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>HDFC ARGO</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.vidalhealthtpa.com/home.html" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands26} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>RELIANCE HEALTH INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.libertyinsurance.in/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands27} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>LIBERTY GEN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.eastwestassist.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands28} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>EAST WEST GEN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.alankitinsurance.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands29} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>NAVI GEN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.godigit.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands30} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>GO DIGIT GEN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a href="https://www.tataaig.com/" target="blank">
                <div class="tpaBox">
                  <div class="tpaBoxLogo">
                    <img src={Brands31} alt="" />
                  </div>
                  <div class="tpaName">
                    <p>TATA AIG GEN INSURANCE</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Tpa;
