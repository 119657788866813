import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../CSS/style.css";
import ghatkopar from "../img/ghatkopar-disha-reception.jpg";
import Chembur from "../img/hospital1.jpg";
import { BiGitBranch } from "react-icons/bi";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";
import Footer from "../Components/Footer";
import Men from "../img/men.jpg";
import Women from "../img/women1.jpg";
import Women2 from "../img/women2.jpg";
import comma from "../img/comma.png";
import { GrLocation } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import CountUp from "react-countup";
import { BsFillCalendarCheckFill, BsPeopleFill } from "react-icons/bs";
import { HiEmojiHappy } from "react-icons/hi";
import { MdRateReview } from "react-icons/md";
import Blogs1 from "../img/blogs/blog1.jpg";
import Blogs2 from "../img/blogs/blog2.jpg";
import Blogs3 from "../img/blogs/blog6.jpg";
import Blogs5 from "../img/blogs/blog5.jpg";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import $ from "jquery";
import BlogData from "../Components/BlogData";
import ServiceData from "../Components/ServiceData";
import DoctorsData from "../Components/DoctorsData";
import { Link } from "react-router-dom";

const Home = () => {
  // email
  const [custBranch, setCustBranch] = useState("");

  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");

  //////////
  const HomeForm = (e) => {
    e.preventDefault();
    var Email = "";
    if (selectedBranch === "Chembur") {
      Email = "dishamedicare@gmail.com";
    } else {
      Email = "dishaghatkopar@gmail.com";
    }
    var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' + custContact + '</td></tr><tr><td style="text-align:left"><strong>Branch:</strong></td><td style="text-align:left;color:#07b7ff">' + custBranch + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff">Disha MultiHospital</span>.</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: Email,
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };

  // ==== home Mail function ===

  const [selectedService, setSelectedService] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
    setSelectedBranch("");
  };

  const availableBranches = ServiceData.find(
    (service) => service.Header === selectedService
  )?.category;

  return (
    <>
      <div id="homeBanner">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Property"
        >
          <SwiperSlide>
            <div className="homeBanner1">
              <div className="homebannerTxt">
                <Zoom>
                  <h1>
                    Your Health, Our Priority
                  </h1>
                </Zoom>
                <Fade left>
                  <p>
                    Your trusted destination for
                    comprehensive healthcare, combining expertise and care to
                    ensure your well-being
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homeBanner2">
              <div className="homebannerTxt">
                <Zoom>
                  <h2>
                    Healing with Expertise
                  </h2>
                </Zoom>
                <Fade left>
                  <p>
                    leading healthcare
                    facility dedicated to providing expert medical care and
                    compassionate service.
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homeBanner3">
              <div className="homebannerTxt">
                <Zoom>
                  <h2>
                    ICCU beds for critical patients
                  </h2>
                </Zoom>
                <Fade left>
                  <p>
                    Committed to high-quality healthcare, we guarantee the availability of ICCU beds for patients in need of intensive medical care.
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homeBanner4">
              <div className="homebannerTxt">
                <Zoom>
                  <h2>Obstetrics & Gynecology Services
                    {/* Disha Multispecialist Hospital - Offers Comprehensive Obstetrics & Gynecology Services */}
                  </h2>
                </Zoom>
                <Fade left>
                  <p>
                    Experience specialized Obstetrics and Gynecology services, dedicated to women's health and holistic care
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homeBanner5">
              <div className="homebannerTxt">
                <Zoom>
                  <h2>Offers Healthcare Training Skill Enhancement</h2>
                </Zoom>
                <Fade left>
                  <p>
                    Experience professional growth with our diverse training
                    programs designed to empower individuals
                    for excellence in their fields.
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homeBanner6">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-md-5">
                    <div className="homebannerTxt6">
                      <Zoom>
                        <h3>
                          NABH (National Accreditation Board for Hospital)
                        </h3>
                      </Zoom>
                      <Fade left>
                        <p>
                          Hospital proudly holds the prestigious NABH accreditation, ensuring the highest standards of healthcare excellence
                        </p>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="appointment-sec">
        <div class="container">
          <form class="slider_form" method="get">
            <div class="container">
              <div class="row">
                <div className="col-12">
                  <div className="Appoint">
                    <p>Book an Appointment</p>
                  </div>
                </div>
                <div className="row row-cols-lg-5 row-cols-sm-3 row-cols-1">
                  <div class="col mt-3">
                    <fieldset>
                      <select
                        className="form-control"
                        name="service"
                        onChange={handleServiceChange}
                        value={selectedService}
                      >
                        <option value="">Select Services</option>
                        {ServiceData.filter(
                          (service) => service.Header !== "I.C.C.U"
                        )
                          .sort((a, b) => a.Header.localeCompare(b.Header))
                          .map((service) => (
                            <option key={service.id} value={service.Header}>
                              {service.Header}
                            </option>
                          ))}
                      </select>
                    </fieldset>
                  </div>
                  <div class="col mt-3">
                    <fieldset>
                      <select
                        className="form-control"
                        name="branch"
                        onChange={(e) => setSelectedBranch(e.target.value)}
                        value={selectedBranch}
                      >
                        <option value="">Select Branch</option>
                        {availableBranches === "Both" ? (
                          <>
                            <option value="Chembur">Chembur</option>
                            <option value="Ghatkopar">Ghatkopar</option>
                          </>
                        ) : (
                          <>
                            {availableBranches === "Chembur" && (
                              <option value="Chembur">Chembur</option>
                            )}
                            {availableBranches === "Ghatkopar" && (
                              <option value="Ghatkopar">Ghatkopar</option>
                            )}
                          </>
                        )}
                      </select>
                    </fieldset>
                  </div>
                  <div class="col mt-3">
                    <fieldset>
                      <select
                        className="form-control"
                        name="doctor"
                        onChange={(e) => setSelectedDoctor(e.target.value)}
                        value={selectedDoctor}
                      >
                        <option value="">Select Doctor</option>
                        {selectedService && selectedBranch && (
                          <>
                            {DoctorsData.filter(
                              (doctor) =>
                                doctor.DocType.includes(selectedService) &&
                                doctor.DocBranch === selectedBranch
                            ).map((doctor) => (
                              <option key={doctor.id} value={doctor.DocName}>
                                {doctor.DocName}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </fieldset>
                  </div>
                  <div class="col mt-3">
                    <div class="form-group">
                      <select
                        class="form-control"
                        name="day"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      >
                        <option selected="selected">Select Day</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>
                    </div>
                  </div>
                  <div class="col mt-3">
                    <div className="Booking">
                      {/* <button className="btn EnquirySubmit" onClick={homeForm}>
                        Submit
                      </button> */}
                      <Link
                        to={`/DoctorAppointment/${selectedService}/${selectedBranch}/${selectedDoctor}/${selectedDate}`}
                        className="btn"
                      >
                        Book Appoinment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ============ Home Branch Section =========== */}
      <div className="branches appointment-sec">
        <div className="container">
          <div class="section-title col-12">
            <h2>Our Branches</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div class="hospitalCard">
                <div class="hospitalCardImg">
                  <img src={Chembur} alt="" />
                </div>
                <div class="hospitalCardText">
                  <h4>Chembur Branch</h4>
                  <div class="hospitalCardTime">
                    <p>
                      <span>
                        <AiOutlineFieldTime />
                      </span>{" "}
                      24 x 7
                    </p>
                  </div>
                  <p>
                    <span>
                      <MdLocationPin />
                    </span>{" "}
                    A-1, 2, 3-Amul Commercial Premises, Rd No. 1, opposite Bmc M Ward Office, Chembur, Mumbai, Maharashtra 400071.
                  </p>
                </div>
                <Link to="/Chembur" class="btn">
                  Know More
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div class="hospitalCard">
                <div class="hospitalCardImg">
                  <img src={ghatkopar} alt="" />
                </div>
                <div class="hospitalCardText">
                  <h4>Ghatkopar Branch</h4>
                  <div class="hospitalCardTime">
                    <p>
                      <span>
                        <AiOutlineFieldTime />
                      </span>{" "}
                      24 x 7
                    </p>
                  </div>
                  <p>
                    <span>
                      <MdLocationPin />
                    </span>{" "}
                    Om Sri Sai Dham CHS, Andheri - Ghatkopar Road Asalpha, Jambhulpada, Ghatkopar West, Mumbai, Maharashtra 400084.
                  </p>
                </div>
                <Link to="/Ghatkopar" class="btn">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============ Services Section =========== */}
      <section>
        <div className="service_detail">
          <div className="container">
            <div className="row">
              <div class="section-title col-12">
                <h2>Our Services</h2>
              </div>
            </div>
            <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay]}
              className="Swiper1"
            >
              {ServiceData.map((item, index) => {
                const { id, ServiceImage, Header, ShortDesc, Slugs } = item;
                return (
                  <>
                    <SwiperSlide>
                      <div class="service-item">
                        <div class="services-card">
                          <div class="cards-img">
                            <img
                              src={ServiceImage}
                              alt="Emergency and Trauma"
                            />
                          </div>
                          <div class="service-title">
                            <h2>{Header}</h2>
                            <p>{ShortDesc}</p>
                            <a href={`${Slugs}`}>Know More</a>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>

      {/* ==========  Testimonal ============ */}
      {/* <div class="testimonial">
        <div class="container">
          <div class="row">
            <div class="section-title pt-2 pb-2 col-12">
              <h2 class="col-white">
                Happy Patient <br />
                <span>What our patients say</span>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="container">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="Testimonal"
              >
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="https://sushruthospital.org/testimonials">
                      Read More
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="/">Read More</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="/">Read More</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="/">Read More</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="/">Read More</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="testimonial-box text-center">
                    <img class="img-responsive mb-3" src={comma} alt="" />
                    <h5 class="mt-0 mb-2">Mayur Ghadge</h5>
                    <p class="block-ellipsis">
                      Superb hospital and time to time service provide and nice
                      care
                    </p>
                    <a href="/">Read More</a>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div> */}

      {/* ============ Tpa Section =========== */}
      <section className="tpa-service">
        <div className="service_detail">
          <div className="container">
            <div className="row">
              <div class="section-title col-12">
                <h2>Medical Insurance & TPA Services</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-sm-5">
                <div class="tpa-img box-shadow">
                  <img
                    src="https://media.istockphoto.com/id/1446394529/photo/medical-reimbursement-with-health-insurance-claim-form-and-stethoscope-with-money-dollars.jpg?s=612x612&w=0&k=20&c=lWvGA7QSsH2CdYznlTHsrpENV9tm2R9rBjqdQNACxZ0="
                    alt="Sushrut Hospital &amp; Research Centre"
                  />
                </div>
              </div>
              <div class="col-lg-7 col-sm-7">
                <div class="homeTpa-Desc">
                  <p>
                    <span>Medical Insurance:</span>Medical insurance provides
                    crucial financial protection against high medical costs,
                    covering hospitalization, treatments, and medications. It
                    ensures access to quality healthcare without immediate
                    out-of-pocket expenses, promoting preventive care for early
                    health issue detection.
                  </p>
                  <p>
                    {" "}
                    <span>Third Party Administrator (TPA) Services:</span>
                    TPAs act as intermediaries between insurance companies and
                    individuals, efficiently managing medical claims and
                    administrative tasks.
                  </p>
                  <p>
                    {" "}
                    <span>Medical Insurance with TPA Services:</span>
                    Collaboration between medical insurance and TPAs benefits
                    patients with hassle-free claims, cashless treatments, and
                    focus on recovery.
                  </p>
                  <div class="trustee-section">
                    <div class="header-section m-b-30">
                      <h2>Our Trusted Brand</h2>
                    </div>
                    <div class="row row-cols-lg-5 row-cols-md-3 row-cols-3 m-b-20">
                      <div className="col">
                        <div class="p-2 box-img box-shadow tpa-brand">
                          <a href="#">
                            <img
                              src="http://holievent.site/disha/assets/img/PVT-INS/reliance-health-insurance.jpg"
                              alt="TATA Power Logo"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div class="p-2 box-img box-shadow tpa-brand">
                          <a href="#">
                            <img
                              src="http://holievent.site/disha/assets/img/PVT-INS/cholamandalam.png"
                              alt="TATA Power Logo"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div class="p-2 box-img box-shadow tpa-brand">
                          <a href="#">
                            <img
                              src="http://holievent.site/disha/assets/img/GIPSA/gennis-india-insurance.png"
                              alt="TATA Power Logo"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div class="p-2 box-img box-shadow tpa-brand">
                          <a href="#">
                            <img
                              src="http://holievent.site/disha/assets/img/GIPSA/md-india.png"
                              alt="TATA Power Logo"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div class="p-2 box-img box-shadow tpa-brand">
                          <a href="#">
                            <img
                              src="http://holievent.site/disha/assets/img/GIPSA/paramount-health.png"
                              alt="TATA Power Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Testimonal Section ============ */}
      <section className="testimonial">
        <div class="container">
          <div class="row">
            <div class="section-title col-12">
              <h2>
                Happy Patient <br />
                What our patients say
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="container">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="Testimonal"
              >
                <SwiperSlide>
                  <div className="Testimonals">
                    <div class="row">
                      <div class="col-lg-8">
                        <div class="Testimonal">
                          <div class="testimonal_img">
                            <img
                              src={Men}
                              class="attachment-themetechmount-img-blog-left size-themetechmount-img-blog-left wp-post-image"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div className="testimonal-List">
                          <div class="Testimonal_Desc">
                            <h4>Sunil Kamthe</h4>
                            <p>
                              Dr. Sachin Ajmera is the BEST !!! He is very
                              brilliant, humble, polite and caring for the
                              patient. Hospital is also very neat, clean and
                              hygienic. Staff is also kind nad wonderful
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Testimonals">
                    <div class="row">
                      <div class="col-lg-8">
                        <div class="Testimonal">
                          <div class="testimonal_img">
                            <img
                              src={Women}
                              class="attachment-themetechmount-img-blog-left size-themetechmount-img-blog-left wp-post-image"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div className="testimonal-List">
                          <div class="Testimonal_Desc">
                            <h4>Harshada phalke</h4>
                            <p>
                              Five star is less i give millions stars to disha
                              hospital..dr. Sachin Ajmera is excellent dr. All
                              staff members are v.cooperative. nurses are caring
                              towards patients... Highly recommend disha
                              hospital
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Testimonals">
                    <div class="row">
                      <div class="col-lg-8">
                        <div class="Testimonal">
                          <div class="testimonal_img">
                            <img
                              src={Women2}
                              class="attachment-themetechmount-img-blog-left size-themetechmount-img-blog-left wp-post-image"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div className="testimonal-List">
                          <div class="Testimonal_Desc">
                            <h4>Anjali gholap</h4>
                            <p>
                              It was good... staff and nurses were very polite
                              and helpful It was the 2nd time i visited the
                              hospital
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Blogs Section ============ */}
      {/* <section className="blogs">
        <div class="container">
          <div class="row">
            <div class="section-title col-12">
              <h2>Blog Articles</h2>
            </div>
          </div>
          <div className="row">
            {BlogData.slice(0, 4).map((item, index) => {
              const { id, BlogImg, BlogHead, BlogDesc } = item;
              return (
                <>
                  <div className="col-lg-3 mt-5">
                    <div className="blogCard">
                      <div className="blog-img">
                        <img src={BlogImg} alt="" />
                      </div>
                      <h2>{BlogHead}</h2>
                      <hr />
                      <p>{BlogDesc}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="Booking">
            <a href="/Blogs" class="btn">
              Know Now
            </a>
          </div>
        </div>
      </section> */}
      {/* ========== People Review Section ============ */}
      <section className="Counter">
        <div className="container">
          <div class="row">
            <div class="section-title col-12">
              <h2>People Review</h2>
            </div>
          </div>
          <div className="row row-cols-md-4 row-cols-2">
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span>
                        <BsFillCalendarCheckFill />
                      </span>
                      <span className="countingClass">
                        <CountUp end={17} duration={5} />
                        <span>+</span>
                      </span>
                      <span>Year of Experience</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span>
                        <BsPeopleFill />
                      </span>
                      <span className="countingClass">
                        <CountUp end={1500} duration={5} />
                        <span>+</span>
                      </span>
                      <span>No of Client</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span>
                        <HiEmojiHappy />
                      </span>
                      <span className="countingClass">
                        <CountUp end={1200} duration={5} />
                        <span>+</span>
                      </span>
                      <span>Happy Users</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span>
                        <MdRateReview />
                      </span>
                      <span className="countingClass">
                        <CountUp end={700} duration={5} />
                        <span>+</span>
                      </span>
                      <span>Google Review</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =============== Contact Section ============= */}
      <section class="contact-sec">
        <div class="container">
          <div class="row">
            <div class="section-title col-12" data-aos="fade-up">
              <h2>Contact us</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <h2 class="heading">Have Questions ? Get in Touch! </h2>
              <div class="contact-details p-t-30">
                <h6>Chembur Details</h6>
                <div>
                  <i className="contact_icon">
                    <GrLocation />
                  </i>
                  A-1, 2, 3-Amul Commercial Premises, Rd No. 1, opposite Bmc M Ward Office, Chembur, Mumbai, Maharashtra 400071.
                </div>
                <div>
                  <i className="contact_icon">
                    <FiPhoneCall />
                  </i>
                  <a href="tel:022-69585500">022-6958 5500</a> /{" "}
                  <a href="tel:022-69585555">022-6958 5555</a>
                </div>
                <div>
                  <i className="contact_icon">
                    <GoMail />
                  </i>
                  <a href="mailto:marketing@sushruthospital.org">
                    marketing@sushruthospital.org
                  </a>
                </div>
              </div>
              <hr />
              <div class="contact-details p-t-30">
                <h6>Ghatkopar Details</h6>
                <div>
                  <i className="contact_icon">
                    <GrLocation />
                  </i>
                  Om Sri Sai Dham CHS, Andheri - Ghatkopar Road Asalpha, Jambhulpada, Ghatkopar West, Mumbai, Maharashtra 400084.
                </div>
                <div>
                  <i className="contact_icon">
                    <FiPhoneCall />
                  </i>
                  <a href="tel:7304028800">7304028800  </a> /{" "}
                  <a href="tel:9004179994">9004179994</a>
                </div>
                <div>
                  <i className="contact_icon">
                    <GoMail />
                  </i>
                  <a href="mailto:dishahospital.ac@gmail.com">
                    dishahospital.ac@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="homeForm">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Name</label>
                      <input
                        className="form-control custName"
                        type="text"
                        name="name"
                        value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Phone</label>
                      <input
                        name="contact custPhone"
                        id="custBranch"
                        required
                        className="form-control"
                        type="number"
                        value={custContact}
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email custEmail"
                        value={custEmail}
                        id="custEmail"
                        required
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group">
                      <label for="name">Select Branch</label>
                      <fieldset>
                        <select
                          aria-label="Default select example"
                          class="form-control form-select"
                          name="subject"
                          onChange={(e) => setCustBranch(e.target.value)}
                          value={custBranch}
                        >
                          <option selected disabled>
                            Branches
                          </option>
                          <option value="Chembur">Chembur</option>
                          <option value="Ghatkopar">Ghatkopar</option>
                        </select>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea
                    class="form-control"
                    name="message custMessage"
                    id="custMessage"
                    rows="6"
                    required
                    value={custMessage}
                    onChange={(e) => setCustMessage(e.target.value)}
                  ></textarea>
                </div>
                <div class="form-group">
                  <div className="form-Enquiry">
                    <button onClick={HomeForm}>Enquiry Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div class="homechembur-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30162.504174155012!2d72.898305!3d19.093919!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c878a3f9a869%3A0x475e99f41dcf0ad3!2sDisha%20Hospital!5e0!3m2!1sen!2sin!4v1687854584148!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <div class="homeghatkopar-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30168.510276520152!2d72.899746!3d19.060933000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c61fc5573b0f%3A0xf422890340ae7ccf!2sDisha%20Polyclinic%20%26%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1687854896730!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
