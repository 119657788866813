import React from "react";
import Blogs1 from '../img/blog1.jpg';
import BlogData from "../Components/BlogData";
import Footer from "../Components/Footer";

const Blogs = () => {
  return (
    <>
      {/* ============= Blogs Section========== */}
      <section class="BlogSection">
        <div class="container">
          <div class="section-title">
            <h2>Our Blogs Pages</h2>
            <p>
            Your Health, Our Priority: Exploring Insights for a Better Life
            </p>
          </div>
          <div className="row">
            {BlogData.map((item, index) => {
              const { id, BlogImg, BlogHead, BlogDesc } = item;
              return (
                <>
            <div className="col-lg-3 mt-5">
                <div className="blogCard">
                    <div className="blog-img">
                        <img src={BlogImg} alt="" />
                    </div>
                    <h2>{BlogHead}</h2>
                    <hr/>
                    <p>{BlogDesc}</p>
                </div>
            </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Blogs;
