import { React, useEffect, useState } from "react";
import DoctorsData from "./DoctorsData";
import { useParams } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import Footer from "../Components/Footer";

const DoctorAppoinment = () => {
  const { selectedService, selectedBranch, selectedDoctor, selectedDate } =
    useParams();
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDoctorData, setSelectedDoctorData] = useState(null);

  useEffect(() => {
    const doctorData = DoctorsData.find(
      (doctor) => doctor.DocName === selectedDoctor
    );

    if (doctorData) {
      setSelectedDoctorData(doctorData);

      const generatedSlots = createTimeSlots(
        doctorData.StartTime,
        doctorData.EndTime,
        30
      );
      setSlots(generatedSlots);
    }
  }, [selectedDoctor]);

  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");

  //////////
  const ChemburForm = (e) => {
    var Email = "";

    if (selectedBranch === "Chembur") {
      Email = "dishamedicare@gmail.com";
    } else {
      Email = "dishaghatkopar@gmail.com";
    }

    var body =
    '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Disha MultiHospital Enquiry</h2><p>Hello Disha MultiHospital,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Services:</strong></td><td style="text-align:left;color:#07b7ff">' + selectedService + '</td></tr><tr><td style="text-align:left"><strong>Branch:</strong></td><td style="text-align:left;color:#07b7ff">' + selectedBranch + '</td></tr><tr><td style="text-align:left"><strong>Doctor:</strong></td><td style="text-align:left;color:#07b7ff">' + selectedDoctor + '</td></tr><tr><td style="text-align:left"><strong>Date:</strong></td><td style="text-align:left;color:#07b7ff">' + selectedDate + '</td></tr><tr><td style="text-align:left"><strong>Time:</strong></td><td style="text-align:left;color:#07b7ff">' + selectedSlot + '</td></tr><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' + custContact + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff">Disha MultiHospital</span>.</p></div></body></html>';
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: Email,
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Disha MulltiHospital",
        accountLeadSource: "http://dishamultispeciality.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + custName);
        console.log("name:" + custEmail);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();
    return false;
  };

  // ------------- Slot Generate Funtion --------------------
  const createTimeSlots = (fromTime, toTime, intervalMinutes) => {
    let startTime = moment(fromTime, "hh:mm A");
    let endTime = moment(toTime, "hh:mm A");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    let arr = [];
    while (startTime <= endTime) {
      arr.push(startTime.format("hh:mm A"));
      startTime.add(intervalMinutes, "minutes");
    }
    return arr;
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };

  return (
    <>
      <section className="appoinmentSlot">
        <div className="container">
        <div class="section-title">
            <h2>appointment schedule</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="slots">
                <h3>Select the Slot</h3>

                <ul>
                  {slots.map((slot, index) => (
                    <li
                      key={index}
                      className={selectedSlot === slot ? "selected" : ""}
                      onClick={() => handleSlotSelection(slot)}
                    >
                      {slot}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="appoinment">
              <div className="row">
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label for="name">Selected Services</label>
                    <input
                      className="form-control"
                      value={selectedService}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label for="name">Selected Branch</label>
                    <input
                      className="form-control"
                      value={selectedBranch}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label for="name">Selected Doctor</label>
                    <input
                      className="form-control"
                      value={selectedDoctor}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label for="name">Selected Day</label>
                    <input
                      className="form-control"
                      value={selectedDate}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label for="name">Your Slot</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSlot}
                      readOnly
                    />
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="form-group">
                    <label for="name">Your Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={custName}
                      onChange={(e) => setCustName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-3">
                  <div class="form-group">
                    <label for="name">Your Email</label>
                    <input
                      class="form-control"
                      type="text"
                      name="email"
                      value={custEmail}
                      onChange={(e) => setCustEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="form-group">
                    <label for="name">Phone</label>
                    <input
                      className="form-control"
                      type="number"
                      name="contact"
                      value={custContact}
                      onChange={(e) => setCustContact(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="name">Message</label>
                <textarea
                  class="form-control"
                  name="message custMessage"
                  id="custMessage1"
                  rows="6"
                  required
                  value={custMessage}
                  onChange={(e) => setCustMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="form-Enquiry mt-3">
                <button onClick={ChemburForm}>Submit</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DoctorAppoinment;
