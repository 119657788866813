import React from "react";
import about from "../img/founder1.jpeg";
import Brands from "../img/brand4.png";
import awards1 from "../img/vsv-awards-1.jpeg";
import awards2 from "../img/vsv-awards-2.jpeg";
import awards3 from "../img/vsv-awards-3.jpeg";
import Choose from '../img/hospital-vector.png';
import Footer from "../Components/Footer";
import Mission from '../img/mission.png';
import Vision from '../img/vission.png';

const AboutUs = () => {
  return (
    <>
      {/* ============= Our Story ========== */}
      <section class="ourStorySection">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="ourStory">
                <div class="ourStoryHead">
                  <h6>Our Story</h6>
                  <h2>Exceptional Care Close to You</h2>
                </div>
                <div class="ourStoryText">
                  <p>
                    Disha hospital was founded in the year 2000; by the Mr.
                    Vittal B. Shetty, a lawyer by profession. Who always
                    believed that healthcare needs a human touch. He started
                    with a goal of setting up personalized healthcare in a small
                    hospital with the best of facilities in an affordable
                    setting. This vision has hence evolved over the last 20 Yrs;
                    with the DISHA group of hospitals carving a small but
                    definitive niche in Mumbai as a healthcare unit based on
                    trust and compassion besides medical expense. The DISHA
                    management, supported by a core team of sincere staff
                    members try in earnest to provide quality healthcare within
                    the limited space & resources available to them.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-center">
              <div class="storyImgSection">
                <div class="storyImg">
                  <img src={about} alt="" />
                </div>
                <div class="storyImgName">
                  <h4>Vittal B Shetty</h4>
                  <h6>Founder</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Our Brands ========== */}
      <section class="brands">
        <div class="container">
          <div class="section-title">
            <h2>Memberships, Certificates and Recognition</h2>
            <p>
            Healing with Distinction: Embracing Memberships, Certificates, and Recognition as a Doctor.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 order-md-1 oder-2">
              <div class="aboutCertificate">
                <h2>Certificates Detail</h2>
                <p>
                  A Disha hospital is a healthcare facility that has achieved
                  accreditation from the National Accreditation Board for
                  Hospitals & Healthcare Providers. This certification validates
                  that the hospital meets rigorous standards and criteria in
                  areas such as patient safety, clinical outcomes,
                  infrastructure, infection control, patient rights, and
                  management processes.
                </p>
                <p>
                  Disha hospitals demonstrate a commitment to excellence,
                  quality improvement, patient-centered care, and continuous
                  enhancement of healthcare services to ensure the highest
                  standards of patient safety and satisfaction.
                </p>
              </div>
            </div>
            <div class="col-md-6 order-md-2 order-1">
              <div class="aboutCertificateImg">
                <img src={Brands} alt="" />
              </div>
            </div>
          </div>
          <div class="row mt-lg-5">
            <div class="col-md-4 serviceCardCol d-flex flex-column">
              <div class="awardImg">
                <img src={awards1} alt="" />
              </div>
            </div>
            <div class="col-md-4 serviceCardCol d-flex flex-column">
              <div class="awardImg">
                <img src={awards2} alt="" />
              </div>
            </div>
            <div class="col-md-4 serviceCardCol d-flex flex-column">
              <div class="awardImg">
                <img src={awards3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Our Mission ========== */}
      <section class="missionSection">
        <div class="container">
        <div class="section-title">
            <h2>Our Mission Our Vission</h2>
            <p>
            Our Compassionate Mission, Our Vision for Empowered Healthcare at Disha Hospital.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="missionCard">
                <div class="missionCardIcon">
                  <img
                    src={Mission}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="missionCard">
                <div class="missionCardText">
                  <h4>Mission</h4>
                  <p>
                    We at DNH understand that delivery of quality health care is
                    not an automated process but one that need compassionate
                    well trained Doctors and staff striving their best to ensure
                    that every patient is given the utmost care and dealt with
                    expertise
                  </p>
                  <p>
                    Hence we train each member of our team to have the self
                    discipline Elton be a better version of themselves each
                    passing day, both in their professional and personal
                    capacity.
                  </p>
                  <p>
                    We truly believe that if each team member achieves this we
                    will be able to provide excellent healthcare to our
                    community.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 order-md-1 order-2">
              <div class="missionCard">
                <div class="missionCardText">
                  <h4>Vision</h4>
                  <p>
                    We have a vision of raising the benchmark in quality of
                    healthcare and serving as a role model to all small and
                    medium sized hospital, in how a dedicated skilled team can
                    deliver precise medical treatments with highly predictable
                    outcomes.
                  </p>
                  <p>
                    At the end of the day we believe that medical, machines
                    don’t run themselves .It is a compassionate healthcare
                    provider with a skilled hand that can make all the
                    difference.
                  </p>
                  <p>
                    {" "}
                    We at DNH train each team member to imbibe these values and
                    reinforce it every passing day.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-md-2 order-1">
              <div class="missionCard">
                <div class="missionCardIcon">
                  <img
                    src={Vision}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       {/* ============= Why Choose us ========== */}
       <section class="whyChooseUS">
        <div class="container">
        <div class="section-title">
            <h2>Why Choose Us</h2>
            <p>
            Your Trusted Path to Health and Wellbeing to   Choose Disha Hospital for Exceptional Care
            </p>
          </div>
            <div class="row">
                <div class="col-lg-4 col-12 d-flex justify-content-lg-start justify-content-center align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 justify-content-center align-items-center">
                            <div class="whyUsCard">
                                <div class="whyUsIcon">
                                    <span class="material-symbols-outlined">
                                        safety_check
                                    </span>
                                </div>
                                <div class="whyUsTitle">
                                    <h3>Full Time Specialist System</h3>
                                </div>
                                <div class="whyUsText">
                                    <p>
                                        Reliability is absolutely everything when it comes to courier/delivery services.
                                        And we handle your products with care.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 justify-content-center align-items-center mb-5">
                            <div class="whyUsCard">
                                <div class="whyUsIcon">
                                    <span class="material-symbols-outlined">
                                        biotech
                                    </span>
                                </div>
                                <div class="whyUsTitle">
                                    <h3>Technology</h3>
                                </div>
                                <div class="whyUsText">
                                    <p>
                                        We’re the fastest and most trusted courier services in Mumbai, we have a
                                        courier/messenger available for pick up/drop off delivery, minutes from you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12 d-flex justify-content-center align-items-center">
                    <div class="whyChooseImg">
                        <img src={Choose} alt="" />
                    </div>
                </div>
                <div class="col-lg-4 col-12 d-flex justify-content-lg-end justify-content-center align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-12 justify-content-center align-items-center mb-5">
                            <div class="whyUsCard">
                                <div class="whyUsIcon">
                                    <span class="material-symbols-outlined">
                                        groups
                                    </span>
                                </div>
                                <div class="whyUsTitle">
                                    <h3>Experiance Team</h3>
                                </div>
                                <div class="whyUsText">
                                    <p>
                                        We have an experienced team who working very hard to deliver your package on
                                        time.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 col-12 justify-content-center align-items-center mb-5">
                            <div class="whyUsCard">
                                <div class="whyUsIcon">
                                    <span class="material-symbols-outlined">
                                        volunteer_activism
                                    </span>
                                </div>
                                <div class="whyUsTitle">
                                    <h3>Purpose and Passion</h3>
                                </div>
                                <div class="whyUsText">
                                    <p>
                                        We will take care of your sensitive item with our best safety service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  );
};

export default AboutUs;
