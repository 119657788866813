import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
// import "../CSS/style.css";
import "../CSS/NavBar.css";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ServiceData from "./ServiceData";
import Logo from "../img/VSV.png";
import { FaUserDoctor } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import EnquiryModal from "./EnquiryModal";

const NavBar = () => {
  // ====== Togal Function
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // appoinment button
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {/* <div className="main_header">
        <div className="container">
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="#home">
              <Link to="/">
                <div className="Logo-Container">
                  <img src={Logo} alt="" />
                </div>
              </Link>
            </Navbar.Brand>
            <button
              className="navbar-toggler navbar-toggler-right collapsed d-block d-lg-none "
              type="button"
              onClick={toggleNav}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
              id="navbarMenu"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">About</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/AboutUs">About Us</Dropdown.Item>
                      <Dropdown.Item href="/Tpa">Tpa Service</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Hospital
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/Chembur">Chembur</Dropdown.Item>
                      <Dropdown.Item href="/Ghatkopar">Ghatkopar</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Services
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="service-nav">
                      {ServicesData.map((ele) => {
                        const { Header, Slugs } = ele;
                        return (
                          <>
                            <Dropdown.Item href={`${Slugs}`}>
                              {Header}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Doctor">
                    Doctor
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Training">
                    Trainings
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Csr">
                    CSR
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Blogs">
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/Contact">
                    Contact
                  </a>
                </li>
                <div className="navcontact">
                  <a href="tel: +91 9372745480">
                    <i>
                      <FaUserDoctor />{" "}
                    </i>
                    <span> +91 &nbsp;9372745480</span>
                  </a>
                </div>
              </ul>
            </div>
          </Navbar>
        </div>
      </div> */}
      <div className="headertop">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4">
              <Navbar.Brand href="/">
                <Link to="/">
                  <div className="Navbarlogo">
                    <img src={Logo} alt="" />
                  </div>
                </Link>
              </Navbar.Brand>
            </div>
            <div className="col-lg-2 d-lg-block d-none TxtLogo">
              <div className="LogoTxt">
                <div className="LogoDesc">
                  <h4>Disha Multispeciality<br/> Hospital  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="headNav">
                <div className="nav_header">
                  <div className="social-detail1">
                    <ul>
                      <li className="num_head">
                        <a href="tel:022–252742602342">022-2527-42602342 /</a>
                      </li>
                      <li className="num_head">
                        <a href="tel:9004179994">900-417-9994</a>
                      </li>
                      {/* <li className="num_add">
                        <i className="fa-solid fa-envelope" />
                        nakshatracarehospital@gmail.com
                      </li>
                      <li className="scl_head">
                        <a
                          href="https://www.facebook.com/profile.php?id=100090917586255"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook" />
                        </a>
                      </li>
                      <li className="scl_head">
                        <a
                          href="https://www.instagram.com/nakshtrahospital573/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="contact-detail">
                  <p className="para_apt">
                    <span>We Are Open 24 / 7 </span> Every Day
                  </p>
                  <p className="book_apt">
                    <Button onClick={() => setModalShow(true)}>
                      <i className="fa-solid fa-user" />
                      Book Appointment
                    </Button>

                    <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </p>
                </div>
                <Navbar collapseOnSelect expand="lg">
                  <button
                    className="navbar-toggler navbar-toggler-right collapsed d-block d-lg-none "
                    type="button"
                    onClick={toggleNav}
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className={`collapse navbar-collapse ${
                      isNavOpen ? "show" : ""
                    }`}
                    id="navbarMenu"
                  >
                    <ul className="navbar-nav mt-3">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            About
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item to="/AboutUs">
                              <Link className="nav-link" to="/AboutUs">
                                About Us
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item to="/Tpa">
                              <Link className="nav-link" to="/Tpa">
                                Tpa Service
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className="nav-item">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            Hospital
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item to="/Chembur">
                              <Link className="nav-link" to="/Chembur">
                                Chembur
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item to="/Ghatkopar">
                              <Link className="nav-link" to="/Ghatkopar">
                                Ghatkopar
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className="nav-item">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            Services
                          </Dropdown.Toggle>
                          <Dropdown.Menu id="service-nav">
                            {ServiceData.map((ele) => {
                              const { Header, Slugs } = ele;
                              return (
                                <>
                                  <Dropdown.Item to={`${Slugs}`}>
                                    <Link className="nav-link" to={`${Slugs}`}>
                                      {Header}
                                    </Link>
                                  </Dropdown.Item>
                                </>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      {/* <li className="nav-item">
                        <a className="nav-link" href="/Service">
                          Service
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/Doctor">
                          Doctor
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Training">
                          Trainings
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Csr">
                          Csr
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link className="nav-link" to="/Blogs">
                          Blog
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/Contact">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
